/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GpPaymentVm = {
    merchantNumber: string;
    operation: string;
    orderNumber: number;
    amount: number;
    currency: number;
    depositFlag: number;
    merordernum: number;
    url: string;
    description?: string;
    md?: string;
    payMethod?: string;
    disableMethod?: string;
    payMethods?: string;
    email?: string;
    referenceNumber?: string;
    addInfo?: string;
    digest: string;
    lang?: string;
    paymentStatus?: GpPaymentVm.paymentStatus;
};

export namespace GpPaymentVm {

    export enum paymentStatus {
        PENDING = 'PENDING',
        PAID = 'PAID',
        CANCELLED = 'CANCELLED',
        REJECTED = 'REJECTED',
        RETURNED = 'RETURNED',
        PROCESSING = 'PROCESSING',
    }


}
