/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReclamationQuery = {
    /**
     * Customer name. "LastName FirstName.
     */
    customerName?: string;
    /**
     * Reclamation state.
     */
    state?: ReclamationQuery.state;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
    /**
     * Page Size. Default: 25
     */
    limit?: number;
    /**
     * Offsetting x items. Default: 0
     */
    offset?: number;
    /**
     * Sort by. Default: id
     */
    sortBy?: string;
    /**
     * Sort order descendent. Default: true
     */
    sortDesc?: boolean;
    /**
     * Sort with locale. Default: sk
     */
    sortLocale?: string;
};

export namespace ReclamationQuery {

    /**
     * Reclamation state.
     */
    export enum state {
        PENDING = 'PENDING',
        REJECTED = 'REJECTED',
        APPROVED_COUNTER_PAY_OFF = 'APPROVED_COUNTER_PAY_OFF',
        APPROVED_CARD_UPLOAD = 'APPROVED_CARD_UPLOAD',
        FINALIZED = 'FINALIZED',
    }


}
