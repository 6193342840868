interface NamedPerson {
  firstName?: string;
  lastName?: string;
}

export const formatName = (person: NamedPerson | undefined): string => {
  const parts: string[] = [];

  if (person?.firstName) {
    parts.push(person.firstName);
  }

  if (person?.lastName) {
    parts.push(person.lastName);
  }

  return parts.join(' ');
};

export const formatNameReverse = (person: NamedPerson | undefined): string => {
  const parts: string[] = [];

  if (person?.lastName) {
    parts.push(person.lastName);
  }

  if (person?.firstName) {
    parts.push(person.firstName);
  }

  return parts.join(' ');
};
