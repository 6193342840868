/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { CustomerCardInfoListResponse } from '../models/CustomerCardInfoListResponse';
import type { CustomerCardResponse } from '../models/CustomerCardResponse';
import type { InitTokenizationResponse } from '../models/InitTokenizationResponse';
import type { TokenizationResponse } from '../models/TokenizationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BankCardService {

    /**
     * Get bank card info list for the given customer.
     * @returns CustomerCardInfoListResponse 
     * @throws ApiError
     */
    public static bankCardControllerGetCustomerBankCards({
customerId,
}: {
customerId: string,
}): CancelablePromise<CustomerCardInfoListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/bank-card/{customerId}',
            path: {
                'customerId': customerId,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Init a tokenization.
     * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
 * + **CUSTOMER_ACCOUNT_NOT_VERIFIED** - Customer account is not verified.<br>
 * + **CANNOT_INIT_TOKENIZATION** - Tokenization cannot be inited.
     * @returns InitTokenizationResponse 
     * @throws ApiError
     */
    public static bankCardControllerInitTokenization({
cardApplicationId,
}: {
cardApplicationId: string,
}): CancelablePromise<InitTokenizationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/eshop/bank-card/init-tokenization/{cardApplicationId}',
            path: {
                'cardApplicationId': cardApplicationId,
            },
            errors: {
                400: `Validation failed.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Finish a tokenization.
     * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
 * + **INVALID_CARD_APPLICATION** - Card application is invalid or does not exist.<br>
 * + **CHIP_NUMBER_NOT_UNIQUE** - Chip number already exists.<br>
 * + **CARD_HAS_UNPROCESSED_TRANSACTIONS** - Card has unprocessed transactions. Upload all transactions to card first.<br>
 * + **CANNOT_FINISH_TOKENIZATION** - Tokenization cannot be finished.
     * @returns CustomerCardResponse 
     * @throws ApiError
     */
    public static bankCardControllerFinishTokenization({
requestBody,
}: {
/**
 * TokenizationResponse
 */
requestBody?: TokenizationResponse,
}): CancelablePromise<CustomerCardResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/eshop/bank-card/finish-tokenization',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Block active bank card.
     * @returns CustomerCardResponse 
     * @throws ApiError
     */
    public static bankCardControllerBlockBankCard({
id,
}: {
id: string,
}): CancelablePromise<CustomerCardResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/eshop/bank-card/{id}/block',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Activate blocked bank card.
     * @returns CustomerCardResponse 
     * @throws ApiError
     */
    public static bankCardControllerActivateBankCard({
id,
}: {
id: string,
}): CancelablePromise<CustomerCardResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/eshop/bank-card/{id}/activate',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Remove tokenized or blocked bank card.
     * @returns ApiResponse 
     * @throws ApiError
     */
    public static bankCardControllerRemoveBankCard({
id,
}: {
id: string,
}): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/eshop/bank-card/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

}
