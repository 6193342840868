/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SavePriceListZoneCommand = {
    price: number;
    zone: SavePriceListZoneCommand.zone;
};

export namespace SavePriceListZoneCommand {

    export enum zone {
        ZONE_I = 'ZONE-I',
        ZONE_I_II = 'ZONE-I+II',
        ZONE_II = 'ZONE-II',
    }


}
