import { MIN_CUSTOMER_AGE } from '@/config/config';

export enum CardApplicationDialogType {
  Create = 'CREATE',
  CreateAssignedCard = 'CREATE_ASSIGNED_CARD',
  Detail = 'DETAIL',
}

export const CARD_APPLICATION_DIALOG_TYPE: CardApplicationDialogTypes = {
  CREATE: {
    icon: '$newCardApplicationIcon',
    title: { translate: 'cardApplication.create' },
    titleVirtual: 'cardApplication.createVirtualCard',
    titleBank: { translate: 'cardApplication.createBankCard' },
    name: CardApplicationDialogType.Create,
  },
  CREATE_ASSIGNED_CARD: {
    icon: '$newCardApplicationIcon',
    title: {
      translate: 'cardApplication.createAssignedCard',
      param: { minCustomerAge: MIN_CUSTOMER_AGE },
    },
    titleVirtual: 'cardApplication.createAssignedCard',
    titleBank: {
      translate: 'cardApplication.createAssignedBankCard',
      param: { minCustomerAge: MIN_CUSTOMER_AGE },
    },
    name: CardApplicationDialogType.CreateAssignedCard,
  },
  DETAIL: {
    icon: 'mdi-eye',
    title: { translate: 'cardApplication.detail' },
    titleVirtual: 'cardApplication.detailVirtualCard',
    titleBank: { translate: 'cardApplication.detailBankCard' },
    name: CardApplicationDialogType.Detail,
  },
};

export interface CardApplicationDialogTypes {
  CREATE: CardApplicationDialogTypeItem;
  CREATE_ASSIGNED_CARD: CardApplicationDialogTypeItem;
  DETAIL: CardApplicationDialogTypeItem;
}

export interface CardApplicationDialogTypeItem {
  icon: string;
  title: { translate: string; param?: Record<string, unknown> };
  titleVirtual: string;
  titleBank: { translate: string; param?: Record<string, unknown> };
  name: CardApplicationDialogType;
}
