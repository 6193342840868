/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EnumDto } from './EnumDto';

export type OrderItemDto = {
    /**
     * Type of order item.
     */
    type: OrderItemDto.type;
    /**
     * Marks the order item as a cancellation.
     */
    cancellation: boolean;
    feeName?: string;
    feeId?: string;
    productName?: string;
    /**
     * Ticket fare zone.
     */
    zone: EnumDto;
    /**
     * Ticket fare customer profile identifier. Type: 'UUID'
     */
    customerProfileId?: string;
    /**
     * Ticket fare tariff profile identifier. Type: 'UUID'
     */
    tariffProfileId?: string;
    /**
     * Ticket fare combination identifier. Type: 'UUID'
     */
    ticketCombinationId?: string;
    /**
     * Ticket fare validity start date. Type: 'DATE'
     */
    validFrom?: string;
    /**
     * Ticket fare validity end date. Type: 'DATE'
     */
    validTo?: string;
    reclamationNumber?: number;
    /**
     * Quantity.
     */
    quantity: number;
    /**
     * Price per one item.
     */
    price: number;
    /**
     * Total price. Price x Quantity.
     */
    totalPrice: number;
    /**
     * Tax percentage.
     */
    taxPercentage: number;
    /**
     * Card number.
     */
    cardNumber?: string;
    /**
     * Electronic wallet operation type.
     */
    eWalletOperationType?: string;
    /**
     * Electronic wallet new balance.
     */
    eWalletNewBalance?: number;
    /**
     * Name of customer.
     */
    customerFullName?: string;
};

export namespace OrderItemDto {

    /**
     * Type of order item.
     */
    export enum type {
        FEE = 'FEE',
        PRODUCT = 'PRODUCT',
        TICKET_FARE = 'TICKET_FARE',
        PAK_TICKET_FARE = 'PAK_TICKET_FARE',
        ELECTRONIC_WALLET = 'ELECTRONIC_WALLET',
        RECLAMATION = 'RECLAMATION',
        ROUNDING = 'ROUNDING',
    }


}
