/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CustomerProfileQuery = {
    /**
     * Customer profile number.
     */
    profileNumber?: number;
    /**
     * Customer profile searched by age.
     */
    age?: number;
    /**
     * Customer profile type. Values: PERSONAL, IMPERSONAL
     */
    type?: CustomerProfileQuery.type;
    /**
     * Filter only primary profiles.
     */
    onlyPrimary?: boolean;
    /**
     * Validity of tariff profiles. Past, Present, Future.
     */
    validity?: CustomerProfileQuery.validity;
    /**
     * Computes deletable flag when enabled.
     */
    includeDeletable?: boolean;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
    /**
     * Page Size. Default: 25
     */
    limit?: number;
    /**
     * Offsetting x items. Default: 0
     */
    offset?: number;
    /**
     * Sort by. Default: id
     */
    sortBy?: string;
    /**
     * Sort order descendent. Default: true
     */
    sortDesc?: boolean;
    /**
     * Sort with locale. Default: sk
     */
    sortLocale?: string;
    /**
     * Filter by date within date range. Type: 'DATE'
     */
    date?: string;
};

export namespace CustomerProfileQuery {

    /**
     * Customer profile type. Values: PERSONAL, IMPERSONAL
     */
    export enum type {
        IMPERSONAL = 'IMPERSONAL',
        PERSONAL = 'PERSONAL',
    }

    /**
     * Validity of tariff profiles. Past, Present, Future.
     */
    export enum validity {
        PAST = 'PAST',
        PRESENT = 'PRESENT',
        FUTURE = 'FUTURE',
    }


}
