/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CardApplicationInfoVm } from './CardApplicationInfoVm';
import type { CardProfileVm } from './CardProfileVm';
import type { CustomerChangeDto } from './CustomerChangeDto';
import type { CustomerDto } from './CustomerDto';
import type { ElectronicWalletDto } from './ElectronicWalletDto';
import type { EnumDto } from './EnumDto';
import type { EnumWithCreatedDateDto } from './EnumWithCreatedDateDto';
import type { TicketFareDto } from './TicketFareDto';

export type CustomerCardDetailVm = {
    /**
     * Customer card identifier. Type: 'UUID'
     */
    id: string;
    /**
     * Unique identifier of card.
     */
    cardNumber: string;
    /**
     * Chip number identifier. Is unique.
     */
    chipNumber: string;
    /**
     * Card valid to. Type: 'DATE'
     */
    validTo: string;
    /**
     * Card valid from. Type: 'DATE'
     */
    validFrom: string;
    note?: string;
    /**
     * Card state. Active, blocked, ...
     */
    state: EnumWithCreatedDateDto;
    /**
     * Card type. Smart or bank.
     */
    type: EnumDto;
    customer: CustomerDto;
    draftCustomer: CustomerDto;
    customerCardType: CustomerCardDetailVm.customerCardType;
    /**
     * Last 5 customer draft changes.
     */
    customerChanges: Array<CustomerChangeDto>;
    /**
     * Card profile. Contains date range, profile itself and optional confirmation check date.
     */
    profileOne?: CardProfileVm;
    /**
     * Card profile. Contains date range, profile itself and optional confirmation check date.
     */
    profileTwo?: CardProfileVm;
    applicationInfo: CardApplicationInfoVm;
    electronicWallet?: ElectronicWalletDto;
    ticketFares: Array<TicketFareDto>;
    /**
     * Is this card a reclamation card
     */
    reclamation: boolean;
    /**
     * Contact me if card found
     */
    contactMeIfCardFound: boolean;
    /**
     * Card production number.
     */
    productionNumber?: number;
    /**
     * Data protection
     */
    dataProtection: string;
    /**
     * Identifier that card is for free.
     */
    cardForFree?: boolean;
    bankCardIssuer?: string;
    /**
     * Agree with marketing consent. Default: false
     */
    marketingConsent?: boolean;
};

export namespace CustomerCardDetailVm {

    export enum customerCardType {
        PERSONAL = 'PERSONAL',
        PORTABLE = 'PORTABLE',
        RECLAMATION = 'RECLAMATION',
        VIRTUAL_CARD = 'VIRTUAL_CARD',
        BANK_CARD = 'BANK_CARD',
    }


}
