/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SystemEshopOrderQuery = {
    /**
     * Payment state
     */
    state?: SystemEshopOrderQuery.state;
    /**
     * Payment type. Bank card or Invoice.
     */
    paymentType?: any;
    /**
     * Filter by payment date from within date range. Type: 'DATE'
     */
    paymentDateFrom?: string;
    /**
     * Filter by payment date to within date range. Type: 'DATE'
     */
    paymentDateTo?: string;
    /**
     * Filter by issued from within date range. Type: 'DATE'
     */
    issuedFrom?: string;
    /**
     * Filter by issued to within date range. Type: 'DATE'
     */
    issuedTo?: string;
    /**
     * Filter only orders by variable symbol
     */
    variableSymbol?: string;
    /**
     * Filter only orders by card number
     */
    cardNumber?: string;
    /**
     * Filter only card application by number
     */
    accountEmail?: string;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
    /**
     * Page Size. Default: 25
     */
    limit?: number;
    /**
     * Offsetting x items. Default: 0
     */
    offset?: number;
    /**
     * Sort by. Default: id
     */
    sortBy?: string;
    /**
     * Sort order descendent. Default: true
     */
    sortDesc?: boolean;
    /**
     * Sort with locale. Default: sk
     */
    sortLocale?: string;
    /**
     * Filter by date within date range. Type: 'DATE'
     */
    date?: string;
};

export namespace SystemEshopOrderQuery {

    /**
     * Payment state
     */
    export enum state {
        PENDING = 'PENDING',
        PAID = 'PAID',
        CANCELLED = 'CANCELLED',
        REJECTED = 'REJECTED',
        RETURNED = 'RETURNED',
        PROCESSING = 'PROCESSING',
    }


}
