import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { GlobalSettingDto } from '@/api';
import { GlobalSetting as GlobalSettingConstants } from '@/constants/GlobalSettting';

const globalSettingFactory = (): GlobalSettingDto => {
  return {
    id: '',
    displayName: '',
    name: '',
    value: 0,
  } as GlobalSettingDto;
};

@Module({ dynamic: true, store, namespaced: true, name: 'globalSetting' })
class GlobalSetting extends VuexModule {
  public globalSettings: Array<GlobalSettingDto> = [];
  public customerCardValidity: GlobalSettingDto = globalSettingFactory();

  @Mutation
  public setGlobalSettings(payload: Array<GlobalSettingDto>): void {
    this.globalSettings = payload;
    this.customerCardValidity =
      payload.find(setting => setting.name === GlobalSettingConstants.CUSTOMER_CARD_VALIDITY) || globalSettingFactory();
  }
}

export default getModule(GlobalSetting);
