/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SystemConfigurationResponse } from '../models/SystemConfigurationResponse';
import type { UpdateSystemConfigurationCommand } from '../models/UpdateSystemConfigurationCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SystemConfigurationService {

    /**
     * Get system configuration
     * @returns SystemConfigurationResponse 
     * @throws ApiError
     */
    public static systemConfigurationControllerGetSystemConfiguration(): CancelablePromise<SystemConfigurationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/system-configurations',
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Update system configuration
     * @returns SystemConfigurationResponse 
     * @throws ApiError
     */
    public static systemConfigurationControllerUpdateSystemConfiguration({
requestBody,
}: {
/**
 * UpdateSystemConfigurationCommand
 */
requestBody?: UpdateSystemConfigurationCommand,
}): CancelablePromise<SystemConfigurationResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/public/system-configurations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

}
