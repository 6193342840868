import TicketFareIcon from '@/components/dpmp/icons/TicketFareIcon.vue';
import CardManagingAccountIcon from '@/components/dpmp/icons/CardManagingAccountIcon.vue';
import ElectronicWalletIcon from '@/components/dpmp/icons/ElectronicWalletIcon.vue';
import ShoppingCartTopIcon from '@/components/dpmp/icons/ShoppingCartTopIcon.vue';
import UserAccountIcon from '@/components/dpmp/icons/UserAccountIcon.vue';
import UserAccountTopIcon from '@/components/dpmp/icons/UserAccountTopIcon.vue';
import OrderListIcon from '@/components/dpmp/icons/OrderListIcon.vue';
import ChipCardIcon from '@/components/dpmp/icons/ChipCardIcon.vue';
import AssignedCardListIcon from '@/components/dpmp/icons/AssignedCardListIcon.vue';
import ManagedCardListIcon from '@/components/dpmp/icons/ManagedCardListIcon.vue';
import CardApplicationListIcon from '@/components/dpmp/icons/CardApplicationListIcon.vue';
import PakTransferIcon from '@/components/dpmp/icons/PakTransferIcon.vue';
import NewCardApplicationIcon from '@/components/dpmp/icons/NewCardApplicationIcon.vue';
import AssignCardIcon from '@/components/dpmp/icons/AssignCardIcon.vue';
import PayByCardIcon from '@/components/dpmp/icons/PayByCardIcon.vue';
import PayByInvoiceIcon from '@/components/dpmp/icons/PayByInvoiceIcon.vue';
import CancelAllIcon from '@/components/dpmp/icons/CancelAllIcon.vue';
import ManageCardRequestIcon from '@/components/dpmp/icons/ManageCardRequestIcon.vue';
import LogoutIcon from '@/components/dpmp/icons/LogoutIcon.vue';
import LoginIcon from '@/components/dpmp/icons/LoginIcon.vue';
import RegistrationSendIcon from '@/components/dpmp/icons/RegistrationSendIcon.vue';
import NotificationSettingIcon from '@/components/dpmp/icons/NotificationSettingIcon.vue';

export const dpmpIcons = {
  cardManagingAccountIcon: CardManagingAccountIcon,
  ticketFareIcon: TicketFareIcon,
  electronicWalletIcon: ElectronicWalletIcon,
  shoppingCartIcon: ShoppingCartTopIcon,
  shoppingCartTopIcon: ShoppingCartTopIcon,
  userAccountIcon: UserAccountIcon,
  userAccountTopIcon: UserAccountTopIcon,
  orderListIcon: OrderListIcon,
  chipCardIcon: ChipCardIcon,
  assignedCardListIcon: AssignedCardListIcon,
  managedCardListIcon: ManagedCardListIcon,
  cardApplicationListIcon: CardApplicationListIcon,
  pakTransferIcon: PakTransferIcon,
  newCardApplicationIcon: NewCardApplicationIcon,
  assignCardIcon: AssignCardIcon,
  payByCardIcon: PayByCardIcon,
  payByInvoiceIcon: PayByInvoiceIcon,
  cancelAllIcon: CancelAllIcon,
  manageCardRequestIcon: ManageCardRequestIcon,
  logoutIcon: LogoutIcon,
  loginIcon: LoginIcon,
  registrationSendIcon: RegistrationSendIcon,
  forgottenPasswordIcon: RegistrationSendIcon,
  verificationCodeIcon: RegistrationSendIcon,
  notificationSettingIcon: NotificationSettingIcon,
};
