<template>
  <div class="data-table">
    <v-data-table
      ref="dataTable"
      :headers="translatedHeaders"
      :items="shoppingCartModule.items"
      data-cy="shoppingCart-table"
      :no-data-text="$t('shoppingCart.shoppingCartTable.noData')"
      disable-sort
      disable-pagination
      hide-default-footer
      class="elevation-1 border"
    >
      <template v-if="$vuetify.display.mobile" #headers></template>

      <template #item="{ item }">
        <tr>
          <td
            v-for="(column, key) in translatedHeaders"
            :key="key"
            :title="column.tooltip"
            :class="`
              font-weight-${column.fontWeight || 'regular'}
              ${$vuetify.display.mobile ? 'xs-td' : ''}
              d-sm-table-cell
              d-block`"
          >
            <div v-if="column.value === HEADER_BUTTONS.value" class="d-flex justify-end">
              <v-btn
                v-if="isItemRemovable(item)"
                :title="$t('shoppingCart.shoppingCartTable.deleteTitle')"
                :data-cy="`shoppingCart-table-edit-${item.id}`"
                class="mx-1 elevation-4"
                icon
                size="x-small"
                color="buttonWarning"
                theme="dark"
                @click.stop="deleteItem(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <div class="data-container" :class="`justify-sm-${column.align || 'start'}`">
                <div v-if="$vuetify.display.mobile" :class="`'pr-2' ${$vuetify.display.mobile ? 'text-truncate' : ''}`">
                  {{ column.text ? `${column.text}: ` : `` }}
                </div>
                <div class="data-content">
                  <div :class="{ 'data-label text-truncate': $vuetify.display.mobile }">
                    {{ transformValue(item, column) }}
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template #bottom></template>
    </v-data-table>

    <RDPConfirmDialog
      ref="confirmDeleteDialog"
      :title="$t('shoppingCart.shoppingCartTable.deleteTitle')"
      :text="$t('shoppingCart.shoppingCartTable.deleteConfirmationText')"
      :confirm="confirmDeleteItem"
      data-cy="shoppingCart-table-deleteConfirmDialog"
    >
    </RDPConfirmDialog>
    <ConfirmCancelApplicationItemDialog
      ref="confirmCancelApplicationItemDialog"
      :confirm-callback="cancelCardApplication"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue } from 'vue-facing-decorator';
import ShoppingCartModule from '@/store/modules/shoppingCart';
import DataTableHelper, { DATA_TABLE_HEADER_TYPES } from '@/components/common/RDPDataTable/DataTableHelper';
import RDPConfirmDialog from '@/components/common/RDPConfirmDialog.vue';
import shoppingCartService from '@/services/api/shoppingCartService';
import { ShoppingCartItemVm } from '@/api';
import { RDPDialogInterface } from '@/components/common/RDPDialogInterface';
import ConfirmCancelApplicationItemDialog from '@/components/shopingCart/dialogs/ConfirmCancelApplicationItemDialog.vue';
import { ConfirmCancelApplicationItemDialogInterface } from '@/components/shopingCart/dialogs/ConfirmCancelApplicationItemDialogInterface';
import { toMapCardNumber } from '@/utils/customerCard';
import { formatOrderItemName, Formattable } from '@/utils/orderItem';
import ToastModule from '@/store/modules/toast';
import { toAmount, toPercentage, toPrice } from '@/filters';
import { ColumnInterface, ItemInterface } from '@/components/common/RDPDataTable/RDPDataTableInterfaces.ts';

@Component({
  components: {
    RDPConfirmDialog,
    ConfirmCancelApplicationItemDialog,
  },
})
export default class ShoppingCartTable extends Vue {
  @Prop({ required: false, type: Function, default: () => undefined })
  readonly refreshTableCallback!: () => void;
  @Ref()
  readonly confirmDeleteDialog!: RDPDialogInterface;
  @Ref()
  readonly confirmCancelApplicationItemDialog!: ConfirmCancelApplicationItemDialogInterface;

  shoppingCartModule = ShoppingCartModule;
  dataTableHelper = new DataTableHelper('shoppingCart.shoppingCartTable');
  itemToDelete: ShoppingCartItemVm | undefined = undefined;
  descriptionWidth = '450px';
  HEADER_BUTTONS = {
    title: '',
    text: '',
    value: '__buttons',
    key: '__buttons',
    width: '1%',
    sortable: false,
  };

  get headers() {
    return [
      { text: 'customer', transform: this.getItemCustomer },
      { text: 'name', transform: this.getItemName },
      { text: 'description', width: this.descriptionWidth, transform: this.getItemDescription },
      { text: 'price', type: DATA_TABLE_HEADER_TYPES.PRICE },
      { text: 'taxPercentage', type: DATA_TABLE_HEADER_TYPES.PERCENTAGE },
      {
        text: 'quantity',
        type: DATA_TABLE_HEADER_TYPES.AMOUNT,
        align: 'center',
      },
      { text: 'totalPrice', type: DATA_TABLE_HEADER_TYPES.PRICE },
      this.HEADER_BUTTONS,
    ];
  }

  get translatedHeaders() {
    return this.dataTableHelper.translateHeaders(this.headers);
  }

  transformValue(item: ItemInterface, column: ColumnInterface): string | boolean {
    return this.dataTableHelper.transformValue(item, column);
  }

  getItemCustomer(item: ShoppingCartItemVm) {
    return item.customer ? `${item.customer.lastName} ${item.customer.firstName}` : '';
  }

  getItemName(item: ShoppingCartItemVm) {
    return formatOrderItemName(item as Formattable);
  }

  getItemDescription(item: ShoppingCartItemVm) {
    if (!item.description && item.payload?.cardNumber) {
      return this.$t('shoppingCart.shoppingCartTable.noteCardNumber', {
        cardNumber: toMapCardNumber(item.payload.cardNumber),
      });
    }

    return item.description;
  }

  deleteItem(item: ShoppingCartItemVm) {
    if (item.payload.applicationId) {
      this.confirmCancelApplicationItemDialog.openDialog(item);
    } else {
      this.itemToDelete = item;
      this.confirmDeleteDialog.openDialog();
    }
  }

  isItemRemovable(item: ShoppingCartItemVm) {
    return !item.payload?.nonRemovable;
  }

  cancelCardApplication() {
    this.refreshTableCallback();
  }

  async confirmDeleteItem() {
    try {
      if (this.itemToDelete) {
        await shoppingCartService.removeItem(this.itemToDelete.id);
        this.refreshTableCallback();
        ToastModule.success({
          message: this.$t('shoppingCart.shoppingCartTable.deleteSuccess'),
        });
      }
    } catch (e) {
      ToastModule.error({
        message: this.$t('shoppingCart.shoppingCartTable.deleteFailed'),
      });
    }
  }

  toAmount(value: string) {
    return toAmount(value);
  }

  toPercentage(value: string) {
    return toPercentage(value);
  }

  toPrice(value: string) {
    return toPrice(value);
  }
}
</script>
<style lang="scss" scoped>
.border {
  border: 1px solid #0000002b;
}

.data-table {
  width: 100%;
}

.data-container {
  display: flex !important;
  align-items: center;
}

.xs-td {
  border-bottom: 0 !important;
  height: 24px !important;
  width: 100%;

  &:last-child {
    height: 35px !important;
  }

  .data-container {
    .data-label {
      font-weight: 900;
      padding-left: 0.5rem;
    }
  }
}
</style>
