import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import {
  AddressDto,
  BranchOfficeDto,
  CardApplicationDto,
  CardApplicationProfileDto,
  CardProfileVm,
  CustomerDetailVm,
  CustomerDto,
  PersonDto,
} from '@/api';
import cloneDeep from 'lodash/cloneDeep';
import { _branchOffice } from '@/constants/BranchOffice';
import { _address } from '@/constants/Address';
import { DATE_FORMAT, DATE_PICKER_FORMAT, isDateOneAfterDateTwo } from '@/utils/dateTime';
import isDefined, { isArrayEmpty } from '@common/utils/isDefined';
import { shouldSetValidTo } from '@/utils/customerProfileValidToDefault';
import moment from 'moment';
import { _custodianData } from './customer';

export const REGISTER_NEW_BANK_CARD = 'REGISTER_NEW_BANK_CARD';

const _cardData = {
  serialNumber: 0,
  applicationNumber: '',
  id: '',
  note: '',
  created: '',
  keepPhoto: false,
  state: {
    id: '',
    name: '',
    displayName: '',
  },
  customerChanges: [],
  customer: {
    id: '',
    firstName: '',
    lastName: '',
    fullName: '',
    birthDay: '',
    created: '',
    draft: false,
    originId: '',
  },
  profileOne: {
    profile: {
      id: '',
      name: '',
    },
    validFrom: '',
    validTo: '',
    confirmationChecked: '',
  },
  profileTwo: {
    profile: {
      id: '',
      name: '',
    },
    validFrom: '',
    validTo: '',
    confirmationChecked: '',
  },
  contactMeIfCardFound: false,
  recipientAddress: {
    street: '',
    streetNumber: '',
    city: '',
    zip: '',
  },
  deliveryBranchOffice: {
    id: '',
    name: '',
    branchNumber: 0,
    address: {
      street: '',
      streetNumber: '',
      city: '',
      zip: '',
    },
  },
  eshop: true,
  paid: false,
  checkedBy: {} as PersonDto,
  checkedDate: '',
  cardForFree: false,
} as unknown as CardApplicationDto;

const _customerData = {
  id: '',
  firstName: '',
  lastName: '',
  fullName: '',
  birthDay: '',
  photo: '',
  email: '',
  phoneNumber: '',
  originId: '',
  address: {
    street: '',
    streetNumber: '',
    city: '',
    zip: '',
  },
  parent: { address: {} } as CustomerDto,
  draft: false,
  changes: [],
  lastCard: undefined,
  pin: '',
} as CustomerDetailVm;

const _profilesAttachment: ProfilesAttachment = {
  profileOneAttachments: [],
  profileTwoAttachments: [],
};

export interface Attachment {
  id: string;
  file: File;
}

interface ProfilesAttachment {
  profileOneAttachments: Attachment[];
  profileTwoAttachments: Attachment[];
}

export interface AttachmentPayload {
  attachmentTypeId: string;
  attachmentFile: File;
  isProfileOne: boolean;
}

@Module({ dynamic: true, store, namespaced: true, name: 'cardApplication' })
class CardApplication extends VuexModule {
  public cardData: CardApplicationDto = cloneDeep(_cardData);
  public customerData: CustomerDetailVm = cloneDeep(_customerData);
  public custodianData: CustomerDto = cloneDeep(_custodianData);
  public custodianAdded = false;
  public deliveryBranchOfficeData: BranchOfficeDto = cloneDeep(_branchOffice);
  public recipientAddressData: AddressDto = cloneDeep(_address);
  public termsAndConditionsApproval = false;
  public gdprApproval = false;
  public marketingConsent = true;
  public customerProfileAutoUpdated = false;
  public profilesAreInCollision = false;
  public attachments: ProfilesAttachment = cloneDeep(_profilesAttachment);
  public bankCardId = '';

  @Mutation
  public setCustomerData(payload: CustomerDetailVm): void {
    payload.birthDay = CardApplication.formatBirthday(payload.birthDay);
    this.customerData = payload;
  }

  @Mutation
  public setCardData(payload: CardApplicationDto): void {
    this.cardData = payload;
  }

  @Mutation
  public setProfileOne(payload: CardApplicationProfileDto): void {
    this.cardData.profileOne = payload;
  }

  @Mutation
  public initCardData(): void {
    this.cardData = cloneDeep(_cardData);
  }

  @Mutation
  public setBranchOfficeData(payload: BranchOfficeDto): void {
    this.deliveryBranchOfficeData = payload;
  }

  @Mutation
  public setBankCardId(id: string): void {
    this.bankCardId = id;
  }

  @Mutation
  public setRecipientAddressData(payload: AddressDto): void {
    this.recipientAddressData = payload;
  }

  @Mutation
  public setCustomerPhoto(payload: string): void {
    this.customerData.photo = payload;
  }

  @Mutation
  public setCustodianData(payload: CustomerDto): void {
    payload.birthDay = CardApplication.formatBirthday(payload.birthDay);
    this.custodianData = payload;
  }

  @Mutation
  public resetCustodian(): void {
    this.custodianData = cloneDeep(_custodianData);
    this.custodianAdded = false;
  }

  public get isCustodianAdded() {
    return this.custodianAdded || !!this.custodianData.id;
  }

  public get isCustomerFilled() {
    return !!this.customerData.id;
  }

  public get isCustomerVerified() {
    return (
      isDefined(this.customerData.personalDataVerifiedDate) && isDefined(this.customerData.personalDataVerifiedBy?.id)
    );
  }

  @Mutation
  public setCustodianAdded(isCustodianAdded: boolean) {
    this.custodianAdded = isCustodianAdded;
  }

  @Mutation
  public resetBranchOfficeData(): void {
    this.deliveryBranchOfficeData = cloneDeep(_branchOffice);
  }

  @Mutation
  public resetBankCardId(): void {
    this.bankCardId = '';
  }

  @Mutation
  public resetRecipientAddressData(): void {
    this.recipientAddressData = cloneDeep(_address);
  }

  @Mutation
  public setProfilesInCollision(collision: boolean) {
    this.profilesAreInCollision = collision;
  }

  @Mutation
  public initCardApplication(): void {
    this.cardData = cloneDeep(_cardData) as CardApplicationDto;
    this.customerData = cloneDeep(_customerData);
    this.custodianData = cloneDeep(_custodianData);
    this.deliveryBranchOfficeData = cloneDeep(_branchOffice);
    this.recipientAddressData = cloneDeep(_address);
    this.termsAndConditionsApproval = false;
    this.gdprApproval = false;
    this.marketingConsent = true;
    this.attachments = cloneDeep(_profilesAttachment);
    this.customerProfileAutoUpdated = false;
  }

  @Mutation
  public setTermsAndConditionsApproval(value: boolean) {
    this.termsAndConditionsApproval = value;
  }

  @Mutation
  public setGdprApproval(value: boolean) {
    this.gdprApproval = value;
  }

  @Mutation
  public setMarketingConsent(value: boolean) {
    this.marketingConsent = value;
  }

  @Mutation
  public setVerifiedProfile(customerDetail: CustomerDetailVm) {
    this.customerProfileAutoUpdated = false;

    const oldProfiles = [customerDetail.lastCard?.profileOne, customerDetail.lastCard?.profileTwo];

    const profileIsValid = (profile: CardProfileVm) => {
      const { validFrom, validTo } = profile;
      return !validFrom || !validTo || moment().isSameOrBefore(validTo, 'day');
    };

    const createEmptyProfileDto = () =>
      ({
        profile: {
          id: '',
          name: '',
        },
        validFrom: '',
      }) as CardApplicationProfileDto;

    const copyProfileToDto = (
      target: CardApplicationProfileDto,
      source: CardProfileVm,
      secondSource: CardProfileVm | undefined,
    ): void => {
      const currentDate = moment().format(DATE_FORMAT);
      target.validFrom = isDateOneAfterDateTwo(source.validFrom, currentDate) ? source.validFrom || '' : currentDate;
      if (secondSource) {
        target.validTo = profileIsValid(secondSource) ? source.validTo : '';
      } else {
        target.validTo = (shouldSetValidTo(source.profile) && source.validTo) || '';
      }

      target.profile = source.profile;
      target.confirmationChecked = source.confirmationChecked;
    };

    const tryAutoUpdateCustomerProfile = (
      targetProperty: 'profileOne' | 'profileTwo',
      source: CardProfileVm | undefined,
      secondSource: CardProfileVm | undefined,
    ): void => {
      if (source) {
        const target = this.cardData[targetProperty] || createEmptyProfileDto();
        copyProfileToDto(target, source, secondSource);
        this.cardData[targetProperty] = target;
        this.customerProfileAutoUpdated = true;
      }
    };

    const reusableProfiles: CardProfileVm[] = [];
    for (const profile of oldProfiles) {
      if (profile) {
        if (profileIsValid(profile)) {
          reusableProfiles.push(profile);
        }
      }
    }

    const [sourceOne, sourceTwo] = reusableProfiles;
    tryAutoUpdateCustomerProfile('profileOne', sourceOne, sourceTwo);
    tryAutoUpdateCustomerProfile('profileTwo', sourceTwo, sourceOne);
  }

  @Mutation
  public setAttachment(payload: AttachmentPayload): void {
    const attachment: Attachment = {
      id: payload.attachmentTypeId,
      file: payload.attachmentFile,
    };

    const handleAttachments = (attachments: Attachment[], newAttachment: Attachment) => {
      const index = attachments.findIndex(attachment => attachment.id === newAttachment.id);
      if (index === -1) {
        if (newAttachment.file) {
          attachments.push(newAttachment);
        }
      } else {
        if (newAttachment.file) {
          attachments[index] = newAttachment;
        } else {
          attachments.splice(index, 1);
        }
      }
    };

    if (payload.isProfileOne) {
      handleAttachments(this.attachments.profileOneAttachments, attachment);
    } else {
      handleAttachments(this.attachments.profileTwoAttachments, attachment);
    }
  }

  get emptyAttachments() {
    return isArrayEmpty(this.attachments.profileOneAttachments) && isArrayEmpty(this.attachments.profileTwoAttachments);
  }

  @Mutation
  public clearAttachments(isProfileOne?: boolean): void {
    if (isDefined(isProfileOne)) {
      if (isProfileOne) {
        this.attachments.profileOneAttachments = cloneDeep(_profilesAttachment.profileOneAttachments);
      } else {
        this.attachments.profileTwoAttachments = cloneDeep(_profilesAttachment.profileTwoAttachments);
      }
    } else {
      this.attachments = cloneDeep(_profilesAttachment);
    }
  }

  private static formatBirthday(date: string): string {
    // check date has already proper format
    if (date && moment(date, DATE_PICKER_FORMAT).format(DATE_PICKER_FORMAT) === date) {
      return date;
    }
    return date ? moment(date).format(DATE_PICKER_FORMAT) : '';
  }

  get customerDateBirthday(): string {
    return this.customerData.birthDay ? moment(this.customerData.birthDay, DATE_PICKER_FORMAT).format(DATE_FORMAT) : '';
  }
}

export default getModule(CardApplication);
