/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateSystemEshopOrderCommand = {
    /**
     * Payment state
     */
    state: UpdateSystemEshopOrderCommand.state;
    note?: string;
    /**
     * Payment Date. Type: 'DATE'
     */
    paymentDate?: string;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
};

export namespace UpdateSystemEshopOrderCommand {

    /**
     * Payment state
     */
    export enum state {
        PENDING = 'PENDING',
        PAID = 'PAID',
        CANCELLED = 'CANCELLED',
        REJECTED = 'REJECTED',
        RETURNED = 'RETURNED',
        PROCESSING = 'PROCESSING',
    }


}
