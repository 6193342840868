/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PayShortTermTicketsByWalletCommand = {
    /**
     * Operation types.
     */
    operation: PayShortTermTicketsByWalletCommand.operation;
    /**
     * Price.
     */
    price: number;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
};

export namespace PayShortTermTicketsByWalletCommand {

    /**
     * Operation types.
     */
    export enum operation {
        RECHARGE = 'RECHARGE',
        WITHDRAW = 'WITHDRAW',
        PAYMENT = 'PAYMENT',
        TRANSFER = 'TRANSFER',
        PAK_TRANSFER = 'PAK_TRANSFER',
    }


}
