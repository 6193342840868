<template>
  <div>
    <v-dialog
      v-model="show"
      :fullscreen="$vuetify.display.mobile"
      max-width="600"
      persistent
      @keydown.esc="closeDialog"
    >
      <v-card>
        <v-card-title class="text-h5 d-flex dialog-title">
          <v-icon size="large" start> mdi-help-circle</v-icon>
          <div class="align-self-center">{{ $t(`shoppingCart.confirmCancelApplicationItem.title`) }}</div>
        </v-card-title>

        <v-card-text class="text-body-1">
          <v-container>
            {{ $t(`shoppingCart.confirmCancelApplicationItem.text`) }}
          </v-container>
          <v-container>
            <slot />
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="buttonWarning" variant="elevated" class="mr-2" @click="closeDialog">
            <template #prepend>
              <v-icon>mdi-cancel</v-icon>
            </template>
            {{ $t('buttons.no') }}
          </v-btn>
          <ConfirmButton
            :confirm="confirmDialogCancelApplication"
            :text="$t(`shoppingCart.confirmCancelApplicationItem.buttonCancelApplication`)"
            dark
            button-color="buttonPrimary"
            :data-cy="cy('cancel-application-button')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import { ShoppingCartItemVm } from '@/api';
import cardApplicationService from '@/services/api/cardApplicationService';
import { ConfirmCancelApplicationItemDialogInterface } from '@/components/shopingCart/dialogs/ConfirmCancelApplicationItemDialogInterface';
import ToastModule from '@/store/modules/toast';

@Component({
  components: {
    ConfirmButton,
  },
})
export default class ConfirmCancelApplicationItemDialog
  extends Vue
  implements ConfirmCancelApplicationItemDialogInterface
{
  @Prop({ required: true, type: Function, default: () => undefined })
  readonly confirmCallback!: () => void;

  show = false;
  dataCy = 'shopping-cart-cancel-application-dialog';
  itemToCancel: ShoppingCartItemVm | undefined;

  /* Methods */
  openDialog(itemToCancel: ShoppingCartItemVm) {
    this.itemToCancel = itemToCancel;
    this.show = true;
  }

  closeDialog() {
    this.show = false;
  }

  async confirmDialogCancelApplication() {
    try {
      if (this.itemToCancel) {
        await cardApplicationService.rejectCardApplication(
          this.itemToCancel.payload?.applicationId,
          this.$t('shoppingCart.customerCancelApplicationInEshop'),
        );
        this.confirmCallback();
        this.closeDialog();
        ToastModule.success({
          message: this.$t('shoppingCart.confirmCancelApplicationItem.applicationCanceled'),
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  cy(partialDataCy: string) {
    return `${this.dataCy}-${partialDataCy}`;
  }
}
</script>
