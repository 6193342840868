/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EnumsDto = {
    bankPaymentStates: EnumsDto.bankPaymentStates;
    carrierTypes: EnumsDto.carrierTypes;
    cardApplicationStates: EnumsDto.cardApplicationStates;
    cardProductionStates: EnumsDto.cardProductionStates;
    cardStates: EnumsDto.cardStates;
    cardTypes: EnumsDto.cardTypes;
    customerAccountLinkTypes: EnumsDto.customerAccountLinkTypes;
    customerCardTypes: EnumsDto.customerCardTypes;
    customerProfileType: EnumsDto.customerProfileType;
    customerProfileValidToDefaults: EnumsDto.customerProfileValidToDefaults;
    dataProtectionTypes: EnumsDto.dataProtectionTypes;
    denyCardStates: EnumsDto.denyCardStates;
    electronicWalletOperations: EnumsDto.electronicWalletOperations;
    employeeCardRoleTypes: EnumsDto.employeeCardRoleTypes;
    eshopOrderStates: EnumsDto.eshopOrderStates;
    globalSettings: EnumsDto.globalSettings;
    orderItemTypes: EnumsDto.orderItemTypes;
    paymentTypes: EnumsDto.paymentTypes;
    permissions: EnumsDto.permissions;
    reclamationStates: EnumsDto.reclamationStates;
    reclamationCardTypes: EnumsDto.reclamationCardTypes;
    reclamationEvaluationTypes: EnumsDto.reclamationEvaluationTypes;
    reclamationFinalizationTypes: EnumsDto.reclamationFinalizationTypes;
    reclamationSubjectTypes: EnumsDto.reclamationSubjectTypes;
    refundTypes: EnumsDto.refundTypes;
    stockActions: EnumsDto.stockActions;
    stockTypes: EnumsDto.stockTypes;
    tariffProfilePeriods: EnumsDto.tariffProfilePeriods;
    tariffProfileTypes: EnumsDto.tariffProfileTypes;
    ticketStates: EnumsDto.ticketStates;
    validityFilter: EnumsDto.validityFilter;
    vehicleTransactionTypes: EnumsDto.vehicleTransactionTypes;
    zones: EnumsDto.zones;
};

export namespace EnumsDto {

    export enum bankPaymentStates {
        PROCESSED = 'PROCESSED',
        TO_BE_PROCESSED = 'TO_BE_PROCESSED',
        DENIED = 'DENIED',
        TAP_MISSING = 'TAP_MISSING',
        ANSWER_MISSING = 'ANSWER_MISSING',
        GPE_ERROR = 'GPE_ERROR',
        SKIPPED = 'SKIPPED',
    }

    export enum carrierTypes {
        HAULIER_CARD = 'HAULIER_CARD',
        VIRTUAL_CARD = 'VIRTUAL_CARD',
        BANK_CARD = 'BANK_CARD',
    }

    export enum cardApplicationStates {
        WAITING_TO_BE_PRINTED = 'WAITING_TO_BE_PRINTED',
        PROCESSED = 'PROCESSED',
        REJECTED = 'REJECTED',
        UNCHECKED_DATA = 'UNCHECKED_DATA',
    }

    export enum cardProductionStates {
        IN_PROGRESS = 'IN_PROGRESS',
        PROCESSED = 'PROCESSED',
    }

    export enum cardStates {
        NOT_ISSUED = 'NOT_ISSUED',
        ACTIVE = 'ACTIVE',
        BLOCKED = 'BLOCKED',
        EXPIRED = 'EXPIRED',
        IN_PROGRESS = 'IN_PROGRESS',
        TOKENIZED = 'TOKENIZED',
    }

    export enum cardTypes {
        SMART_CARD = 'SMART_CARD',
        SMART_CARD_2 = 'SMART_CARD_2',
        BANK_CARD = 'BANK_CARD',
        MOBILE_PHONE = 'MOBILE_PHONE',
        QR_CODE = 'QR_CODE',
    }

    export enum customerAccountLinkTypes {
        REGISTERED = 'REGISTERED',
        ASSIGNED = 'ASSIGNED',
        MANAGED = 'MANAGED',
    }

    export enum customerCardTypes {
        PERSONAL = 'PERSONAL',
        PORTABLE = 'PORTABLE',
        RECLAMATION = 'RECLAMATION',
        VIRTUAL_CARD = 'VIRTUAL_CARD',
        BANK_CARD = 'BANK_CARD',
    }

    export enum customerProfileType {
        IMPERSONAL = 'IMPERSONAL',
        PERSONAL = 'PERSONAL',
    }

    export enum customerProfileValidToDefaults {
        CARD_VALIDITY = 'CARD_VALIDITY',
        END_OF_YEAR = 'END_OF_YEAR',
        END_OF_JANUARY = 'END_OF_JANUARY',
        END_OF_AUGUST = 'END_OF_AUGUST',
        DAY_BEFORE_19_BIRTHDAY_OR_END_OF_AUGUST = 'DAY_BEFORE_19_BIRTHDAY_OR_END_OF_AUGUST',
        TO_AGE = 'TO_AGE',
        _365_DAYS_AFTER_CONFIRMATION_DELIVERY = '365_DAYS_AFTER_CONFIRMATION_DELIVERY',
        END_OF_SEPTEMBER = 'END_OF_SEPTEMBER',
    }

    export enum dataProtectionTypes {
        FULL_ACCESS = 'FULL_ACCESS',
        PSEUDONYMIZED = 'PSEUDONYMIZED',
        ANONYMIZED = 'ANONYMIZED',
    }

    export enum denyCardStates {
        NOT_IN_DENY_LIST = 'NOT_IN_DENY_LIST',
        IS_IN_DENY_LIST = 'IS_IN_DENY_LIST',
        WAS_IN_DENY_LIST = 'WAS_IN_DENY_LIST',
        DENY_LIST_RECOVER_DEPLETED = 'DENY_LIST_RECOVER_DEPLETED',
        CARD_NOT_FOUND = 'CARD_NOT_FOUND',
    }

    export enum electronicWalletOperations {
        RECHARGE = 'RECHARGE',
        WITHDRAW = 'WITHDRAW',
        PAYMENT = 'PAYMENT',
        TRANSFER = 'TRANSFER',
        PAK_TRANSFER = 'PAK_TRANSFER',
    }

    export enum employeeCardRoleTypes {
        INSPECTOR = 'INSPECTOR',
        DRIVER = 'DRIVER',
        ANONYMOUS = 'ANONYMOUS',
        TECHNICIAN = 'TECHNICIAN',
        CASHIER = 'CASHIER',
        SALE = 'SALE',
    }

    export enum eshopOrderStates {
        PENDING = 'PENDING',
        PAID = 'PAID',
        CANCELLED = 'CANCELLED',
        REJECTED = 'REJECTED',
        RETURNED = 'RETURNED',
        PROCESSING = 'PROCESSING',
    }

    export enum globalSettings {
        CUSTOMER_CARD_VALIDITY = 'CUSTOMER_CARD_VALIDITY',
        RECLAMATION_CARD_VALIDITY = 'RECLAMATION_CARD_VALIDITY',
        E_WALLET_MIN_RECHARGE = 'E_WALLET_MIN_RECHARGE',
        E_WALLET_MAX_RECHARGE = 'E_WALLET_MAX_RECHARGE',
        E_WALLET_MAX_AMOUNT = 'E_WALLET_MAX_AMOUNT',
        ESHOP_CARD_EXPIRATION_NOTIFICATION = 'ESHOP_CARD_EXPIRATION_NOTIFICATION',
        ESHOP_TICKET_EXPIRATION_NOTIFICATION = 'ESHOP_TICKET_EXPIRATION_NOTIFICATION',
        TICKET_FARE_RETURN_FEE = 'TICKET_FARE_RETURN_FEE',
        MAX_DAYS_BETWEEN_CURRENT_DATE_AND_TICKET_FARE_VALID_FROM_BEFORE_START_AT_COUNTER = 'MAX_DAYS_BETWEEN_CURRENT_DATE_AND_TICKET_FARE_VALID_FROM_BEFORE_START_AT_COUNTER',
        MAX_DAYS_BETWEEN_CURRENT_DATE_AND_TICKET_FARE_VALID_FROM_AFTER_START_AT_COUNTER = 'MAX_DAYS_BETWEEN_CURRENT_DATE_AND_TICKET_FARE_VALID_FROM_AFTER_START_AT_COUNTER',
        MAX_DAYS_BETWEEN_CURRENT_DATE_AND_TICKET_FARE_VALID_FROM_BEFORE_START_AT_ESHOP = 'MAX_DAYS_BETWEEN_CURRENT_DATE_AND_TICKET_FARE_VALID_FROM_BEFORE_START_AT_ESHOP',
        RECLAMATION_FINALIZATION_TIME_IN_DAYS = 'RECLAMATION_FINALIZATION_TIME_IN_DAYS',
        E_WALLET_TRANSACTION_TAX_PERCENTAGE = 'E_WALLET_TRANSACTION_TAX_PERCENTAGE',
        VIRTUAL_CARD_VALIDITY = 'VIRTUAL_CARD_VALIDITY',
        VIRTUAL_CARD_MAX_SHORT_TERM_TICKET_FARES_COUNT = 'VIRTUAL_CARD_MAX_SHORT_TERM_TICKET_FARES_COUNT',
    }

    export enum orderItemTypes {
        FEE = 'FEE',
        PRODUCT = 'PRODUCT',
        TICKET_FARE = 'TICKET_FARE',
        PAK_TICKET_FARE = 'PAK_TICKET_FARE',
        ELECTRONIC_WALLET = 'ELECTRONIC_WALLET',
        RECLAMATION = 'RECLAMATION',
        ROUNDING = 'ROUNDING',
    }

    export enum paymentTypes {
        CASH = 'CASH',
        BANK_CARD = 'BANK_CARD',
        ELECTRONIC_WALLET = 'ELECTRONIC_WALLET',
        INVOICE = 'INVOICE',
    }

    export enum permissions {
        PERMISSION_CUSTOMER_CARDS_RECLAMATION = 'PERMISSION_CUSTOMER-CARDS_RECLAMATION',
        PERMISSION_CUSTOMER_CARDS_EDIT = 'PERMISSION_CUSTOMER-CARDS_EDIT',
        PERMISSION_CUSTOMER_CARDS_VIEW = 'PERMISSION_CUSTOMER-CARDS_VIEW',
        PERMISSION_CUSTOMER_CARDS_DETAIL = 'PERMISSION_CUSTOMER-CARDS_DETAIL',
        PERMISSION_EMPLOYEE_CARDS_EDIT = 'PERMISSION_EMPLOYEE-CARDS_EDIT',
        PERMISSION_EMPLOYEE_CARDS_VIEW = 'PERMISSION_EMPLOYEE-CARDS_VIEW',
        PERMISSION_VEHICLE_TRANSACTIONS_VIEW = 'PERMISSION_VEHICLE-TRANSACTIONS_VIEW',
        PERMISSION_ORDERS_VIEW_ALL = 'PERMISSION_ORDERS_VIEW-ALL',
        PERMISSION_ORDERS_VIEW_RESTRICTED = 'PERMISSION_ORDERS_VIEW-RESTRICTED',
        PERMISSION_ORDERS_EDIT = 'PERMISSION_ORDERS_EDIT',
        PERMISSION_COUNTER_SELL = 'PERMISSION_COUNTER_SELL',
        PERMISSION_STOCKS_VIEW = 'PERMISSION_STOCKS_VIEW',
        PERMISSION_STOCKS_MANAGE = 'PERMISSION_STOCKS_MANAGE',
        PERMISSION_ADMINISTRATION_VIEW = 'PERMISSION_ADMINISTRATION_VIEW',
        PERMISSION_CARD_APPLICATIONS_VIEW = 'PERMISSION_CARD-APPLICATIONS_VIEW',
        PERMISSION_CARD_APPLICATIONS_EDIT = 'PERMISSION_CARD-APPLICATIONS_EDIT',
        PERMISSION_CARD_APPLICATION_DRAFTS_VIEW = 'PERMISSION_CARD-APPLICATION-DRAFTS_VIEW',
        PERMISSION_CARD_APPLICATION_DRAFTS_EDIT = 'PERMISSION_CARD-APPLICATION-DRAFTS_EDIT',
        PERMISSION_PRODUCTS_MANAGE = 'PERMISSION_PRODUCTS_MANAGE',
        PERMISSION_EMPLOYEES_MANAGE = 'PERMISSION_EMPLOYEES_MANAGE',
        PERMISSION_PAK_TICKET_FARES_VIEW = 'PERMISSION_PAK-TICKET-FARES_VIEW',
        PERMISSION_BANK_PAYMENTS_VIEW = 'PERMISSION_BANK-PAYMENTS_VIEW',
        PERMISSION_CARD_PRODUCTIONS_MANAGE = 'PERMISSION_CARD-PRODUCTIONS_MANAGE',
        PERMISSION_STATISTICS_VIEW = 'PERMISSION_STATISTICS_VIEW',
        PERMISSION_ESHOP_ORDERS_VIEW = 'PERMISSION_ESHOP_ORDERS_VIEW',
        PERMISSION_ESHOP_ORDERS_EDIT = 'PERMISSION_ESHOP_ORDERS_EDIT',
        PERMISSION_PAK_E_WALLETS_VIEW = 'PERMISSION_PAK-E-WALLETS_VIEW',
        PERMISSION_RECLAMATION_VIEW = 'PERMISSION_RECLAMATION_VIEW',
        PERMISSION_RECLAMATION_EDIT = 'PERMISSION_RECLAMATION_EDIT',
        PERMISSION_RECLAMATION_MANAGE = 'PERMISSION_RECLAMATION_MANAGE',
        PERMISSION_REEVALUATE_RECLAMATION = 'PERMISSION_REEVALUATE_RECLAMATION',
        PERMISSION_SHIFT_REPORTS_VIEW = 'PERMISSION_SHIFT-REPORTS_VIEW',
        PERMISSION_PROTECTED_CUSTOMERS_VIEW = 'PERMISSION_PROTECTED-CUSTOMERS_VIEW',
        PERMISSION_PRODUCT_SELL = 'PERMISSION_PRODUCT_SELL',
        PERMISSION_FEE_CHARGE = 'PERMISSION_FEE_CHARGE',
        PERMISSION_PRODUCT_CANCEL = 'PERMISSION_PRODUCT_CANCEL',
        PERMISSION_PAYMENT_ACCEPT_CASH = 'PERMISSION_PAYMENT_ACCEPT-CASH',
        PERMISSION_PAYMENT_ACCEPT_CARD = 'PERMISSION_PAYMENT_ACCEPT-CARD',
        PERMISSION_PAYMENT_ACCEPT_INVOICE = 'PERMISSION_PAYMENT_ACCEPT-INVOICE',
        PERMISSION_CUSTOMER_OR_CUSTODIAN_EDIT = 'PERMISSION_CUSTOMER-OR-CUSTODIAN_EDIT',
        PERMISSION_TICKET_FARES_CANCEL = 'PERMISSION_TICKET-FARES_CANCEL',
        PERMISSION_CUSTOMER_ACCOUNT_EDIT = 'PERMISSION_CUSTOMER-ACCOUNT_EDIT',
        PERMISSION_DENY_CARDS_VIEW = 'PERMISSION_DENY-CARDS_VIEW',
        PERMISSION_DENY_CARDS_EDIT = 'PERMISSION_DENY-CARDS_EDIT',
    }

    export enum reclamationStates {
        PENDING = 'PENDING',
        REJECTED = 'REJECTED',
        APPROVED_COUNTER_PAY_OFF = 'APPROVED_COUNTER_PAY_OFF',
        APPROVED_CARD_UPLOAD = 'APPROVED_CARD_UPLOAD',
        FINALIZED = 'FINALIZED',
    }

    export enum reclamationCardTypes {
        MAP_CARD = 'MAP_CARD',
        PAK_CARD = 'PAK_CARD',
        CREDIT_CARD = 'CREDIT_CARD',
        VIRTUAL_CARD = 'VIRTUAL_CARD',
    }

    export enum reclamationEvaluationTypes {
        APPROVED = 'APPROVED',
        REJECTED = 'REJECTED',
        PENDING = 'PENDING',
    }

    export enum reclamationFinalizationTypes {
        COUNTER_PAY_OFF = 'COUNTER_PAY_OFF',
        INVOICE_PAY_OFF = 'INVOICE_PAY_OFF',
        CARD_UPLOAD = 'CARD_UPLOAD',
        OTHER = 'OTHER',
    }

    export enum reclamationSubjectTypes {
        TICKET_MACHINE = 'TICKET_MACHINE',
        CHECK_IN_CHECK_OUT = 'CHECK_IN_CHECK_OUT',
        ONLINE_RECHARGE = 'ONLINE_RECHARGE',
        PARKING_METER = 'PARKING_METER',
        CARD = 'CARD',
        MAP_PHONE = 'MAP_PHONE',
        OTHER = 'OTHER',
    }

    export enum refundTypes {
        REFUND_NO_REASON = 'REFUND_NO_REASON',
        REFUND_HOSPITAL = 'REFUND_HOSPITAL',
        REFUND_DEATH = 'REFUND_DEATH',
    }

    export enum stockActions {
        ACCEPT = 'ACCEPT',
        MOVE = 'MOVE',
        EXTERNAL_SELL = 'EXTERNAL_SELL',
        COUNTER_SELL = 'COUNTER_SELL',
        COUNTER_CANCEL = 'COUNTER_CANCEL',
        LIQUIDATION = 'LIQUIDATION',
    }

    export enum stockTypes {
        CENTRAL = 'CENTRAL',
        DISTRIBUTION = 'DISTRIBUTION',
        PERSONAL = 'PERSONAL',
    }

    export enum tariffProfilePeriods {
        DAY = 'DAY',
        MONTH = 'MONTH',
        YEAR = 'YEAR',
        HALF_YEAR = 'HALF_YEAR',
        MINUTE = 'MINUTE',
    }

    export enum tariffProfileTypes {
        SLIDING = 'SLIDING',
        CALENDAR = 'CALENDAR',
        FIXED = 'FIXED',
        SINGLE = 'SINGLE',
    }

    export enum ticketStates {
        CANCELLED = 'CANCELLED',
        EXPIRED = 'EXPIRED',
        ACTIVE = 'ACTIVE',
        FUTURE = 'FUTURE',
    }

    export enum validityFilter {
        PAST = 'PAST',
        PRESENT = 'PRESENT',
        FUTURE = 'FUTURE',
    }

    export enum vehicleTransactionTypes {
        PAPER_TICKET = 'PAPER_TICKET',
        ELECTRONIC_TICKET = 'ELECTRONIC_TICKET',
        CHECK_IN = 'CHECK_IN',
        CHECK_OUT = 'CHECK_OUT',
        CANCELLED_TICKET = 'CANCELLED_TICKET',
        GREEN_LIST_WALLET = 'GREEN_LIST_WALLET',
        GREEN_LIST_TICKET = 'GREEN_LIST_TICKET',
        WALLET_CHANGE = 'WALLET_CHANGE',
        CARD_BLOCKING = 'CARD_BLOCKING',
    }

    export enum zones {
        ZONE_I = 'ZONE-I',
        ZONE_I_II = 'ZONE-I+II',
        ZONE_II = 'ZONE-II',
    }


}
