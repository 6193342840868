/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CancelTicketCommand = {
    returnedAmount: number;
    /**
     * Reason of refund.
     */
    refundReason?: CancelTicketCommand.refundReason;
    /**
     * Count days of hospitalisation.
     */
    hospitalDays?: number;
    /**
     * Date of death.
     */
    deathDate?: string;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
};

export namespace CancelTicketCommand {

    /**
     * Reason of refund.
     */
    export enum refundReason {
        REFUND_NO_REASON = 'REFUND_NO_REASON',
        REFUND_HOSPITAL = 'REFUND_HOSPITAL',
        REFUND_DEATH = 'REFUND_DEATH',
    }


}
