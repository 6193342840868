export default class Errors {
  public static INVALID_CREDENTIALS = 'INVALID_CREDENTIALS';
  public static VALIDATION_ERROR = 'VALIDATION_ERROR';
  public static EMPTY_PASSWORD = 'password should not be empty';
  public static EMPTY_USERNAME = 'username should not be empty';
  public static CUSTOMER_EMAIL_NOT_UNIQUE = 'CUSTOMER_EMAIL_NOT_UNIQUE';
  public static INVALID_EMAIL = 'INVALID_EMAIL';
  public static CUSTOMER_ACCOUNT_NOT_VERIFIED = 'CUSTOMER_ACCOUNT_NOT_VERIFIED';
  public static INVALID_VERIFICATION_TOKEN = 'INVALID_VERIFICATION_TOKEN';
  public static CUSTOMER_NOT_UNIQUE = 'CUSTOMER_NOT_UNIQUE';
}

export const NUMBERED_ERRORS: { [key: string]: number } = {
  CUSTOMER_NOT_UNIQUE_CUSTOMER: 1,
  CUSTOMER_NOT_UNIQUE_CUSTODIAN: 2,
  INVALID_CUSTOMER_CARD_AND_CARD_APPLICATION: 3,
  CUSTOMER_ALREADY_HAS_REGISTERED_ACCOUNT: 4,
  CUSTOMER_ALREADY_HAS_ASSIGNED_ACCOUNT: 5,
  CUSTOMER_PARENT_AND_ACCOUNT_OWNER_ARE_DIFFERENT: 6,
  CUSTOMER_TOO_YOUNG_TO_CREATE_REGISTERED_ACCOUNT: 7,
  CUSTOMER_TOO_OLD_TO_CREATE_ASSIGNED_ACCOUNT: 8,
  CANNOT_ASSIGN_CUSTOMER_WITHOUT_PARENT_TO_ACCOUNT: 9,
  INVALID_CUSTOMER_CARD: 10,
  INVALID_ACCOUNT: 11,
  CANNOT_MANAGE_OWN_CARD: 12,
  APPROVE_MANAGED_CARD_INVALID_TOKEN: 13,
  APPROVE_MANAGED_CARD_EXPIRED_TOKEN: 14,
  APPROVE_MANAGED_CARD_MAX_ACCOUNTS_REACHED: 15,
  REJECT_MANAGED_CARD_INVALID_TOKEN: 16,
  CUSTOMER_CARD_ALREADY_MANAGED_BY_CURRENT_ACCOUNT: 17,
  INVALID_CARD_APPLICATION_USERNAME: 18,
  INVALID_CARD: 19,
};
