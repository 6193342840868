<template>
  <div class="text-center">
    <img :src="homepageLogoPath" :height="$vuetify.display.mobile ? 60 : 120" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { HOMEPAGE_LOGO_PATH } from '@/constants/Logo';

@Component
export default class RDPHomePageLogo extends Vue {
  get homepageLogoPath() {
    return HOMEPAGE_LOGO_PATH;
  }
}
</script>
