/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EetResponseDto } from './EetResponseDto';
import type { EnumDto } from './EnumDto';
import type { PersonDto } from './PersonDto';

export type CounterOrderDto = {
    /**
     * Order identifier. Type: 'UUID'
     */
    id: string;
    /**
     * Order number. Is unique.
     */
    orderNumber: number;
    /**
     * DateTime when order was processed. Issued, paid or cancelled. Type: 'DATETIME'
     */
    processed: string;
    /**
     * Shift number. Format: 'YYMMDDCCCCCUUUUSS'
 * + **YY**: year
 * + **MM**: month
 * + **DD**: day
 * + **CCCCC**: counter number - has 5 digits
 * + **UUUU**: user number - has 4 digits
 * + **SS**: sequence number for the given combination of user and counter - 2 digits
     */
    shiftNumber?: number;
    /**
     * Price paid.
     */
    price: number;
    /**
     * Payment state
     */
    state: CounterOrderDto.state;
    /**
     * Payment type. Cash, card, e-wallet, invoice.
     */
    paymentType: EnumDto;
    /**
     * User who confirmed the payment or canceled the order.
     */
    operator?: PersonDto;
    /**
     * Eet details.
     */
    eet?: EetResponseDto;
};

export namespace CounterOrderDto {

    /**
     * Payment state
     */
    export enum state {
        PENDING = 'PENDING',
        PAID = 'PAID',
        CANCELLED = 'CANCELLED',
        REJECTED = 'REJECTED',
        RETURNED = 'RETURNED',
        PROCESSING = 'PROCESSING',
    }


}
