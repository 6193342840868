<template>
  <div v-if="show" :title="tooltip" class="d-flex align-end flex-wrap flex-column">
    <div :class="customEffect">
      <v-alert
        v-if="disabled && $vuetify.display.mobile"
        border="top"
        type="warning"
        variant="text"
        elevation="2"
        width="292px"
        class="pt-3 pb-1 mb-2 text-left"
      >
        {{ tooltip }}
      </v-alert>
    </div>
    <v-btn
      class="mb-4 rdp-button"
      color="buttonPrimary"
      :disabled="disabled"
      :data-cy="dataCy"
      :theme="!disabled ? 'dark' : ''"
      @click="confirmCallback"
    >
      <template #prepend>
        <v-icon size="x-large" icon="$newCardApplicationIcon" />
      </template>
      <span class="rdp-button-text">
        {{ title }}
      </span>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component
export default class CreateCardApplicationButton extends Vue {
  @Prop({
    required: false,
    type: Function,
    default: () => {
      console.error('Not implemented');
    },
  })
  readonly confirm!: () => void;

  @Prop({ required: true, type: String, default: '' })
  readonly title!: string;

  @Prop({ required: false, type: String, default: '' })
  readonly dataCy!: string;

  @Prop({ required: false, type: String, default: '' })
  readonly tooltip!: string;

  @Prop({ required: false, type: Boolean, default: true })
  readonly show!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly disabled!: boolean;

  customEffect = '';

  confirmCallback() {
    this.confirm();
  }

  async created() {
    // hide alert after 20s
    setTimeout(() => {
      this.customEffect = 'hide-alert';
    }, 20000);
  }
}
</script>
<style lang="scss" scoped>
.hide-alert {
  display: none;
}
</style>
