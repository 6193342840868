import { HAULIER_NAME } from '@/config/config';
import { HAULIERS } from '@/constants/Constants';

function getLogoAlt() {
  let logoAlt = 'Default logo';
  switch (HAULIER_NAME) {
    case HAULIERS.DPMP: {
      logoAlt = 'DPMP logo';
      break;
    }
    case HAULIERS.NITRA: {
      logoAlt = 'Nitra logo';
      break;
    }
    case HAULIERS.TRENCIN: {
      logoAlt = 'Trečín logo';
      break;
    }
    case HAULIERS.RUZOMBEROK: {
      logoAlt = 'Ružomberok logo';
      break;
    }
    case HAULIERS.DPB: {
      logoAlt = 'DPB logo';
      break;
    }
    case HAULIERS.POLAND: {
      logoAlt = 'Poland logo';
      break;
    }
  }
  return logoAlt;
}

export const LOGO_ALT = getLogoAlt();
export const LOGO_PATH = new URL(`../assets/${HAULIER_NAME}/logo.svg`, import.meta.url).href;

export const HOMEPAGE_LOGO_PATH = new URL(`../assets/${HAULIER_NAME}/logo_homepage.svg`, import.meta.url).href;
