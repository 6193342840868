/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateEetProductionVerificationRequestCommand = {
    /**
     * id_provoz: BranchOffice.branchNumber.
     */
    branchNumber?: number;
    /**
     * id_pokl and porad_cis: BranchOffice.eetCounterPrefix.
     */
    eetCounterPrefix?: string;
    /**
     * id_pokl: Counter.counterNumber.
     */
    counterNumber?: number;
    /**
     * porad_cis: Order.number.
     */
    orderNumber?: number;
    /**
     * celk_trzba: Order.price.
     */
    price?: number;
    /**
     * dat_trzby: Order.paidUtc.
     */
    paidUtc?: string;
    /**
     * dat_trzby: Order.paidOffset.
     */
    paidOffset?: number;
    /**
     * Rezim: Usually use REGIME.NORMAL.
     */
    regime?: CreateEetProductionVerificationRequestCommand.regime;
    /**
     * uuid_zpravy: Use EetService.generateUuid() or send uuid from db if firstTry is false.
     */
    uuid?: string;
    /**
     * Use this just for testing of a wrong password. If left undefined, the correct password will be set.
     */
    wrongPassword?: string;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
};

export namespace CreateEetProductionVerificationRequestCommand {

    /**
     * Rezim: Usually use REGIME.NORMAL.
     */
    export enum regime {
        NORMAL = 'NORMAL',
        SIMPLIFIED = 'SIMPLIFIED',
        '_0' = 0,
        '_1' = 1,
    }


}
