/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { CreateCustomerProfileCommand } from '../models/CreateCustomerProfileCommand';
import type { CustomerProfileListResponse } from '../models/CustomerProfileListResponse';
import type { CustomerProfileResponse } from '../models/CustomerProfileResponse';
import type { UpdateCustomerProfileNameCommand } from '../models/UpdateCustomerProfileNameCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerProfileService {

    /**
     * Get customer profiles.
     * @returns CustomerProfileListResponse 
     * @throws ApiError
     */
    public static customerProfileControllerGetAll({
profileNumber,
age,
type,
onlyPrimary,
validity,
includeDeletable,
timezoneOffset,
limit,
offset,
sortBy,
sortDesc,
sortLocale,
date,
}: {
profileNumber?: number,
age?: number,
type?: 'IMPERSONAL' | 'PERSONAL',
onlyPrimary?: boolean,
validity?: 'PAST' | 'PRESENT' | 'FUTURE',
includeDeletable?: boolean,
timezoneOffset?: number,
limit?: number,
offset?: number,
sortBy?: string,
sortDesc?: boolean,
sortLocale?: string,
date?: string,
}): CancelablePromise<CustomerProfileListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/customer-profiles',
            query: {
                'profileNumber': profileNumber,
                'age': age,
                'type': type,
                'onlyPrimary': onlyPrimary,
                'validity': validity,
                'includeDeletable': includeDeletable,
                'timezoneOffset': timezoneOffset,
                'limit': limit,
                'offset': offset,
                'sortBy': sortBy,
                'sortDesc': sortDesc,
                'sortLocale': sortLocale,
                'date': date,
            },
            errors: {
                401: `User wasn't authenticated.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Create customer profile.
     * Require: `PERMISSION_ADMINISTRATION_VIEW`<br>
 * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
 * + **INVALID_VALID_TO** - ValidFrom is after ValidTo OR New ValidFrom is null or new validFrom is before old ValidFrom.<br>
 * + **INVALID_CUSTOMER_PROFILE_TYPE** - Customer profile type does not exist or for the given ticket combination does not match with card.<br>
 * + **INVALID_CUSTOMER_PROFILE_VALID_TO_DEFAULT** - Customer profile validTo default does not exist.<br>
 * + **INVALID_CUSTOMER_PROFILE** - Customer profile does not exist, is not valid for the customer, is impersonal, is not primary or has invalid properties combination.
     * @returns CustomerProfileResponse 
     * @throws ApiError
     */
    public static customerProfileControllerCreate({
requestBody,
}: {
/**
 * CreateCustomerProfileCommand
 */
requestBody?: CreateCustomerProfileCommand,
}): CancelablePromise<CustomerProfileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/customer-profiles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get customer profile by id.
     * @returns CustomerProfileResponse 
     * @throws ApiError
     */
    public static customerProfileControllerGetOne({
id,
}: {
id: string,
}): CancelablePromise<CustomerProfileResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/customer-profiles/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Update customer profile names.
     * Require: `PERMISSION_ADMINISTRATION_VIEW`<br>
 * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
 * + **INVALID_VALID_TO** - Valid to must be bigger than valid from or is in the past.
     * @returns CustomerProfileResponse 
     * @throws ApiError
     */
    public static customerProfileControllerUpdate({
id,
requestBody,
}: {
id: string,
/**
 * UpdateCustomerProfileNameCommand
 */
requestBody?: UpdateCustomerProfileNameCommand,
}): CancelablePromise<CustomerProfileResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/public/customer-profiles/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Delete customer profile.
     * If an entity is being used, 409 Conflict will be returned.<br>
 * Require: `PERMISSION_ADMINISTRATION_VIEW`
     * @returns ApiResponse 
     * @throws ApiError
     */
    public static customerProfileControllerDelete({
id,
}: {
id: string,
}): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/public/customer-profiles/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                409: `Request couldn't be completed due to a conflict.`,
                500: `Something went wrong.`,
            },
        });
    }

}
