import moment, { DurationInputArg2, Moment } from 'moment';
import isDefined from '@common/utils/isDefined';

export const DATE_PICKER_FORMAT = 'DD.MM.YYYY';
export const DATE_BIRTHDAY_FORMAT = 'D.M.YYYY';
export const MONTH_PICKER_FORMAT = 'MM/YYYY';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FIRST_DAY_FORMAT = 'YYYY-MM-01';
export const MONTH_FORMAT = 'YYYY-MM';
export const YEAR_FORMAT = 'YYYY';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const MIN_DAY_OF_BIRTHDAY = '1900-01-01';

/**
 * Format date with timezone offset.
 * E.g.: 2013-03-01T00:00:00+01:00
 * @param date
 */
export const toDateTimeOffset = (date: Date | string | Moment): string => {
  return moment(date).format();
};

/**
 * Format date without time part.
 * @param date
 * @param format
 */
export const toDate = (date: Date | string | Moment, format?: string): string => {
  if (format) {
    return moment(date, format).format(DATE_FORMAT);
  }
  return moment(date).format(DATE_FORMAT);
};

/**
 * Format date to the first day of month.
 * @param date
 */
export const toMonth = (date: Date | string): string => {
  return moment(date).format(DATE_FIRST_DAY_FORMAT);
};

/**
 * Get client timezone offset in minutes.
 * E.g.: if +02:00 then returns 120 minutes.
 * @param date
 */
export const getTimezoneOffset = (date: Date | string): number => {
  // Need to be reversed, +02:00 returns diff from UTC so -120.
  return -new Date(date).getTimezoneOffset();
};
/**
 * Get end of month
 *
 * @param date
 */
export const toEndOfMonth = (date: Date | string): string => {
  return moment(toMonth(date)).add(1, 'month').subtract(1, 'day').format(DATE_FORMAT);
};

/**
 * Checks validFrom and validTo and returns false if validFrom is after validTo, otherwise returns true
 *
 * @param validFrom
 * @param validTo
 */
export const checkValidityDates = (validFrom: Date | string, validTo: Date | string): boolean => {
  return !(validFrom && validTo && moment(validFrom).isAfter(moment(validTo)));
};

/**
 * Format date for date picker, etc.
 * E.g.: 31.03.2020
 * @param date
 */
export const formatDate = (date: string | Date | Moment): string => {
  return moment(date).format(DATE_PICKER_FORMAT);
};

/**
 * Format date time.
 * E.g.: 31.03.2020 13:45
 * @param date
 */
export const formatDateTime = (date: string | Date | Moment): string => {
  return moment(date).format(DATE_TIME_FORMAT);
};

/**
 * Returns number of days between two dates including the start day
 *
 * @param {Date} start
 * @param {Date} end
 * @param options
 * @returns {number}
 */
export const getDaysDiffBetweenDates = (start: Date | string, end: Date | string, options = { includeStart: true }) => {
  return moment(end).diff(moment(start), 'days') + (options.includeStart ? 1 : 0);
};

/**
 * Filter active or future records.
 * @param validTo
 */
export const filterActiveOrFuture = (validTo: string | Date) => {
  return moment(validTo).isSameOrAfter(new Date(), 'day');
};

export const isDateValid = (day: number | string | undefined, month: number | string | undefined) => {
  const DEFAULT_YEAR = 2000;
  return moment(`${day}.${month}.${DEFAULT_YEAR}`, 'DD.MM.YYYY').isValid();
};

/**
 * Check if dateOne is invalid.
 * @param dateOne
 * @param dateTwo
 */
export const isDateOneAfterDateTwo = (dateOne?: string | Date, dateTwo?: string | Date): boolean => {
  if (!dateOne || !dateTwo) {
    return false;
  }

  return moment(dateOne).isAfter(dateTwo, 'day');
};

/**
 * Check if dateOne is same or after dateTwo.
 * @param dateOne
 * @param dateTwo
 */
export const isDateOneSameOrAfterDateTwo = (dateOne?: string | Date, dateTwo?: string | Date): boolean => {
  if (!dateOne || !dateTwo) {
    return false;
  }

  return moment(dateOne).isSameOrAfter(dateTwo, 'day');
};

/**
 * Check if dateOne is same or before dateTwo.
 * @param dateOne
 * @param dateTwo
 */
export const isDateOneSameOrBeforeDateTwo = (dateOne?: string | Date, dateTwo?: string | Date): boolean => {
  if (!dateOne || !dateTwo) {
    return false;
  }

  return moment(dateOne).isSameOrBefore(dateTwo, 'day');
};

/**
 * Check if date is between given date range.
 * @param date
 * @param validFrom
 * @param validTo
 */
export const isBetween = (date: string | Date, validFrom?: string | Date, validTo?: string | Date): boolean => {
  if (isDefined(validFrom as string) && isDefined(validTo as string)) {
    return moment(date).isBetween(validFrom, validTo, 'day', '[]');
  }

  if (!isDefined(validFrom as string)) {
    return moment(date).isSameOrBefore(validTo, 'day');
  }

  if (!isDefined(validTo as string)) {
    return moment(date).isSameOrAfter(validFrom, 'day');
  }

  return true;
};

/**
 * Get tomorrow.
 */
export const getTomorrow = (): Date => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

/**
 * @param date
 * @param amount
 * @param unit
 */
export const getDateAfter = (date: Date | string, amount: number, unit: DurationInputArg2): string => {
  return moment(date).add(amount, unit).format(DATE_FORMAT);
};

/**
 * @param date
 * @param amount
 * @param unit
 */
export const getDateBefore = (date: Date, amount: number, unit: DurationInputArg2): string => {
  return moment(date).add(-amount, unit).format(DATE_FORMAT);
};

/**
 * Returns earlier date of the two dates
 * @param dateOne
 * @param dateTwo
 */
export const getEarlierDate = (dateOne: string, dateTwo: string): string | Date => {
  if (!isDefined(dateOne)) {
    return dateTwo;
  }

  if (!isDefined(dateTwo)) {
    return dateOne;
  }
  return isDateOneAfterDateTwo(dateOne, dateTwo) ? dateTwo : dateOne;
};

/**
 * Returns later date of the two dates
 * @param dateOne
 * @param dateTwo
 */
export const getLaterDate = (dateOne: string, dateTwo: string): string | Date => {
  if (!isDefined(dateOne)) {
    return dateTwo;
  }

  if (!isDefined(dateTwo)) {
    return dateOne;
  }

  return isDateOneAfterDateTwo(dateOne, dateTwo) ? dateOne : dateTwo;
};
