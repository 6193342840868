<template>
  <v-card width="100%" class="fill-height pa-4 overflow-visible">
    <RDPDataTable
      ref="dataTable"
      :headers="headers"
      :filter="filter"
      :get-row-class-callback="getRowClassCallback"
      :search-items-callback="searchTicketFare"
      i18n-group-key="userAccountPage.ticketFareTab"
      data-cy="user-account-ticket-fare-list"
    >
      <template #head-panel.start>
        <v-col cols="12" md="3" :style="filterStyle">
          <v-select
            v-model="filter.stateIds"
            :items="ticketFareStateItems"
            chips
            :label="$t(`userAccountPage.ticketFareTab.ticketFareFilter`)"
            data-cy="user-account-ticket-fare-filter"
            multiple
            clearable
            variant="underlined"
            @update:model-value="searchTicketFare"
          >
            <template #selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.title }}</span>
                <span v-if="filter.stateIds.length > 1" class="text-grey text-caption ml-1">{{
                  $t('multiselectOthers', {
                    selectionLength: filter.stateIds.length - 1,
                  })
                }}</span>
              </v-chip>
            </template>
          </v-select>
        </v-col>
      </template>
      <template #item.customerProfile.displayName="{ item }">
        {{ getCustomerProfileDisplayName(item.customerProfile) }}
      </template>
      <template #item.tariffProfile.displayName="{ item }">
        {{ getTariffProfileDisplayName(item.tariffProfile) }}
      </template>
    </RDPDataTable>
  </v-card>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-facing-decorator';
import { DATA_TABLE_HEADER_TYPES } from '@/components/common/RDPDataTable/DataTableHelper';
import customerService from '@/services/api/customerService';
import RDPDataTable from '@/components/common/RDPDataTable/RDPDataTable.vue';
import {
  CustomerTicketFareListResponse,
  CustomerProfileInfoVm,
  TariffProfileInfoVm,
  TicketFareStateEnum,
  TicketStateListQuery,
} from '@/api';
import ticketFareStateService from '@/services/api/ticketFareStateService';
import CustomerModule from '@/store/modules/customer';
import { RDPDataTableInterface } from '@/components/common/RDPDataTable/RDPDataTableInterfaces';
import { getCustomerProfileDisplayName, getTariffProfileDisplayName } from '@/utils/i18n';

const DEFAULT_SORT_BY_COLUMN = 'validTo';
const DEFAULT_SORT_DESC = true;

@Component({
  components: {
    RDPDataTable,
  },
})
export default class TickerFareTab extends Vue {
  @Ref()
  readonly dataTable!: RDPDataTableInterface;

  customerModule = CustomerModule;

  filter = {
    stateIds: [],
    sortBy: DEFAULT_SORT_BY_COLUMN,
    sortDesc: DEFAULT_SORT_DESC,
  };
  headers = [
    {
      text: 'state.displayName',
      applyRowClass: true,
      sortable: false,
    },
    {
      text: 'validFrom',
      type: DATA_TABLE_HEADER_TYPES.DATE,
    },
    {
      text: 'validTo',
      type: DATA_TABLE_HEADER_TYPES.DATE,
    },
    {
      text: 'customerProfile.displayName',
    },
    'zone.displayName',
    {
      text: 'tariffProfile.displayName',
    },
    {
      text: 'price',
      type: DATA_TABLE_HEADER_TYPES.PRICE,
    },
    { text: 'card.cardNumber', type: DATA_TABLE_HEADER_TYPES.CARD_NUMBER },
    'card.state.displayName',
  ];
  ticketFareStateList: TicketFareStateEnum[] = [];

  async created() {
    this.ticketFareStateList = await ticketFareStateService.getTicketFareStateList();
  }

  get ticketFareStateItems() {
    return this.ticketFareStateList.map((ticketFareState: TicketFareStateEnum) => ({
      title: ticketFareState.displayName,
      value: ticketFareState.name,
    }));
  }

  get filterStyle() {
    return this.$vuetify.display.mobile ? '' : 'margin-top: -95px; margin-right: -15px;';
  }

  public async searchTicketFare(query: TicketStateListQuery): Promise<CustomerTicketFareListResponse> {
    return customerService.getCustomerTicketFareList(this.customerModule.currentlySelectedCustomerId, query);
  }

  getRowClassCallback(item: { state: { code: string } }) {
    return `ticket-fare-${item.state.code}`;
  }

  /**
   * This is called from UserAccountPage.vue
   */
  refresh() {
    this.dataTable.refresh();
  }

  getCustomerProfileDisplayName(profile: CustomerProfileInfoVm | undefined): string {
    return getCustomerProfileDisplayName(profile?.id);
  }

  getTariffProfileDisplayName(profile: TariffProfileInfoVm | undefined): string {
    return getTariffProfileDisplayName(profile?.id);
  }
}
</script>

<style>
.ticket-fare-EXPIRED {
  background-color: #b7e2f1 !important;
}

.ticket-fare-FUTURE {
  background-color: #fff9c4 !important;
}

.ticket-fare-CANCELLED {
  background-color: #ffcdd2 !important;
}

.ticket-fare-ACTIVE {
  background-color: #c7ffc9 !important;
}
</style>
