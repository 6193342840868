<template>
  <v-dialog
    v-model="show"
    max-width="1000"
    :max-height="$vuetify.display.mobile ? '100%' : '85%'"
    :fullscreen="$vuetify.display.mobile"
    persistent
    scrollable
    data-cy="card-application-dialog"
  >
    <v-card data-cy="card-application-form-dialog">
      <v-toolbar
        color="systemPrimary"
        theme="dark"
        flat
        :height="$vuetify.display.mobile ? '100%' : '64px'"
        :class="$vuetify.display.mobile ? 'pt-1' : ''"
        class="dialog-toolbar"
      >
        <v-card-title class="headlineWrapper">
          <div class="text-h5 dialog-title d-flex">
            <v-icon start size="large" color="white">
              {{ dialogTypes[dialogType].icon }}
            </v-icon>
            <div class="align-self-center">
              {{ dialogTitle }}
            </div>
          </div>
        </v-card-title>
      </v-toolbar>

      <v-card-text class="pa-0">
        <!-- eslint-disable-next-line vuetify/no-deprecated-components -->
        <v-stepper v-model="activeStep" :mobile="$vuetify.display.mobile">
          <v-stepper-header>
            <template v-for="(step, index) of steps" :key="`header-${index}`">
              <v-stepper-item
                :complete="activeStep > index + 1"
                :value="index + 1"
                :title="step.title"
                color="systemSecondary"
              />
              <v-divider v-if="index !== steps.length - 1" :key="`divider-${index}`" />
            </template>
          </v-stepper-header>

          <v-stepper-window :touch="false">
            <v-stepper-window-item v-for="(step, index) of steps" :key="`step-${step.title}`" :value="index + 1" eager>
              <component
                :is="step.form"
                ref="dialogSteps"
                :disabled="step.formDisabled"
                :dialog-type="dialogType"
                :is-virtual-card="isVirtualCard"
                :is-bank-card="isBankCard"
                :step-active="activeStep === index + 1"
                :check-if-user-is-unique="checkIfUserIsUnique"
              />
            </v-stepper-window-item>

            <RDPSpinner :show="showAttachmentLoading" i18n-key="cardApplication.attachmentUploading" />
            <RDPSpinner
              :show="showTokenizationRedirectLoading"
              i18n-key="cardApplication.tokenizationRedirectLoading"
            />
          </v-stepper-window>
        </v-stepper>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer v-if="!$vuetify.display.mobile" />
        <div :class="buttonsClass">
          <v-btn
            v-if="activeStep === 1 || (activeStep === steps.length && dialogType === dialogTypesEnum.Detail)"
            color="buttonWarning"
            theme="dark"
            data-cy="card-application-dialog-close-dialog-button"
            :class="$vuetify.display.mobile ? 'mb-2' : ''"
            variant="elevated"
            @click="closeDialog()"
          >
            <template #prepend>
              <v-icon> mdi-cancel</v-icon>
            </template>
            {{ $t('buttons.close') }}
          </v-btn>

          <v-btn
            v-if="activeStep > 1"
            class="pl-2"
            color="buttonPrimary"
            data-cy="card-application-dialog-back-step-button"
            theme="dark"
            :class="$vuetify.display.mobile ? 'mb-2 ml-0' : ''"
            variant="elevated"
            @click="previousStep()"
          >
            <template #prepend>
              <v-icon> mdi-chevron-left</v-icon>
            </template>
            {{ $t('buttons.back') }}
          </v-btn>

          <v-btn
            v-if="activeStep === 1 || (activeStep > 1 && activeStep < steps.length)"
            class="pr-2"
            color="buttonPrimary"
            data-cy="card-application-dialog-next-step-button"
            :theme="!nextStepButtonsDisabled ? 'dark' : ''"
            :disabled="nextStepButtonsDisabled"
            :class="$vuetify.display.mobile ? 'mb-2 ml-0' : ''"
            variant="elevated"
            @click="nextStep()"
          >
            {{ $t('buttons.next') }}
            <template #append>
              <v-icon> mdi-chevron-right</v-icon>
            </template>
          </v-btn>
          <confirm-button
            v-if="(isVirtualCard || isBankCard) && showConfirmButton"
            :text="$t('buttons.ok')"
            :confirm="save"
            button-color="buttonConfirm"
            icon="mdi-check"
            dark
            :class="$vuetify.display.mobile ? 'mb-2' : ''"
            data-cy="card-application-dialog-virtualApp-confirm-btn"
          />
          <confirm-button
            v-if="!isVirtualCard && !isBankCard && showConfirmButton"
            :text="confirmAndContinueText"
            :confirm="save"
            button-color="buttonConfirm"
            icon="mdi-check"
            dark
            :class="$vuetify.display.mobile ? 'mb-2' : ''"
            data-cy="card-application-dialog-saveContinue-button"
          />
          <confirm-button
            v-if="!isVirtualCard && !isBankCard && showConfirmButton && showRedirectConfirmButton"
            :text="saveAndRedirectText"
            :confirm="saveAndRedirect"
            button-color="buttonConfirm"
            icon="mdi-check"
            dark
            :class="$vuetify.display.mobile ? 'mb-2' : ''"
            data-cy="card-application-dialog-saveRedirect-button"
          />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue } from 'vue-facing-decorator';
import PersonalDataForm from './dialogSteps/PersonalDataForm.vue';
import CardDataForm from './dialogSteps/CardDataForm.vue';
import SummaryForm from './dialogSteps/SummaryForm.vue';
import CustodianForm from '@/components/cardApplication/dialogSteps/CustodianForm.vue';
import { RDPDialogInterface } from '@/components/common/RDPDialogInterface';
import CardApplicationModule, { Attachment, REGISTER_NEW_BANK_CARD } from '@/store/modules/cardApplication';
import AuthModule from '@/store/modules/auth';
import CustomerModule from '@/store/modules/customer';
import { RDPCropperInterface } from '@/components/common/RDPCropper/RDPCropperInterface';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import { CARD_APPLICATION_DIALOG_TYPE, CardApplicationDialogType } from './cardApplicationDialogType';
import customerService from '@/services/api/customerService';
import { CardApplicationDto, CardApplicationHeaderVm, CustomerDetailVm } from '@/api';
import cardApplicationService from '@/services/api/cardApplicationService';
import moment from 'moment';
import { DATE_BIRTHDAY_FORMAT, DATE_PICKER_FORMAT, toDate } from '@/utils/dateTime';
import Errors from '@/constants/Errors';
import ShoppingCartModule from '@/store/modules/shoppingCart';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import Paths from '@/constants/Paths';
import { CustomerUniqueCheckType } from '@/components/cardApplication/customerUniqueCheckType';
import RDPSpinner from '@/components/common/RDPSpinner.vue';
import { MIN_CUSTOMER_AGE } from '@/config/config';
import bankCardService from '@/services/api/bankCardService';
import { getUrl } from '@/utils/tokenizationRequest';
import { getNumberedApiError } from '@/utils/toast.ts';
import ToastModule from '@/store/modules/toast.ts';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';
import { markRaw } from 'vue';

export interface CardApplicationStepperDialogInterface {
  openDialog: (id?: string) => void;
  closeDialog: () => void;
  openClearedDialog: (id?: string) => void;
  openClearedBankCardDialog: (id?: string) => void;
  openDialogForVirtualCard: (id?: string) => void;
  openDialogForBankCard: (id?: string) => void;
  openDialogForPersonalCard: (id?: string) => void;
}

interface DialogStepComponentInterface extends Vue {
  validate: () => Promise<boolean>;
  createCardApplication: () => Promise<CardApplicationHeaderVm>;
  updateCardApplication: () => Promise<CardApplicationHeaderVm>;
  searchedUser: {
    text: string;
    value: string;
  };
  form: {
    resetValidation: () => boolean;
  };
  photoErrorMessage: string;
  cropper: RDPCropperInterface;
  initExistingBankCardsData?: () => void;
}

interface DialogStepInterface {
  title: string;
  formDisabled: boolean;
  form: typeof PersonalDataForm | typeof CardDataForm | typeof SummaryForm | typeof CustodianForm;
}

@Component({
  components: {
    ConfirmButton,
    RDPSpinner,
  },
})
export default class CardApplicationStepperDialog extends Vue implements RDPDialogInterface {
  @Ref()
  readonly dialogSteps!: DialogStepComponentInterface[];

  @Prop({ required: false, type: Function, default: () => undefined })
  readonly confirmCallback!: () => void;

  dialogType = CardApplicationDialogType.Detail;
  dialogTypes = CARD_APPLICATION_DIALOG_TYPE;
  dialogTypesEnum = CardApplicationDialogType;
  activeStep = 1;
  isVirtualCard = false;
  isBankCard = false;
  cardApplicationModule = CardApplicationModule;
  authModule = AuthModule;
  customerModule = CustomerModule;
  shoppingCartModule = ShoppingCartModule;
  systemConfigurationModule = SystemConfigurationModule;
  nextStepButtonsDisabled = false;
  show = false;
  showAttachmentLoading = false;
  showTokenizationRedirectLoading = false;
  // stepper dialog can be without custodian form/step
  stepperIndex = {
    PERSON_DATA_INDEX: 0,
    CUSTODIAN_DATA_INDEX: 1,
    CARD_DATA_INDEX: 1,
    SUMMARY_DATA_INDEX: 2,
  };

  steps: DialogStepInterface[] = [];

  created() {
    this.steps = [
      {
        title: this.$t('cardApplication.personalData.stepName'),
        form: markRaw(PersonalDataForm),
        formDisabled: false,
      },
      {
        title: this.$t('cardApplication.cardData.stepName'),
        form: markRaw(CardDataForm),
        formDisabled: false,
      },
      {
        title: this.$t('cardApplication.summary.stepName'),
        form: markRaw(SummaryForm),
        formDisabled: false,
      },
    ] as DialogStepInterface[];
  }

  get confirmAndContinueText() {
    const i18nKey = this.$vuetify.display.mobile ? 'ok' : 'confirmAndContinue';
    return this.$t(`buttons.${i18nKey}`);
  }

  get saveAndRedirectText() {
    const i18nKey = this.$vuetify.display.mobile ? 'addToShoppingCart' : 'confirmAndGoToShoppingCart';
    return this.$t(`buttons.${i18nKey}`);
  }

  get dialogTitle() {
    if (this.isVirtualCard) {
      return this.$t(CARD_APPLICATION_DIALOG_TYPE[this.dialogType].titleVirtual);
    } else if (this.isBankCard) {
      return this.$t(
        CARD_APPLICATION_DIALOG_TYPE[this.dialogType].titleBank.translate,
        CARD_APPLICATION_DIALOG_TYPE[this.dialogType].titleBank.param!,
      );
    } else {
      return this.$t(
        CARD_APPLICATION_DIALOG_TYPE[this.dialogType].title.translate,
        CARD_APPLICATION_DIALOG_TYPE[this.dialogType].title.param!,
      );
    }
  }

  get showConfirmButton() {
    return this.activeStep === this.steps.length && !this.steps[this.stepperIndex.SUMMARY_DATA_INDEX].formDisabled;
  }

  get showRedirectConfirmButton() {
    return !this.cardApplicationModule.cardData.cardForFree;
  }

  get buttonsClass() {
    return this.$vuetify.display.mobile ? 'd-flex flex-column ml-auto button-mobile' : '';
  }

  async openDialogForVirtualCard(id?: string) {
    this.isVirtualCard = true;
    this.isBankCard = false;
    this.cardApplicationModule.setCustodianAdded(false);
    await this.openDialog(id);
  }

  async openDialogForBankCard(id?: string) {
    this.isBankCard = true;
    this.isVirtualCard = false;
    this.cardApplicationModule.setCustodianAdded(false);
    await this.openDialog(id);
  }

  async openDialogForPersonalCard(id?: string) {
    this.isBankCard = false;
    this.isVirtualCard = false;
    this.cardApplicationModule.setCustodianAdded(false);
    await this.openDialog(id);
  }

  async openDialog(id?: string) {
    if (id) {
      const cardApplicationData: CardApplicationDto = await cardApplicationService.getCardApplication(id);
      this.cardApplicationModule.setCardData(cardApplicationData);
      if (cardApplicationData.deliveryBranchOffice) {
        this.cardApplicationModule.setBranchOfficeData(cardApplicationData.deliveryBranchOffice);
      }

      if (cardApplicationData.recipientAddress) {
        this.cardApplicationModule.setRecipientAddressData(cardApplicationData.recipientAddress);
      }
      this.cardApplicationModule.setMarketingConsent(cardApplicationData.marketingConsent!);
      this.dialogType = CardApplicationDialogType.Detail;
      await this.initDialogs(cardApplicationData.customer.id);
      this.disableStepperDialog();
    } else {
      this.dialogType = CardApplicationDialogType.Create;
      const lastCardApplication = await cardApplicationService.getLastCardApplication();
      if (lastCardApplication) {
        this.cardApplicationModule.setMarketingConsent(lastCardApplication.marketingConsent!);
      }
      await this.initDialogs(this.customerModule.currentlySelectedCustomerId);
      this.cardApplicationModule.setCustomerPhoto(''); // let the eshop-user set a new photo when creating a new cardApplication
    }
  }

  async openClearedDialog(id?: string) {
    this.isBankCard = false;
    this.isVirtualCard = false;
    this.dialogType = CardApplicationDialogType.CreateAssignedCard;

    this.addCustodianStep();

    if (id) {
      await this.initDialogs(id);
    } else {
      await this.initDialogs();
    }

    this.cardApplicationModule.setCustomerPhoto(''); // let the eshop-user set a new photo when creating a new cardApplication

    await this.setCustodianData();
  }

  async openClearedBankCardDialog(id?: string) {
    this.isBankCard = true;
    this.isVirtualCard = false;
    this.dialogType = CardApplicationDialogType.CreateAssignedCard;

    this.addCustodianStep();

    if (id) {
      await this.initDialogs(id);
    } else {
      await this.initDialogs();
    }

    this.cardApplicationModule.setCustomerPhoto(''); // let the eshop-user set a new photo when creating a new cardApplication

    await this.setCustodianData();
  }

  initSteps() {
    if (this.dialogSteps) {
      if (this.dialogSteps[this.stepperIndex.PERSON_DATA_INDEX]) {
        this.dialogSteps[this.stepperIndex.PERSON_DATA_INDEX].form.resetValidation();
        this.dialogSteps[this.stepperIndex.PERSON_DATA_INDEX].photoErrorMessage = '';
      }

      if (this.dialogSteps[this.stepperIndex.CARD_DATA_INDEX]) {
        this.dialogSteps[this.stepperIndex.CARD_DATA_INDEX].form.resetValidation();

        if (this.isBankCard) {
          this.dialogSteps[this.stepperIndex.CARD_DATA_INDEX].initExistingBankCardsData?.();
        }
      }

      if (this.dialogSteps[this.stepperIndex.CUSTODIAN_DATA_INDEX]) {
        this.dialogSteps[this.stepperIndex.CUSTODIAN_DATA_INDEX].form.resetValidation();
        this.dialogSteps[this.stepperIndex.CUSTODIAN_DATA_INDEX].photoErrorMessage = '';
      }
    }
  }

  addCustodianStep() {
    this.steps = [
      {
        title: this.$t('cardApplication.personalData.stepName'),
        form: markRaw(PersonalDataForm),
        formDisabled: false,
      },
      {
        title: this.$t('cardApplication.custodianData.stepName'),
        form: markRaw(CustodianForm),
        formDisabled: false,
      },
      {
        title: this.$t('cardApplication.cardData.stepName'),
        form: markRaw(CardDataForm),
        formDisabled: false,
      },
      {
        title: this.$t('cardApplication.summary.stepName'),
        form: markRaw(SummaryForm),
        formDisabled: false,
      },
    ];

    this.stepperIndex.CARD_DATA_INDEX = 2;
    this.stepperIndex.SUMMARY_DATA_INDEX = 3;
    this.cardApplicationModule.setCustodianAdded(true);
  }

  async setCustodianData() {
    const registeredOriginId = this.customerModule.registeredCustomer?.id;
    if (registeredOriginId) {
      const parent = await this.getCustomer(registeredOriginId);
      if (parent) {
        this.cardApplicationModule.setCustodianData(parent);
        this.steps[this.stepperIndex.CUSTODIAN_DATA_INDEX].formDisabled = true;
      }
    }
  }

  disableStepperDialog() {
    this.steps[this.stepperIndex.PERSON_DATA_INDEX].formDisabled = true;
    this.steps[this.stepperIndex.CARD_DATA_INDEX].formDisabled = true;
    this.steps[this.stepperIndex.SUMMARY_DATA_INDEX].formDisabled = true;
  }

  async getCustomer(customerId: string) {
    const customer: CustomerDetailVm = await customerService.getCustomer(customerId);
    if (!customer.photo) {
      customer.photo = '';
    }
    if (!customer.email && this.dialogType !== CardApplicationDialogType.Detail) {
      customer.email = this.authModule.username;
    }

    return customer;
  }

  async initDialogs(customerId?: string) {
    this.activeStep = 1;
    this.initSteps();
    this.show = true;

    if (customerId) {
      const customer = await this.getCustomer(customerId);
      this.cardApplicationModule.setCustomerData(customer);
      if (customer.parent) {
        this.cardApplicationModule.setCustodianData(customer.parent);
        this.addCustodianStep();
      }

      this.cardApplicationModule.setVerifiedProfile(customer);
    } else {
      this.cardApplicationModule.initCardApplication();
    }
  }

  async checkIfUserIsUnique(customer: CustomerDetailVm, type: CustomerUniqueCheckType) {
    const { id, originId, firstName, lastName, birthDay } = customer;
    // using DATE_BIRTHDAY_FORMAT allows to write (and then to check) 2.2.1980 and 02.02.1980 (both are valid)
    const isBirthDayValid = moment(birthDay, DATE_BIRTHDAY_FORMAT, true).isValid();
    if (birthDay && isBirthDayValid && firstName && lastName && this.dialogType !== CardApplicationDialogType.Detail) {
      const customerIsUnique = await customerService.checkIfCustomerIsUnique({
        firstName,
        lastName,
        birthDay: toDate(birthDay, DATE_PICKER_FORMAT),
        excludedCustomerIds: [id, originId].filter(id => id),
      });
      if (customerIsUnique.errors.length > 0) {
        this.nextStepButtonsDisabled = true;
        const error = getNumberedApiError(
          { data: { name: `${Errors.CUSTOMER_NOT_UNIQUE}_${type}` } },
          'cardApplication',
        );
        ToastModule.error({
          message: error,
          options: {
            x: 'center',
            y: 'top',
            timeout: 10000,
          },
        });
        return;
      }
    }
    this.nextStepButtonsDisabled = false;
    ToastModule.clearQueue();
  }

  closeDialog() {
    this.dialogSteps[this.stepperIndex.PERSON_DATA_INDEX]?.cropper.closeCrop();
    if (this.dialogSteps[this.stepperIndex.CUSTODIAN_DATA_INDEX]?.cropper) {
      this.dialogSteps[this.stepperIndex.CUSTODIAN_DATA_INDEX].cropper.closeCrop();
    }
    this.show = false;
    this.cardApplicationModule.initCardApplication();
    this.clearDialog();
  }

  async nextStep() {
    // you can create your own validate() function in form component
    if (typeof this.activeDialogStep?.validate === 'function') {
      if (await this.activeDialogStep.validate()) {
        this.activeStep++;
      }
    }

    // temporary workaround - this should be called after opening the dialog but for some reason it doesn't set the profile at all when called from there
    if (this.isVirtualCard && this.activeStep === this.stepperIndex.CARD_DATA_INDEX + 1) {
      this.cardApplicationModule.setVerifiedProfile(this.cardApplicationModule.customerData);
    }
  }

  get custodian() {
    return this.cardApplicationModule.custodianData;
  }

  previousStep() {
    this.activeStep--;
  }

  async addAttachments(cardApplicationId: string, attachments: Attachment[], isProfileOne = false) {
    for (const attachment of attachments) {
      await cardApplicationService.addAttachment(cardApplicationId, attachment.id, isProfileOne, attachment.file);
    }
  }

  async processAttachments(cardApplicationId: string) {
    try {
      if (!this.cardApplicationModule.emptyAttachments) {
        this.showAttachmentLoading = true;
        await this.addAttachments(
          cardApplicationId,
          this.cardApplicationModule.attachments.profileOneAttachments,
          true,
        );
        await this.addAttachments(cardApplicationId, this.cardApplicationModule.attachments.profileTwoAttachments);
      }
    } catch (e) {
      ToastModule.error({
        message: this.$t('cardApplication.errors.attachmentUploadFailed'),
      });
    }
    this.showAttachmentLoading = false;
  }

  async save() {
    try {
      const cardApplication = await this.dialogSteps[this.stepperIndex.SUMMARY_DATA_INDEX].createCardApplication();
      await this.shoppingCartModule.updateShoppingCart();
      if (this.systemConfigurationModule.cardApplicationAttachmentAllowed) {
        await this.processAttachments(cardApplication.id);
      }

      if (this.isBankCard && this.cardApplicationModule.bankCardId === REGISTER_NEW_BANK_CARD) {
        await this.initTokenization(cardApplication.id);
      } else {
        this.show = false;
        this.confirmCallback();
        this.cardApplicationModule.initCardApplication();
        this.activeStep = 1;
        this.clearDialog();
        ToastModule.success({ message: this.$t('cardApplication.createSuccess') });
      }
    } catch (e) {
      ToastModule.error({
        message: getNumberedApiError(e as ApiErrorInterface, 'cardApplication', undefined, {
          minCustomerAge: MIN_CUSTOMER_AGE,
        }),
      });
    }
  }

  async initTokenization(cardApplicationId: string) {
    this.showTokenizationRedirectLoading = true;
    try {
      const tokenizationRequest = await bankCardService.initTokenization(cardApplicationId);

      window.location.href = getUrl(tokenizationRequest);
    } catch (e) {
      this.showTokenizationRedirectLoading = false;
      ToastModule.error({
        message: this.$t('userAccountPage.tokenizationFailed'),
      });
    }
  }

  async saveAndRedirect() {
    await this.save();
    await this.$router.push(Paths.SHOPPING_CART);
  }

  clearDialog() {
    this.stepperIndex.CARD_DATA_INDEX = 1;
    this.stepperIndex.SUMMARY_DATA_INDEX = 2;
    this.isVirtualCard = false;
    this.dialogType = CardApplicationDialogType.Detail;

    this.steps = [
      {
        title: this.$t('cardApplication.personalData.stepName'),
        form: PersonalDataForm,
        formDisabled: false,
      },
      {
        title: this.$t('cardApplication.cardData.stepName'),
        form: CardDataForm,
        formDisabled: false,
      },
      {
        title: this.$t('cardApplication.summary.stepName'),
        form: SummaryForm,
        formDisabled: false,
      },
    ];
  }

  get activeDialogStep() {
    return this.dialogSteps[this.activeStep - 1];
  }
}
</script>

<style lang="scss" scoped>
.headlineWrapper {
  width: 100%;
  padding: 12px;
}

.headline {
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

header.dialog-toolbar > .v-toolbar__content {
  padding-left: 12px;
}

.v-toolbar__content .v-card__title.headline {
  padding-left: 0;
}

.button-mobile {
  button {
    min-width: 120px;
  }
}
</style>
