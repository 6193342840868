<template>
  <v-card width="100%" class="fill-height pa-4 overflow-visible">
    <RDPDataTable
      ref="dataTable"
      :headers="headers"
      :filter="filter"
      :search-items-callback="searchEWalletTransactions"
      :get-row-class-callback="getRowClass"
      :row-click-callback="rowClick"
      :i18n-group-key="i18nGroupKey"
      is-expand-enabled
      :data-cy="dataCy"
    >
      <template #head-panel.start>
        <!-- removed in RDP-1207 as the CSS style is broken in width between 1200-1500px - TODO find a better solution
        <v-col
          :cols="$vuetify.display.mobile ? 12 : 2"
          ml="3"
          :style="filterStyle"
        >
          <v-select
            multiple
            v-model="filter.operations"
            :label="$t(`${i18nGroupKey}.operationsFilter`)"
            :items="operationTypeItems"
            clearable
            chips
            :data-cy="`${dataCy}-operationsFilter`"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.text }}</span>
                <span
                  v-if="filter.operations.length > 1"
                  class="grey--text caption ml-1"
                  >{{
                    $t('multiselectOthers', {
                      selectionLength: filter.operations.length - 1,
                    })
                  }}</span
                >
              </v-chip>
            </template>
          </v-select>
        </v-col>
        <v-col
          :cols="$vuetify.display.mobile ? 12 : 2"
          ml="3"
          :style="filterStyle"
        >
          <v-select
            multiple
            v-model="filter.paymentTypes"
            :label="$t(`${i18nGroupKey}.paymentTypesFilter`)"
            :items="paymentTypeItems"
            clearable
            chips
            :data-cy="`${dataCy}-paymentTypes`"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.text }}</span>
                <span
                  v-if="filter.paymentTypes.length > 1"
                  class="grey--text caption ml-1"
                  >{{
                    $t('multiselectOthers', {
                      selectionLength: filter.paymentTypes.length - 1,
                    })
                  }}</span
                >
              </v-chip>
            </template>
          </v-select>
        </v-col>
        -->
        <v-col class="ml" :cols="$vuetify.display.mobile ? 12 : 2" :style="filterStyle">
          <RDPDatePicker
            v-model="filter.date"
            :label="$t(`${i18nGroupKey}.dateFilter`)"
            clearable
            :max="new Date().toISOString()"
            :data-cy="`${dataCy}-dateFilter`"
          />
        </v-col>
      </template>
      <template #expanded-item>
        <td colspan="10">
          <table v-if="ticketsLoaded && tickets.length > 0" class="tickets">
            <tr>
              <th style="text-align: left">
                {{ t('ticket.customerProfile') }}
              </th>
              <th style="text-align: left">
                {{ t('ticket.tariffProfile') }}
              </th>
              <th style="text-align: left">
                {{ t('ticket.validFrom') }}
              </th>
              <th style="text-align: left">
                {{ t('ticket.validTo') }}
              </th>
              <th style="text-align: right">
                {{ t('ticket.price') }}
              </th>
              <th style="text-align: right">
                {{ t('ticket.passengerCount') }}
              </th>
              <th style="text-align: right">
                {{ t('ticket.sumTicketsPrice') }}
              </th>
            </tr>
            <tr v-for="ticket of tickets" :key="ticket.ticketNumber">
              <td style="text-align: left">
                {{ ticket.customerProfile.name }}
              </td>
              <td style="text-align: left">
                {{ ticket.tariffProfile.name }}
              </td>
              <td style="text-align: left">
                {{ ticket.validFrom }}
              </td>
              <td style="text-align: left">
                {{ ticket.validTo }}
              </td>
              <td style="text-align: right">
                {{
                  $t('common.formattedCurrency', {
                    price: filters.toPrice(ticket.ticketPrice.toString()),
                  })
                }}
              </td>
              <td style="text-align: right">
                {{ ticket.passengerCount }}
              </td>
              <td style="text-align: right">
                {{
                  $t('common.formattedCurrency', {
                    price: filters.toPrice(ticket.sumTicketsPrice.toString()),
                  })
                }}
              </td>
            </tr>
          </table>

          <div v-else-if="ticketsLoaded" class="text-center font-italic pb-2">
            {{ t('noDetailFound') }}
          </div>
        </td>
      </template>
    </RDPDataTable>
  </v-card>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-facing-decorator';
import { RDPDataTableInterface } from '@/components/common/RDPDataTable/RDPDataTableInterfaces';
import customerService from '@/services/api/customerService';
import electronicWalletTransactionService from '@/services/api/electronicWalletTransactionService';
import RDPDataTable from '@/components/common/RDPDataTable/RDPDataTable.vue';
import { DATA_TABLE_HEADER_TYPES } from '@/components/common/RDPDataTable/DataTableHelper';
import { VuetifySelectOptionsVm } from '@/models/VuetifySelectOptionsVm';
import { ElectronicWalletTransactionQuery, ElectronicWalletTransactionVm, EnumsDto, VehicleTicketDto } from '@/api';
import RDPDatePicker from '@/components/common/RDPDatePicker/RDPDatePicker.vue';
import CustomerModule from '@/store/modules/customer';
import ToastModule from '@/store/modules/toast';
import * as filters from '@/filters';

const DEFAULT_SORT_BY_COLUMN = 'dateTime';
const DEFAULT_SORT_DESC = true;

@Component({
  components: {
    RDPDatePicker,
    RDPDataTable,
  },
})
export default class EWalletTab extends Vue {
  @Ref()
  readonly dataTable!: RDPDataTableInterface;

  customerModule = CustomerModule;

  i18nGroupKey = 'userAccountPage.eWalletList';
  dataCy = 'user-account-e-wallet-list';

  filter = {
    operations: undefined,
    paymentTypes: undefined,
    date: undefined,
    sortBy: DEFAULT_SORT_BY_COLUMN,
    sortDesc: DEFAULT_SORT_DESC,
  } as ElectronicWalletTransactionQuery;
  filters = filters;

  get headers() {
    return [
      {
        text: 'operation.displayName',
        applyRowClass: true,
      },
      { text: 'dateTime', type: DATA_TABLE_HEADER_TYPES.DATETIME },
      'transactionNumber',
      { text: 'lineNumber', sortable: false },
      { text: 'address', sortable: false },
      { text: 'amount', type: DATA_TABLE_HEADER_TYPES.PRICE },
      {
        text: 'newBalance',
        transform: this.formatNewBalance,
      },
      { text: 'paymentType.name', sortable: false },
      { text: 'cardNumber', type: DATA_TABLE_HEADER_TYPES.CARD_NUMBER },
    ];
  }

  tickets = [] as VehicleTicketDto[];
  ticketsLoaded = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t(i18nKey: string, param?: any) {
    return this.$t(`${this.i18nGroupKey}.${i18nKey}`, param);
  }

  async searchEWalletTransactions(filters: ElectronicWalletTransactionQuery) {
    return customerService.getCustomerEWalletTransactionList(this.customerModule.currentlySelectedCustomerId, filters);
  }

  get filterStyle() {
    return this.$vuetify.display.mobile ? '' : 'margin-top: -95px; margin-right: -15px;';
  }

  get paymentTypeItems(): VuetifySelectOptionsVm[] {
    return Object.values(EnumsDto.paymentTypes).map(key => ({
      title: this.t(`paymentType.${key}`),
      value: key,
    }));
  }

  get operationTypeItems(): VuetifySelectOptionsVm[] {
    return Object.values(EnumsDto.electronicWalletOperations).map(key => ({
      title: this.t(`operationType.${key}`),
      value: key,
    }));
  }

  getRowClass(row: ElectronicWalletTransactionVm) {
    return row.amount > 0 ? 'e-wallet-ACCEPT' : 'e-wallet-PAY';
  }

  async rowClick(row: ElectronicWalletTransactionVm, isExpanded: boolean) {
    this.tickets = [];
    if (isExpanded) {
      this.ticketsLoaded = false;
      try {
        this.tickets = await electronicWalletTransactionService.getVehicleTickets(row.id);
        this.ticketsLoaded = true;
      } catch (e) {
        ToastModule.error({
          message: this.$t(`${this.i18nGroupKey}.loadingDetailFailed`),
        });
      }
    }
  }

  /**
   * This is called from UserAccountPage.vue
   */
  refresh() {
    this.dataTable.refresh();
  }

  formatNewBalance(item: ElectronicWalletTransactionVm) {
    return item.processedOnCard ? `${filters.toPrice(item.newBalance.toString())} ${this.$t('common.currency')}` : '';
  }
}
</script>

<style lang="scss">
.table-component {
  .table-head-panel {
    align-items: flex-end !important;
  }
}

.e-wallet-ACCEPT {
  background-color: #c7ffc9 !important;
}

.e-wallet-PAY {
  background-color: #ffd3d3 !important;
}

table.tickets {
  width: 100%;
  border-bottom: 1px solid lightgrey;
  font-size: 14px;
}
</style>
