<template>
  <UserAccountPage />
</template>

<script lang="ts">
import { Component, Hook, Vue } from 'vue-facing-decorator';
import UserAccountPage from '@/pages/UserAccountPage.vue';
import UserAccountModule from '@/store/modules/userAccount';
import { AccountTypes } from '@/constants/AccountTypes';
import CustomerModule from '@/store/modules/customer';
import Paths from '@/constants/Paths';
import { NavigationGuardNext, RouteRecord } from 'vue-router';

@Component({
  components: { UserAccountPage },
})
export default class UserLinkedAccountPage extends Vue {
  @Hook
  async beforeRouteEnter(to: RouteRecord, from: RouteRecord, next: NavigationGuardNext) {
    if (!CustomerModule.linkedCustomer.id) {
      return next(Paths.USER_ACCOUNT);
    }

    UserAccountModule.setSelectedAccount(AccountTypes.LINKED);
    next();
  }

  @Hook
  beforeRouteLeave(to: RouteRecord, from: RouteRecord, next: NavigationGuardNext) {
    UserAccountModule.setSelectedAccount(AccountTypes.REGISTERED);
    next();
  }
}
</script>
