import moment from 'moment';
import DurationConstructor = moment.unitOfTime.DurationConstructor;
import { DATE_FORMAT } from '@/utils/dateTime';
import { EnumDto, EnumsDto } from '@/api';

export const convertToMomentTypes = (quantity: number, period: EnumDto | undefined): [DurationConstructor, number] => {
  let unit: DurationConstructor;
  let amount: number;
  if (period?.name === EnumsDto.tariffProfilePeriods.HALF_YEAR) {
    unit = 'month';
    amount = 6 * quantity;
  } else {
    unit = period?.name?.toLowerCase() as DurationConstructor;
    amount = quantity;
  }

  return [unit, amount];
};

export const computeSlidingTicketValidity = (
  startDay: string,
  quantity: number,
  period: EnumDto | undefined,
): [string, string] => {
  const [unit, amount] = convertToMomentTypes(quantity, period);

  const validTo = moment(startDay).add(amount, unit).subtract(1, 'day').format(DATE_FORMAT);

  return [startDay as string, validTo];
};

export const computeCalendarTicketValidity = (
  startDay: string,
  quantity: number,
  period: EnumDto | undefined,
): [string, string] => {
  if (period?.name === EnumsDto.tariffProfilePeriods.DAY) {
    return computeSlidingTicketValidity(startDay, quantity, period);
  }

  const [unit, amount] = convertToMomentTypes(quantity, period);

  const validFrom = moment(startDay).startOf(unit).format(DATE_FORMAT);

  const validTo = moment(validFrom).add(amount, unit).subtract(1, 'day').format(DATE_FORMAT);

  return [validFrom, validTo];
};

export const correctFixedDate = (year: number, month: number | undefined, day: number | undefined) => {
  let date = moment(new Date(year, (month || 1) - 1, day));
  // Check if date overflows.
  if (date.date() !== day) {
    // If so, set the last day of the given month.
    date = moment(new Date(year, (month || 1) - 1, 1)).endOf('month');
  }

  return date.format(DATE_FORMAT);
};

export const computeFixedTicketValidFrom = (
  startDay: string,
  fixedValidFromDay: number | undefined,
  fixedValidFromMonth: number | undefined,
  fixedValidToDay: number | undefined,
  fixedValidToMonth: number | undefined,
): [string, string] => {
  const year = moment(startDay).get('year');
  let validFrom = correctFixedDate(year, fixedValidFromMonth, fixedValidFromDay);
  let validTo = correctFixedDate(year, fixedValidToMonth, fixedValidToDay);

  if (moment(validFrom).isAfter(validTo)) {
    if (moment(validTo).isAfter(startDay)) {
      validFrom = moment(validFrom).subtract(1, 'year').format(DATE_FORMAT);
    } else {
      validTo = moment(validTo).add(1, 'year').format(DATE_FORMAT);
    }
  }

  return [validFrom, validTo];
};
