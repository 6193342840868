<template>
  <v-autocomplete
    v-if="!disabled"
    v-model="localValue"
    v-model:search="dataFilter"
    :items="items"
    :no-data-text="$t(`${i18nGroupKey}.noDataFound`)"
    :loading="itemsLoading"
    :rules="[requiredValidation]"
    :label="$t(`${i18nGroupKey}.dataSearch`)"
    :data-cy="`${dataCy}-autoComplete`"
    clearable
    variant="outlined"
    density="compact"
    hide-no-data
    hide-details
    hide-selected
    return-object
    @update:model-value="onDataChanged()"
  />
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator';
import debounce from 'lodash/debounce';
import AutoComplete from '@/constants/AutoComplete';
import { requiredValidator } from '@/utils/validators';
import { RDPAutoCompleteInterface } from '@/components/common/RDPAutoComplete/RDPAutoCompleteInterface';

@Component
export default class RDPAutoComplete extends Vue {
  @Prop({ required: false, type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Function, required: true })
  readonly searchDataFunction!: (queryText: string) => Promise<void>;

  @Prop({ type: Function, required: true })
  readonly searchedDataChanged!: () => void;

  @Prop({ type: Boolean, required: true })
  readonly itemsLoading!: boolean;

  @Prop({ type: Array, required: true })
  readonly items!: Array<{ value: string; title: string }>;

  @Prop({ required: true, type: String })
  readonly i18nGroupKey!: string;

  @Prop({ required: false, type: String, default: '' })
  readonly dataCy!: string;

  @Prop({
    required: false,
    type: Object,
    default: () => {
      return {
        title: '',
        value: '',
      };
    },
  })
  readonly value!: RDPAutoCompleteInterface;

  @Prop({ required: false, type: Boolean, default: false })
  readonly required!: boolean;

  dataFilter = '';
  searchDataDebounced: any;

  created() {
    this.searchDataDebounced = debounce(async (queryText: string) => {
      await this.searchDataFunction(queryText);
    }, AutoComplete.AUTO_COMPLETE_DEBOUNCE_TIME);
  }

  get requiredValidation() {
    return (v: RDPAutoCompleteInterface) => {
      return this.required ? requiredValidator(v.title) : true;
    };
  }

  onDataChanged() {
    this.searchedDataChanged();
  }

  get localValue() {
    return this.value;
  }

  set localValue(localValue) {
    this.$emit('update:modelValue', localValue);
  }

  @Watch('dataFilter')
  dataFilterChanged(queryText: string) {
    this.searchDataDebounced(queryText);
  }
}
</script>
