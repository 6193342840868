<template>
  <v-form ref="form" validate-on="lazy" data-cy="summaryData-data-form">
    <v-card class="summary-content-card" :style="summaryContentStyle">
      <h4>{{ $t('cardApplication.summary.customer') }}</h4>
      <v-row>
        <v-col :cols="$vuetify.display.mobile ? 12 : 3" class="pt-5">
          <RDPImage :src="cardApplicationModule.customerData.photo" />
        </v-col>
        <v-col :cols="$vuetify.display.mobile ? 12 : 5">
          <v-row>
            <v-col class="text-right">
              {{ `${$t('cardApplication.personalData.firstName')}:` }}
            </v-col>
            <v-col class="text-left" data-cy="summaryData-data-form-firstName">
              {{ cardApplicationModule.customerData.firstName }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right">
              {{ `${$t('cardApplication.personalData.lastName')}:` }}
            </v-col>
            <v-col class="text-left" data-cy="summaryData-data-form-lastName">
              {{ cardApplicationModule.customerData.lastName }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right">
              {{ `${$t('cardApplication.personalData.birthDay')}:` }}
            </v-col>
            <v-col class="text-left" data-cy="summaryData-data-form-birthDay">
              {{ cardApplicationModule.customerData.birthDay }}
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="$vuetify.display.mobile ? 12 : 4">
          <v-row>
            <v-col class="text-right">
              {{ `${$t('cardApplication.summary.address')}:` }}
            </v-col>
            <v-col class="text-left" :cols="$vuetify.display.mobile ? 6 : 7" data-cy="summaryData-data-form-address">
              {{ address }}
            </v-col>
          </v-row>
          <v-row v-if="cardApplicationModule.customerData.email">
            <v-col class="text-right">
              {{ `${$t('cardApplication.personalData.email')}:` }}
            </v-col>
            <v-col class="text-left" :cols="$vuetify.display.mobile ? 6 : 7" data-cy="summaryData-data-form-email">
              {{ cardApplicationModule.customerData.email }}
            </v-col>
          </v-row>
          <v-row v-if="cardApplicationModule.customerData.phoneNumber">
            <v-col class="text-right">
              {{ `${$t('cardApplication.personalData.phone')}:` }}
            </v-col>
            <v-col class="text-left" :cols="$vuetify.display.mobile ? 6 : 7" data-cy="summaryData-data-form-phone">
              {{ cardApplicationModule.customerData.phoneNumber }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="summary-content-card" :style="summaryContentStyle">
      <h4>
        {{ $t('cardApplication.summary.profileOne') }}
      </h4>
      <v-row v-if="customerProfileOne">
        <v-col class="text-right" :cols="customerProfileTwo ? 4 : 6">
          {{ `${$t('cardApplication.summary.profile')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-customerProfileOne">
          {{ customerProfileOneDisplayName }}
        </v-col>
      </v-row>
      <v-row v-if="cardApplicationModule.cardData.profileOne.validFrom">
        <v-col class="text-right" :cols="customerProfileTwo ? 4 : 6">
          {{ `${$t('cardApplication.summary.validFrom')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-customerProfileOneValidFrom">
          {{ formatDate(cardApplicationModule.cardData.profileOne.validFrom) }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right" :cols="customerProfileTwo ? 4 : 6">
          {{ `${$t('cardApplication.summary.validTo')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-customerProfileOneValidTo">
          <span v-if="cardApplicationModule.cardData.profileOne.validTo"
            >{{ formatDate(cardApplicationModule.cardData.profileOne.validTo) }}
          </span>
          <span v-else>
            {{ $t('cardApplication.cardData.profileOne.validToCardValidity') }}
          </span>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-if="customerProfileTwo" class="summary-content-card" :style="summaryContentStyle">
      <h4>
        {{ $t('cardApplication.summary.profileTwo') }}
      </h4>
      <v-row>
        <v-col class="text-right" cols="4">
          {{ `${$t('cardApplication.summary.profile')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-customerProfileTwo">
          {{ customerProfileTwoDisplayName }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right" cols="4">
          {{ `${$t('cardApplication.summary.validFrom')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-customerProfileTwoValidFrom">
          {{ formatDate(cardApplicationModule.cardData.profileTwo!.validFrom) }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right" cols="4">
          {{ `${$t('cardApplication.summary.validTo')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-customerProfileTwoValidTo">
          <span v-if="cardApplicationModule.cardData.profileTwo!.validTo"
            >{{ formatDate(cardApplicationModule.cardData.profileTwo!.validTo) }}
          </span>
          <span v-else>
            {{ $t('cardApplication.cardData.profileTwo.validToCardValidity') }}
          </span>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-if="!isBankCard" class="summary-content-card" :style="summaryContentStyle">
      <h4>
        {{ $t('cardApplication.summary.detail') }}
      </h4>
      <v-row>
        <v-col class="text-right">
          {{ `${$t('cardApplication.cardData.deliveryType')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-deliveryType">
          <span v-if="isVirtualCard">
            {{ $t('cardApplication.cardData.mobilePhone') }}
          </span>
          <span v-else>
            {{ deliveryType }}
          </span>
        </v-col>
      </v-row>
      <v-row v-if="showRecipientAddress">
        <v-col class="text-right">
          {{ `${$t('cardApplication.summary.recipientAddress')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-recipientAddress">
          {{ recipientAddress }}
        </v-col>
      </v-row>
      <v-row v-if="showBranchOffice">
        <v-col class="text-right">
          {{ `${$t('cardApplication.summary.branchOffice')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-branchOffice">
          {{ branchOffice }} <br />
          {{ branchOfficeAddress }}
        </v-col>
      </v-row>
      <v-row v-if="!isVirtualCard && !isBankCard">
        <v-col class="text-right">
          {{ `${$t('cardApplication.summary.contactIfCardFound')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-contactMeIfCardFound">
          {{ translateBoolean(cardApplicationModule.cardData.contactMeIfCardFound) }}
        </v-col>
      </v-row>
      <v-row v-if="systemConfigurationModule.isAllowedFreeCardApplication && !isVirtualCard && !isBankCard">
        <v-col class="text-right">
          {{ `${$t('cardApplication.summary.allowFreeCardApplication')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-cardForFree">
          {{ translateBoolean(cardApplicationModule.cardData!.cardForFree!) }}
        </v-col>
      </v-row>
      <v-row v-if="showPin">
        <v-col class="text-right">
          {{ `${$t('cardApplication.cardData.pin')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-pin">
          {{ cardApplicationModule.customerData.pin }}
        </v-col>
      </v-row>
    </v-card>
    <v-card v-if="cardApplicationModule.isCustodianAdded" class="summary-content-card" :style="summaryContentStyle">
      <h4>{{ $t('cardApplication.summary.custodian') }}</h4>
      <v-row>
        <v-col class="text-right">
          {{ `${$t('cardApplication.custodianData.firstName')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-custodian-firstName">
          {{ cardApplicationModule.custodianData.firstName }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          {{ `${$t('cardApplication.custodianData.lastName')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-custodian-lastName">
          {{ cardApplicationModule.custodianData.lastName }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          {{ `${$t('cardApplication.custodianData.birthDay')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-custodian-birthDay">
          {{ cardApplicationModule.custodianData.birthDay }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          {{ `${$t('cardApplication.summary.address')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-custodian-address">
          {{ custodianAddress }}
        </v-col>
      </v-row>
      <v-row v-if="cardApplicationModule.custodianData.email">
        <v-col class="text-right" cols="6">
          {{ `${$t('cardApplication.custodianData.email')}:` }}
        </v-col>
        <v-col class="text-left" cols="6" data-cy="summaryData-data-form-custodian-email">
          {{ cardApplicationModule.custodianData.email }}
        </v-col>
      </v-row>
      <v-row v-if="cardApplicationModule.custodianData.phoneNumber">
        <v-col class="text-right">
          {{ `${$t('cardApplication.custodianData.phone')}:` }}
        </v-col>
        <v-col class="text-left" data-cy="summaryData-data-form-custodian-phone">
          {{ cardApplicationModule.custodianData.phoneNumber }}
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue } from 'vue-facing-decorator';
import CardApplicationModule from '@/store/modules/cardApplication';
import BranchOfficeModule from '@/store/modules/branchOffice';
import { DATE_PICKER_FORMAT } from '@/utils/dateTime';
import moment from 'moment';
import customerService from '@/services/api/customerService';
import cardApplicationService from '@/services/api/cardApplicationService';
import customerMapper from '../mappers/customerMapper';
import cardApplicationMapper from '../mappers/cardApplicationMapper';
import CustomerProfileModule from '@/store/modules/customerProfile';
import { AddressDto, BranchOfficeDto, CardApplicationDto, CardApplicationProfileDto, CustomerProfileDto } from '@/api';
import RDPImage from '@/components/common/RDPImage/RDPImage.vue';
import { DeliveryTypeI18nKeys, DeliveryTypes } from '@/constants/DeliveryTypes';
import CustomerModule from '@/store/modules/customer';
import UserAccountModule from '@/store/modules/userAccount';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import { getCustomerProfileDisplayName } from '@/utils/i18n';
import { getFormattedAddress } from '@/utils/getFormattedAddress';
import { FormInterface } from '@/components/common/FormInterface';
import { ValidationInterface } from '@/components/common/ValidationInterface';

@Component({
  components: {
    RDPImage,
  },
  expose: ['form', 'createCardApplication'],
})
export default class SummaryForm extends Vue implements ValidationInterface {
  @Prop({ required: false, type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ required: true, type: Boolean })
  readonly isVirtualCard!: boolean;

  @Prop({ required: true, type: Boolean })
  readonly isBankCard!: boolean;

  @Ref()
  readonly form!: FormInterface;

  cardApplicationModule = CardApplicationModule;
  customerProfileModule = CustomerProfileModule;
  branchOfficeModule = BranchOfficeModule;
  customerModule = CustomerModule;
  accountModule = UserAccountModule;
  systemConfigurationModule = SystemConfigurationModule;

  get showRecipientAddress() {
    return !this.isVirtualCard && !this.isBankCard && this.cardApplicationModule.recipientAddressData.street !== '';
  }

  get showBranchOffice() {
    return !this.isVirtualCard && !this.isBankCard && this.cardApplicationModule.deliveryBranchOfficeData.id !== '';
  }

  get showPin() {
    return !this.isVirtualCard && !this.isBankCard && this.cardApplicationModule.customerData.pin;
  }

  get customerProfileOne(): CustomerProfileDto | undefined {
    return this.getCustomerProfile(this.cardApplicationModule.cardData.profileOne);
  }

  get customerProfileOneDisplayName(): string {
    return getCustomerProfileDisplayName(this.customerProfileOne?.id);
  }

  get customerProfileTwo(): CustomerProfileDto | undefined {
    return this.getCustomerProfile(this.cardApplicationModule.cardData.profileTwo);
  }

  get customerProfileTwoDisplayName(): string {
    return getCustomerProfileDisplayName(this.customerProfileTwo?.id);
  }

  getCustomerProfile(cp?: CardApplicationProfileDto) {
    return this.customerProfileModule.customerProfiles.find(customerProfile => customerProfile.id === cp?.profile.id);
  }

  get branchOffice(): string {
    const branchOffice = this.findBranchOffice(this.cardApplicationModule.deliveryBranchOfficeData);
    return branchOffice?.name || '';
  }

  get deliveryType() {
    if (this.cardApplicationModule.recipientAddressData?.street) {
      return this.$t(DeliveryTypeI18nKeys[DeliveryTypes.POST_OFFICE]);
    } else {
      return this.$t(DeliveryTypeI18nKeys[DeliveryTypes.BRANCH]);
    }
  }

  get address(): string {
    return this.createAddress(this.cardApplicationModule.customerData.address);
  }

  get recipientAddress(): string {
    if (this.cardApplicationModule.recipientAddressData?.street) {
      return this.createAddress(this.cardApplicationModule.recipientAddressData);
    }
    return '';
  }

  get custodianAddress() {
    if (this.cardApplicationModule.custodianData?.address?.street) {
      return this.createAddress(this.cardApplicationModule.custodianData?.address);
    }
    return '';
  }

  get summaryContentStyle() {
    return this.$vuetify.display.mobile ? 'padding: 10px 6px' : 'padding: 10px 25px';
  }

  get branchOfficeAddress(): string {
    const branchOffice = this.findBranchOffice(this.cardApplicationModule.deliveryBranchOfficeData);
    if (branchOffice) {
      return this.createAddress(branchOffice.address);
    } else {
      return '';
    }
  }

  findBranchOffice(branchOffice: BranchOfficeDto | undefined) {
    return this.branchOfficeModule.branchOfficeList.find(
      (tBranchOffice: BranchOfficeDto) => tBranchOffice.id === branchOffice?.id,
    );
  }

  createAddress(address: AddressDto) {
    return getFormattedAddress(address);
  }

  translateBoolean(value: boolean): string {
    return value ? this.$t('common.yes') : this.$t('common.no');
  }

  formatDate(date: string): string {
    return moment(date).format(DATE_PICKER_FORMAT);
  }

  async validate() {
    // here you can create your own rules for this component form
    return (await this.form.validate()).valid;
  }

  async createCustomer() {
    const customer = customerMapper.toCreateCustomerCommand(
      this.cardApplicationModule.customerData,
      this.cardApplicationModule.termsAndConditionsApproval && this.cardApplicationModule.gdprApproval,
      this.cardApplicationModule.custodianData,
    );
    const newCustomer = await customerService.createCustomer(customer);

    if (!this.customerModule.currentlySelectedCustomerId) {
      const customerId = newCustomer.parentId ? newCustomer.parentId : newCustomer.originId;
      const customerData = await customerService.getCustomer(customerId);
      this.customerModule.setCustomer(customerData);
      this.accountModule.setSelectedCustomerData(customerData);
    }

    return newCustomer.id;
  }

  async createCardApplication(): Promise<CardApplicationDto> {
    const customerId = await this.createCustomer();

    const cardData = {
      ...this.cardApplicationModule.cardData,
      marketingConsent:
        this.systemConfigurationModule.configuration.marketingConsentCardEnabled &&
        this.cardApplicationModule.marketingConsent,
    } as CardApplicationDto;

    if (this.isVirtualCard) {
      const cardApplicationCommand = cardApplicationMapper.toCreateVirtualCardApplicationCommand(cardData, customerId);
      return cardApplicationService.createVirtualCardApplication(cardApplicationCommand);
    } else if (this.isBankCard) {
      const cardApplicationCommand = cardApplicationMapper.toCreateBankCardApplicationCommand(
        cardData,
        customerId,
        this.cardApplicationModule.bankCardId,
      );
      return cardApplicationService.createBankCardApplication(cardApplicationCommand);
    } else {
      const cardApplicationCommand = cardApplicationMapper.toCreateCardApplicationCommand(
        cardData,
        customerId,
        this.cardApplicationModule.recipientAddressData,
        this.cardApplicationModule.deliveryBranchOfficeData,
      );
      return cardApplicationService.createCardApplication(cardApplicationCommand);
    }
  }
}
</script>
<style scoped lang="scss">
.summary-content-card {
  margin: 2px 2px 20px 2px;
}
</style>
