/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EnumDto } from './EnumDto';
import type { VehicleCardInfoDto } from './VehicleCardInfoDto';
import type { VehicleTicketDto } from './VehicleTicketDto';

export type VehicleTransactionDetailVm = {
    lineNumber: number;
    connectionNumber: number;
    currentTariffStationNumber: number;
    currentStationZone: VehicleTransactionDetailVm.currentStationZone;
    cashPayCurrency?: string;
    cashPayAmount?: number;
    bankCardTransactionNumber?: string;
    vehicleCardInfo: VehicleCardInfoDto;
    tickets: Array<VehicleTicketDto>;
    id: string;
    /**
     * Device number on which was transaction assigned.
     */
    deviceNumber: number;
    /**
     * Number of vehicle in which was transaction assigned.
     */
    vehicleNumber: number;
    driverNumber: number;
    /**
     * Ticket issue date.
     */
    issued: string;
    transactionNumber: number;
    cardNumber: string;
    /**
     * Vehicle Transaction Type. PaperTicket, Checkin, CancelledTicket...
     */
    transactionType: EnumDto;
    currency: string;
    price: number;
    /**
     * PaymentType. CASH, BANK_CARD, ELECTRONIC_WALLET...
     */
    paymentType: EnumDto;
    currentStation: string;
    transfer: boolean;
    /**
     * Bank payment variable symbol.
     */
    paymentVariableSymbol: number;
    /**
     * Bank payment code.
     */
    paymentCode: string;
    /**
     * Transaction result.
     */
    result: number;
};

export namespace VehicleTransactionDetailVm {

    export enum currentStationZone {
        ZONE_I = 'ZONE-I',
        ZONE_I_II = 'ZONE-I+II',
        ZONE_II = 'ZONE-II',
    }


}
