/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AddEWalletTransactionCommand = {
    operation: AddEWalletTransactionCommand.operation;
    /**
     * Previous balance. In cents.
     */
    previousBalance: number;
    /**
     * E-wallet transaction note.
     */
    note?: string;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
    /**
     * Electronic wallet identifier. Type: 'UUID'
     */
    eWalletId: string;
    /**
     * Price for the transaction. In cents.
     */
    price: number;
};

export namespace AddEWalletTransactionCommand {

    export enum operation {
        RECHARGE = 'RECHARGE',
        WITHDRAW = 'WITHDRAW',
    }


}
