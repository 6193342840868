/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DenyCardDto = {
    id: string;
    token: string;
    status: DenyCardDto.status;
};

export namespace DenyCardDto {

    export enum status {
        NOT_IN_DENY_LIST = 'NOT_IN_DENY_LIST',
        IS_IN_DENY_LIST = 'IS_IN_DENY_LIST',
        WAS_IN_DENY_LIST = 'WAS_IN_DENY_LIST',
        DENY_LIST_RECOVER_DEPLETED = 'DENY_LIST_RECOVER_DEPLETED',
        CARD_NOT_FOUND = 'CARD_NOT_FOUND',
    }


}
