/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DenyCardQuery = {
    /**
     * Token of card.
     */
    token?: string;
    /**
     * Deny list status.
     */
    status?: DenyCardQuery.status;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
    /**
     * Page Size. Default: 25
     */
    limit?: number;
    /**
     * Offsetting x items. Default: 0
     */
    offset?: number;
    /**
     * Sort by. Default: id
     */
    sortBy?: string;
    /**
     * Sort order descendent. Default: true
     */
    sortDesc?: boolean;
    /**
     * Sort with locale. Default: sk
     */
    sortLocale?: string;
    /**
     * Filter by date within date range. Type: 'DATE'
     */
    date?: string;
};

export namespace DenyCardQuery {

    /**
     * Deny list status.
     */
    export enum status {
        NOT_IN_DENY_LIST = 'NOT_IN_DENY_LIST',
        IS_IN_DENY_LIST = 'IS_IN_DENY_LIST',
        WAS_IN_DENY_LIST = 'WAS_IN_DENY_LIST',
        DENY_LIST_RECOVER_DEPLETED = 'DENY_LIST_RECOVER_DEPLETED',
        CARD_NOT_FOUND = 'CARD_NOT_FOUND',
    }


}
