/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AddTicketFareCommand = {
    /**
     * Ticket combination identifier. Type: 'UUID'
     */
    ticketCombinationId: string;
    /**
     * Zone code.
     */
    zone: AddTicketFareCommand.zone;
    /**
     * Ticket valid from. Type: 'DATE'
     */
    validFrom: string;
    /**
     * Customer card identifier. Type: 'UUID'
     */
    cardId: string;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
};

export namespace AddTicketFareCommand {

    /**
     * Zone code.
     */
    export enum zone {
        ZONE_I = 'ZONE-I',
        ZONE_I_II = 'ZONE-I+II',
        ZONE_II = 'ZONE-II',
    }


}
