import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { CustomerCardDetailVm, CustomerCardProfilesVm, CustomerDetailVm, EnumsDto } from '@/api';
import { AccountTypes } from '@/constants/AccountTypes';
import { MIN_CUSTOMER_AGE } from '@/config/config';
import moment from 'moment';
import { toDate } from '@/utils/dateTime';

@Module({ dynamic: true, store, namespaced: true, name: 'userAccount' })
class UserAccount extends VuexModule {
  public hasActiveCardApplication = false;
  public cardDetailData = {
    state: {},
    customer: {},
    electronicWallet: {},
    profileOne: {} as CustomerCardProfilesVm,
  } as unknown as CustomerCardDetailVm;
  public selectedCustomerData = {} as CustomerDetailVm;
  public selectedAccount = AccountTypes.REGISTERED;
  public canVirtualCardApplicationBeCreated = true;

  @Mutation
  public setCardData(payload: CustomerCardDetailVm): void {
    this.cardDetailData = payload;
  }

  @Mutation
  public setSelectedCustomerData(payload: CustomerDetailVm): void {
    this.selectedCustomerData = payload;
  }

  @Mutation
  public setCardApplicationActive(active: boolean) {
    this.hasActiveCardApplication = active;
  }

  @Mutation
  public setSelectedAccount(accountType: string) {
    this.selectedAccount = accountType;
  }

  @Mutation
  public clearSelectedCustomerData(): void {
    this.selectedCustomerData = {} as CustomerDetailVm;
  }

  @Mutation
  public setVirtualCardApplicationCanBeCreated(canBeCreated: boolean): void {
    this.canVirtualCardApplicationBeCreated = canBeCreated;
  }

  @Mutation
  public clearCardData(): void {
    this.cardDetailData = {
      state: {},
      customer: {},
      electronicWallet: {},
    } as CustomerCardDetailVm;
  }

  public get isCardActive(): boolean {
    return this.cardDetailData?.state.name === EnumsDto.cardStates.ACTIVE;
  }

  public get isCardTypeVirtual(): boolean {
    return this.cardDetailData?.customerCardType === CustomerCardDetailVm.customerCardType.VIRTUAL_CARD;
  }

  public get isCardTypeBank(): boolean {
    return this.cardDetailData?.customerCardType === CustomerCardDetailVm.customerCardType.BANK_CARD;
  }

  public get cardNumber(): string | undefined {
    return this.cardDetailData?.cardNumber;
  }

  public get hasCardDetailData(): boolean {
    return !!this.cardDetailData?.id;
  }

  public get personalDataVerified(): boolean {
    return Boolean(
      this.cardDetailData?.customer.personalDataVerifiedDate &&
        this.cardDetailData?.customer.personalDataVerifiedBy?.id,
    );
  }

  public get isSelectedAccountRegistered() {
    return this.selectedAccount === AccountTypes.REGISTERED;
  }

  public get customerIsChild() {
    const currentDate = toDate(new Date());
    const customerYears = moment(currentDate).diff(this.selectedCustomerData.birthDay, 'years');

    return customerYears < MIN_CUSTOMER_AGE;
  }
}

export default getModule(UserAccount);
