/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CardProductionQuery = {
    /**
     * Filter by card production number
     */
    productionNumber?: number;
    /**
     * Employee who create card production.
     */
    employeeName?: string;
    /**
     * Filter by card production states.
     */
    state?: CardProductionQuery.state;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
    /**
     * Page Size. Default: 25
     */
    limit?: number;
    /**
     * Offsetting x items. Default: 0
     */
    offset?: number;
    /**
     * Sort by. Default: id
     */
    sortBy?: string;
    /**
     * Sort order descendent. Default: true
     */
    sortDesc?: boolean;
    /**
     * Sort with locale. Default: sk
     */
    sortLocale?: string;
    /**
     * Filter by date within date range. Type: 'DATE'
     */
    date?: string;
};

export namespace CardProductionQuery {

    /**
     * Filter by card production states.
     */
    export enum state {
        IN_PROGRESS = 'IN_PROGRESS',
        PROCESSED = 'PROCESSED',
    }


}
