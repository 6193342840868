import axios from 'axios';
import i18n from '@/i18n';
import LocaleModule from '@/store/modules/locale';
import ToastModule from '@/store/modules/toast';
import { en, sk, cs, pl, ro } from 'vuetify/locale';

const vuetifyLocale = { en, sk, cs, pl, ro };

type MessageSection = {
  [key: string]: string;
};

type MessageSections = {
  [sectionKey: string]: MessageSection;
};

type MessageTree = {
  [key: string]: string | string[] | MessageTree;
};

type I18nMessages = MessageTree & { dynamic: MessageSections };

export const t = (key: string, value = {}) => {
  return i18n.global.t(key, value);
};

export const te = (key: string) => {
  return i18n.global.te(key);
};

export class KeyGroupI18n {
  private readonly keyGroup: string;
  private options: { ignoreNonExisting: boolean };

  constructor(keyGroup: string, options = { ignoreNonExisting: false }) {
    this.keyGroup = keyGroup;
    this.options = options;
  }

  te = (key: string) => {
    const composedKey = this.getComposedKey(key);
    return te(composedKey) || te(key);
  };
  getComposedKey = (key: string) => {
    return this.keyGroup ? `${this.keyGroup}.${key}` : key;
  };

  t = (key: string, value = {}) => {
    const composedKey = this.getComposedKey(key);
    if (!te(composedKey)) {
      if (this.options.ignoreNonExisting) {
        return te(key) ? t(key, value) : '';
      } else {
        // translate the key without its group
        return t(key, value);
      }
    }
    return t(composedKey, value);
  };
}

const setLocale = (locale: string, messages: I18nMessages): void => {
  i18n.global.setLocaleMessage(locale, {
    ...messages,
  });
  i18n.global.locale.value = locale;

  const html = document.documentElement;
  html.setAttribute('lang', i18n.global.locale.value);

  document.title = i18n.global.t('title');
};

export async function loadLocaleMessages(): Promise<void> {
  try {
    const preferredLocale = LocaleModule.locale;
    const response = await axios.get('api/eshop/i18n/messages', {
      headers: { 'accept-language': preferredLocale },
    });
    const {
      headers: { 'content-language': resolvedLocale },
      data: { data: messages },
    } = response;

    setLocale(resolvedLocale, { ...messages, $vuetify: vuetifyLocale[resolvedLocale] });
  } catch (e) {
    ToastModule.error({
      message: 'An error occurred while trying to load translated messages.',
      options: {
        timeout: -1,
        showClose: false,
        x: 'center',
        y: 'top',
      },
    });
    throw e;
  }
}

export async function loadSupportedLocales(): Promise<void> {
  try {
    const response = await axios.get('api/eshop/i18n/supported-locales');
    LocaleModule.setSupportedLocales(response.data.data);
  } catch (e) {
    ToastModule.error({
      message: 'An error occurred while trying to load supported locales.',
      options: {
        timeout: -1,
        showClose: false,
        x: 'center',
        y: 'top',
      },
    });
    throw e;
  }
}

enum TranslationSectionNames {
  tariffProfile = 'TARIFF_PROFILE',
  customerProfile = 'CUSTOMER_PROFILE',
  ticketCombination = 'TICKET_COMBINATION',
  fee = 'FEE',
}

const getDynamicTranslation = (section: TranslationSectionNames, id: string): string => t(`dynamic.${section}.${id}`);

export const getCustomerProfileDisplayName = (id: string | undefined): string =>
  id ? getDynamicTranslation(TranslationSectionNames.customerProfile, id) : '';

export const getTariffProfileDisplayName = (id: string | undefined): string =>
  id ? getDynamicTranslation(TranslationSectionNames.tariffProfile, id) : '';

export const getTicketCombinationDisplayName = (id: string | undefined): string =>
  id ? getDynamicTranslation(TranslationSectionNames.ticketCombination, id) : '';

export const getFeeDisplayName = (id: string | undefined): string =>
  id ? getDynamicTranslation(TranslationSectionNames.fee, id) : '';
