/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateDenyCardCommand = {
    /**
     * Deny list status
     */
    status: UpdateDenyCardCommand.status;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
};

export namespace UpdateDenyCardCommand {

    /**
     * Deny list status
     */
    export enum status {
        NOT_IN_DENY_LIST = 'NOT_IN_DENY_LIST',
        IS_IN_DENY_LIST = 'IS_IN_DENY_LIST',
        WAS_IN_DENY_LIST = 'WAS_IN_DENY_LIST',
        DENY_LIST_RECOVER_DEPLETED = 'DENY_LIST_RECOVER_DEPLETED',
        CARD_NOT_FOUND = 'CARD_NOT_FOUND',
    }


}
