/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { CreateTariffProfileCommand } from '../models/CreateTariffProfileCommand';
import type { DateRangeValidityResponse } from '../models/DateRangeValidityResponse';
import type { TariffProfileListResponse } from '../models/TariffProfileListResponse';
import type { TariffProfileResponse } from '../models/TariffProfileResponse';
import type { UpdateProfileNameCommand } from '../models/UpdateProfileNameCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TariffProfileService {

    /**
     * Get tariff profiles.
     * @returns TariffProfileListResponse 
     * @throws ApiError
     */
    public static tariffProfileControllerGetAll({
validity,
timezoneOffset,
limit,
offset,
sortBy,
sortDesc,
sortLocale,
}: {
validity?: 'PAST' | 'PRESENT' | 'FUTURE',
timezoneOffset?: number,
limit?: number,
offset?: number,
sortBy?: string,
sortDesc?: boolean,
sortLocale?: string,
}): CancelablePromise<TariffProfileListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/tariff-profiles',
            query: {
                'validity': validity,
                'timezoneOffset': timezoneOffset,
                'limit': limit,
                'offset': offset,
                'sortBy': sortBy,
                'sortDesc': sortDesc,
                'sortLocale': sortLocale,
            },
            errors: {
                401: `User wasn't authenticated.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Create tariff profile.
     * Require: `PERMISSION_ADMINISTRATION_VIEW`<br>
 * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
 * + **INVALID_VALID_TO** - ValidFrom is after ValidTo OR New ValidFrom is null or new validFrom is before old ValidFrom.<br>
 * + **INVALID_TARIFF_PROFILE_TYPE** - Tariff profile type does not exist or input data are not sufficient.<br>
 * + **INVALID_TARIFF_PROFILE_PERIOD** - Tariff profile period does not exist.<br>
 * + **INVALID_TARIFF_PROFILE** - Tariff profile does not exist.
     * @returns TariffProfileResponse 
     * @throws ApiError
     */
    public static tariffProfileControllerCreate({
requestBody,
}: {
/**
 * CreateTariffProfileCommand
 */
requestBody?: CreateTariffProfileCommand,
}): CancelablePromise<TariffProfileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/tariff-profiles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get tariff profile by id.
     * @returns TariffProfileResponse 
     * @throws ApiError
     */
    public static tariffProfileControllerGetOne({
id,
}: {
id: string,
}): CancelablePromise<TariffProfileResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/tariff-profiles/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Update tariff profile names.
     * Require: `PERMISSION_ADMINISTRATION_VIEW`<br>
 * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
 * + **INVALID_VALID_TO** - Valid to must be bigger than valid from or is in the past.
     * @returns TariffProfileResponse 
     * @throws ApiError
     */
    public static tariffProfileControllerUpdate({
id,
requestBody,
}: {
id: string,
/**
 * UpdateProfileNameCommand
 */
requestBody?: UpdateProfileNameCommand,
}): CancelablePromise<TariffProfileResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/public/tariff-profiles/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Delete tariff profile.
     * If an entity is being used, 409 Conflict will be returned.<br>
 * Require: `PERMISSION_ADMINISTRATION_VIEW`
     * @returns ApiResponse 
     * @throws ApiError
     */
    public static tariffProfileControllerDelete({
id,
}: {
id: string,
}): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/public/tariff-profiles/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                409: `Request couldn't be completed due to a conflict.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get computed validity for the given tariff profile.
     * @returns DateRangeValidityResponse 
     * @throws ApiError
     */
    public static tariffProfileControllerGetComputedValidity({
id,
date,
}: {
id: string,
date: string,
}): CancelablePromise<DateRangeValidityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/tariff-profiles/{id}/computed-validity',
            path: {
                'id': id,
            },
            query: {
                'date': date,
            },
            errors: {
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

}
