/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SaveNameCommand } from './SaveNameCommand';

export type SaveReclamationCommand = {
    subjectTypeName: SaveReclamationCommand.subjectTypeName;
    customerName: SaveNameCommand;
    cardTypeName?: SaveReclamationCommand.cardTypeName;
    /**
     * Card number of Map or Pak card.
     */
    cardNumber?: string;
    /**
     * Customer contact. Email or phone.
     */
    reclaimPersonContact: string;
    /**
     * Device detail. JA/PA/line.
     */
    deviceName?: string;
    /**
     * Datetime when incident was happened. TYPE: 'DATETIME'
     */
    reclamationDate: string;
    description?: string;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
};

export namespace SaveReclamationCommand {

    export enum subjectTypeName {
        TICKET_MACHINE = 'TICKET_MACHINE',
        CHECK_IN_CHECK_OUT = 'CHECK_IN_CHECK_OUT',
        ONLINE_RECHARGE = 'ONLINE_RECHARGE',
        PARKING_METER = 'PARKING_METER',
        CARD = 'CARD',
        MAP_PHONE = 'MAP_PHONE',
        OTHER = 'OTHER',
    }

    export enum cardTypeName {
        MAP_CARD = 'MAP_CARD',
        PAK_CARD = 'PAK_CARD',
        CREDIT_CARD = 'CREDIT_CARD',
        VIRTUAL_CARD = 'VIRTUAL_CARD',
    }


}
