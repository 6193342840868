/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaymentResponse } from '../models/PaymentResponse';
import type { StatusResponse } from '../models/StatusResponse';
import type { UpdatePaymentStatusCommand } from '../models/UpdatePaymentStatusCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentService {

    /**
     * Get a payment status.
     * @returns StatusResponse 
     * @throws ApiError
     */
    public static paymentControllerGetPaymentStatus({
id,
}: {
id: string,
}): CancelablePromise<StatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/payments/{id}/status',
            path: {
                'id': id,
            },
            errors: {
                400: `Validation failed.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Update payment state of order if changed.
     * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
 * + **CUSTOMER_ACCOUNT_NOT_VERIFIED** - Customer account is not verified.<br>
 * + **INVALID_ESHOP_ORDER_STATE** - Eshop order state is invalid or does not exist.
     * @returns StatusResponse 
     * @throws ApiError
     */
    public static paymentControllerCheckPaymentStatus({
id,
requestBody,
}: {
id: string,
/**
 * UpdatePaymentStatusCommand
 */
requestBody?: UpdatePaymentStatusCommand,
}): CancelablePromise<StatusResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/eshop/payments/{id}/check',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Update payment state of order if changed.
     * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
 * + **CUSTOMER_ACCOUNT_NOT_VERIFIED** - Customer account is not verified.<br>
 * + **CANNOT_REVERSE_PAYMENT** - CSOB payment cannot be reversed.
     * @returns PaymentResponse 
     * @throws ApiError
     */
    public static paymentControllerReversePayment({
id,
}: {
id: string,
}): CancelablePromise<PaymentResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/eshop/payments/{id}/reverse',
            path: {
                'id': id,
            },
            errors: {
                400: `Validation failed.`,
                500: `Something went wrong.`,
            },
        });
    }

}
