import { DATE_FORMAT, DATE_PICKER_FORMAT, isDateOneAfterDateTwo, isDateOneSameOrAfterDateTwo } from '@/utils/dateTime';
import i18n from '@/i18n';
import { CustomerProfileDto } from '@/api';
import moment from 'moment';

export const REGULAR_PROFILE_NUMBER = 1;

export const isCustomerProfileValidityValid = (
  customerProfileValidFrom: string | Date,
  customerProfileValidTo?: string | Date,
): string => {
  if (isDateOneAfterDateTwo(customerProfileValidFrom, customerProfileValidTo)) {
    return i18n.global.t('customerProfile.validityErrorMessage');
  } else {
    return '';
  }
};

export const initializeCPValidFrom = (
  profile: CustomerProfileDto | undefined,
  birthDay: string,
  customerVerified: boolean,
): string => {
  if (profile?.ageFrom) {
    let dateForCP = moment(birthDay).add(profile.ageFrom, 'years').format(DATE_FORMAT);

    if (isDateOneAfterDateTwo(new Date(), dateForCP) || !customerVerified) {
      dateForCP = moment().format(DATE_FORMAT);
    }

    return dateForCP;
  }

  return moment().format(DATE_FORMAT);
};
/**
 * ValidFrom and ValidTo must be in profile validity range
 * @param profile
 * @param validFrom
 * @param validTo
 */
export const isProfileValidInDateRange = (
  profile: CustomerProfileDto | undefined,
  validFrom: string | Date,
  validTo?: string | Date,
): string => {
  let valid = true;
  //  profile validity range not defined there is nothing to compare
  if (!profile?.validFrom && !profile?.validTo) {
    return '';
  }

  if (profile?.validFrom && !isDateOneSameOrAfterDateTwo(validFrom, profile.validFrom)) {
    valid = valid && false;
  }

  if (profile?.validTo) {
    // if validTo is not defined use validFrom to compare validity
    const dateToCompare = validTo || validFrom;
    if (!isDateOneSameOrAfterDateTwo(profile.validTo, dateToCompare)) {
      valid = valid && false;
    }
  }

  return valid
    ? ''
    : i18n.global.t('customerProfile.profileIsValidOnlyInDateRange', {
        validFrom: profile?.validFrom
          ? moment(profile.validFrom).format(DATE_PICKER_FORMAT)
          : i18n.global.t('customerProfile.unlimited'),
        validTo: profile?.validTo
          ? moment(profile.validTo).format(DATE_PICKER_FORMAT)
          : i18n.global.t('customerProfile.unlimited'),
      });
};

export const profilesAreInCollision = (
  profileOneValidFrom: string | Date,
  profileTwoValidFrom: string | Date,
  profileOneValidTo?: string | Date,
  profileTwoValidTo?: string | Date,
): boolean => {
  return !(
    // profile two starts after profile one
    (
      isDateOneAfterDateTwo(profileTwoValidFrom, profileOneValidTo || profileOneValidFrom) ||
      // profile one starts after profile two
      isDateOneAfterDateTwo(profileOneValidFrom, profileTwoValidTo || profileTwoValidFrom)
    )
  );
};
