/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiErrorResponse } from '../models/ApiErrorResponse';
import type { AssignCustodianCommand } from '../models/AssignCustodianCommand';
import type { CardApplicationListResponse } from '../models/CardApplicationListResponse';
import type { ConnectAccountCommand } from '../models/ConnectAccountCommand';
import type { CreateCustomerCommand } from '../models/CreateCustomerCommand';
import type { CustomerAccountManagedRelationResponse } from '../models/CustomerAccountManagedRelationResponse';
import type { CustomerCardInfoListResponse } from '../models/CustomerCardInfoListResponse';
import type { CustomerCardResponse } from '../models/CustomerCardResponse';
import type { CustomerDetailResponse } from '../models/CustomerDetailResponse';
import type { CustomerHeaderResponse } from '../models/CustomerHeaderResponse';
import type { CustomerManageableRelationResponse } from '../models/CustomerManageableRelationResponse';
import type { CustomerResponse } from '../models/CustomerResponse';
import type { CustomerTicketFareListResponse } from '../models/CustomerTicketFareListResponse';
import type { ElectronicWalletTransactionResponse } from '../models/ElectronicWalletTransactionResponse';
import type { EshopTransferEWalletFromPakCardCommand } from '../models/EshopTransferEWalletFromPakCardCommand';
import type { EWalletsTransactionListResponse } from '../models/EWalletsTransactionListResponse';
import type { ManagingAccountsCardResponse } from '../models/ManagingAccountsCardResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EshopCustomerService {

    /**
     * Get customer.
     * @returns CustomerDetailResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerGetOne({
id,
}: {
id: string,
}): CancelablePromise<CustomerDetailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customers/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Create a customer.
     * If a request body payload exceeds 5MB, Bad Request error `INVALID_PHOTO_SIZE` is returned.<br>
 * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
 * + **CUSTOMER_NOT_UNIQUE** - Customer name and birthday combination is not unique.<br>
 * + **INVALID_PHOTO_SIZE** - The customer's photo is too big, 120kB is maximum.<br>
 * + **INVALID_CUSTOMER** - Customer does not exist, belongs to another account, is a draft or origin.<br>
 * + **INVALID_ACCOUNT** - Account does not exist or another customer is assigned to this account.<br>
 * + **INVALID_GDPR_OR_TERMS_APPROVAL** - GDPR, terms and conditions must be approved.<br>
 * + **CUSTODIAN_IS_REQUIRED** - Custodian is required for customer under the age of 15.<br>
 * + **CANNOT_CHANGE_BIRTHDAY** - Customer can´t change birthday.
     * @returns CustomerResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerCreate({
requestBody,
}: {
/**
 * CreateCustomerCommand
 */
requestBody?: CreateCustomerCommand,
}): CancelablePromise<CustomerResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/eshop/customers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get active card for the given customer.
     * If customer has no active card, `NOT FOUND` response is returned.
     * @returns CustomerCardResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerGetCustomerActiveCard({
id,
}: {
id: string,
}): CancelablePromise<CustomerCardResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customers/{id}/active-card',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get card info list for the given customer.
     * Field `stateIds` must be send as stateIds[]=value.
     * @returns CustomerCardInfoListResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerGetCustomerCards({
id,
stateIds,
timezoneOffset,
limit,
offset,
sortBy,
sortDesc,
sortLocale,
}: {
id: string,
stateIds?: Array<string>,
timezoneOffset?: number,
limit?: number,
offset?: number,
sortBy?: string,
sortDesc?: boolean,
sortLocale?: string,
}): CancelablePromise<CustomerCardInfoListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customers/{id}/cards',
            path: {
                'id': id,
            },
            query: {
                'stateIds': stateIds,
                'timezoneOffset': timezoneOffset,
                'limit': limit,
                'offset': offset,
                'sortBy': sortBy,
                'sortDesc': sortDesc,
                'sortLocale': sortLocale,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get ticket list for the given customer.
     * @returns CustomerTicketFareListResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerGetCustomerTicketFares({
id,
stateIds,
combinationType,
cardNumber,
timezoneOffset,
limit,
offset,
sortBy,
sortDesc,
sortLocale,
}: {
id: string,
stateIds?: Array<'CANCELLED' | 'EXPIRED' | 'ACTIVE' | 'FUTURE'>,
combinationType?: 'SINGLE' | 'TIME',
cardNumber?: string,
timezoneOffset?: number,
limit?: number,
offset?: number,
sortBy?: string,
sortDesc?: boolean,
sortLocale?: string,
}): CancelablePromise<CustomerTicketFareListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customers/{id}/ticket-fares',
            path: {
                'id': id,
            },
            query: {
                'stateIds': stateIds,
                'combinationType': combinationType,
                'cardNumber': cardNumber,
                'timezoneOffset': timezoneOffset,
                'limit': limit,
                'offset': offset,
                'sortBy': sortBy,
                'sortDesc': sortDesc,
                'sortLocale': sortLocale,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get e-wallets transaction list for the given customer.
     * @returns EWalletsTransactionListResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerGetCustomerEWalletsTransactions({
id,
operations,
paymentTypes,
cardNumber,
chipNumber,
timezoneOffset,
limit,
offset,
sortBy,
sortDesc,
sortLocale,
date,
}: {
id: string,
operations?: Array<'RECHARGE' | 'WITHDRAW' | 'PAYMENT' | 'TRANSFER' | 'PAK_TRANSFER'>,
paymentTypes?: Array<'CASH' | 'BANK_CARD' | 'ELECTRONIC_WALLET' | 'INVOICE'>,
cardNumber?: string,
chipNumber?: string,
timezoneOffset?: number,
limit?: number,
offset?: number,
sortBy?: string,
sortDesc?: boolean,
sortLocale?: string,
date?: string,
}): CancelablePromise<EWalletsTransactionListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customers/{id}/e-wallets-transactions',
            path: {
                'id': id,
            },
            query: {
                'operations': operations,
                'paymentTypes': paymentTypes,
                'cardNumber': cardNumber,
                'chipNumber': chipNumber,
                'timezoneOffset': timezoneOffset,
                'limit': limit,
                'offset': offset,
                'sortBy': sortBy,
                'sortDesc': sortDesc,
                'sortLocale': sortLocale,
                'date': date,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get card application list for the given customer.
     * Field `stateIds` must be send as stateIds[]=value.
     * @returns CardApplicationListResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerGetCardApplications({
id,
applicationNumber,
serialNumber,
eshop,
carrierTypeId,
forceShowUnpaid,
customerName,
username,
cardApplicationStateId,
dateCreated,
timezoneOffset,
limit,
offset,
sortBy,
sortDesc,
sortLocale,
}: {
id: string,
applicationNumber?: string,
serialNumber?: string,
eshop?: boolean,
carrierTypeId?: string,
forceShowUnpaid?: boolean,
customerName?: string,
username?: string,
cardApplicationStateId?: string,
dateCreated?: string,
timezoneOffset?: number,
limit?: number,
offset?: number,
sortBy?: string,
sortDesc?: boolean,
sortLocale?: string,
}): CancelablePromise<CardApplicationListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customers/{id}/card-applications',
            path: {
                'id': id,
            },
            query: {
                'applicationNumber': applicationNumber,
                'serialNumber': serialNumber,
                'eshop': eshop,
                'carrierTypeId': carrierTypeId,
                'forceShowUnpaid': forceShowUnpaid,
                'customerName': customerName,
                'username': username,
                'cardApplicationStateId': cardApplicationStateId,
                'dateCreated': dateCreated,
                'timezoneOffset': timezoneOffset,
                'limit': limit,
                'offset': offset,
                'sortBy': sortBy,
                'sortDesc': sortDesc,
                'sortLocale': sortLocale,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get active card application. State: UNCHECKED or WAITING TO PRINT
     * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **CUSTOMER_ALREADY_HAS_ACTIVE_CARD_APPLICATION** - Customer already has active card application.
     * @returns ApiErrorResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerGetActiveCardApplication({
id,
}: {
id: string,
}): CancelablePromise<ApiErrorResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customers/{id}/active-card-application',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Returns an error if there is an ongoing card-application or ongoing virtual-card-application. Also returns an error if the customer has a card in_progress or not_issued.
     * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **CUSTOMER_ALREADY_HAS_ACTIVE_CARD_APPLICATION** - Customer already has active card application.<br>
 * + **CUSTOMER_HAS_CARD_TO_BE_ISSUED** - The customer has a card in state "IN_PROGRESS" or "NOT_ISSUED".
     * @returns ApiErrorResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerCheckCreationOfVirtualCardApplication({
id,
}: {
id: string,
}): CancelablePromise<ApiErrorResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customers/{id}/virtual-card-application-creation-check',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Check if combination of first name, last name and birthday is unique.
     * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **CUSTOMER_NOT_UNIQUE** - Customer name and birthday combination is not unique.<br>
 * + **INVALID_ACCOUNT** - Account does not exist or another customer is assigned to this account.
     * @returns ApiErrorResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerVerifyIfCustomerIsUnique({
firstName,
lastName,
birthDay,
excludedCustomerIds,
}: {
firstName: string,
lastName: string,
birthDay: string,
excludedCustomerIds?: Array<any>,
}): CancelablePromise<ApiErrorResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customers-check',
            query: {
                'firstName': firstName,
                'lastName': lastName,
                'birthDay': birthDay,
                'excludedCustomerIds': excludedCustomerIds,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get last card for the given customer.
     * If customer has no last card, `NOT FOUND` response is returned.
     * @returns CustomerCardResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerGetCustomerLastActiveCard({
id,
}: {
id: string,
}): CancelablePromise<CustomerCardResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customers/{id}/last-active-card',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * List of accounts managing customer card.
     * @returns ManagingAccountsCardResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerGetAccountsManagingCard({
limit,
offset,
sortBy,
sortDesc,
sortLocale,
timezoneOffset,
}: {
limit?: number,
offset?: number,
sortBy?: string,
sortDesc?: boolean,
sortLocale?: string,
timezoneOffset?: number,
}): CancelablePromise<ManagingAccountsCardResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customers-managing-accounts-card',
            query: {
                'limit': limit,
                'offset': offset,
                'sortBy': sortBy,
                'sortDesc': sortDesc,
                'sortLocale': sortLocale,
                'timezoneOffset': timezoneOffset,
            },
            errors: {
                401: `User wasn't authenticated.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Connect customer with account as REGISTERED based on card number and application number.
     * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **INVALID_CUSTOMER_CARD_AND_CARD_APPLICATION** - Invalid customer card and card application combination.<br>
 * + **ACCOUNT_ALREADY_HAS_REGISTERED_CUSTOMER** - Account already has registered customer.<br>
 * + **CUSTOMER_ALREADY_HAS_REGISTERED_ACCOUNT** - Customer already has a registered account.<br>
 * + **CUSTOMER_TOO_YOUNG_TO_CREATE_REGISTERED_ACCOUNT** - Customer is not able register to eshop account (too young).<br>
 * + **INVALID_CARD_APPLICATION_USERNAME** - Invalid card application username.<br>
 * + **INVALID_CUSTOMER_CARD** - Customer card does not exist or has invalid card state.
     * @returns CustomerResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerConnectRegisteredAccount({
requestBody,
}: {
/**
 * ConnectAccountCommand
 */
requestBody?: ConnectAccountCommand,
}): CancelablePromise<CustomerResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/eshop/customers-connect-registered-account',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Connect customer with account as ASSIGNED based on card number and application number.
     * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **INVALID_CUSTOMER_CARD_AND_CARD_APPLICATION** - Invalid customer card and card application combination.<br>
 * + **CANNOT_ASSIGN_CUSTOMER_WITHOUT_PARENT_TO_ACCOUNT** - Can not assign customer without parent to account.<br>
 * + **CUSTOMER_ALREADY_HAS_ASSIGNED_ACCOUNT** - Customer already has a assigned account.<br>
 * + **CUSTOMER_TOO_OLD_TO_CREATE_ASSIGNED_ACCOUNT** - Customer is not able assign to eshop account (too old).<br>
 * + **CUSTOMER_PARENT_AND_ACCOUNT_OWNER_ARE_DIFFERENT** - Customer parent and account owner are different.
     * @returns CustomerResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerConnectAssignedAccount({
requestBody,
}: {
/**
 * ConnectAccountCommand
 */
requestBody?: ConnectAccountCommand,
}): CancelablePromise<CustomerResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/eshop/customers-connect-assigned-account',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Transfer electronic wallet from PaK to MAP card for the eshop customer.
     * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **INVALID_CARD_STATE** - Card state does not exist or is invalid.<br>
 * + **PAK_WALLET_NEGATIVE_AMOUNT** - PaK electronic wallet has negative current amount.<br>
 * + **PAK_WALLET_ALREADY_TRANSFERRED** - PaK electronic wallet has already been transferred.<br>
 * + **PAK_WALLET_INCORRECT_NAME_OR_BIRTHDAY** - PaK electronic wallet and MAP card do not have the same customer's name or birthday.<br>
 * + **INVALID_CUSTOMER_CARD** - Customer card does not exist or has invalid card state.
     * @returns ElectronicWalletTransactionResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerEWalletPakTransfer({
requestBody,
}: {
/**
 * EshopTransferEWalletFromPakCardCommand
 */
requestBody?: EshopTransferEWalletFromPakCardCommand,
}): CancelablePromise<ElectronicWalletTransactionResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/eshop/customers-pak-transfer/wallet',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get a customer info for the logged in account.
     * Returns null in "data" if there is no registered customer for that account.
     * @returns CustomerHeaderResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerGetLoggedCustomer(): CancelablePromise<CustomerHeaderResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customers-logged-customer',
            errors: {
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Check if account has only managed relations with customer.
     * @returns CustomerAccountManagedRelationResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerCheckRegisteredCustomer(): CancelablePromise<CustomerAccountManagedRelationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customers-check-only-managed-relations',
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Check customer can be managed.
     * Customer must be registered or child and has only one active card.
     * @returns CustomerManageableRelationResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerIsCustomerManageable({
id,
}: {
id: string,
}): CancelablePromise<CustomerManageableRelationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customers/{id}/check-manageable',
            path: {
                'id': id,
            },
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Assign customer custodian.
     * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.
     * @returns CustomerResponse 
     * @throws ApiError
     */
    public static eshopCustomerControllerSetCustomerCustodian({
id,
requestBody,
}: {
id: string,
/**
 * AssignCustodianCommand
 */
requestBody?: AssignCustodianCommand,
}): CancelablePromise<CustomerResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/eshop/customers/{id}/assign-custodian',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

}
