/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerAccountNotificationListResponse } from '../models/CustomerAccountNotificationListResponse';
import type { CustomerAccountNotificationResponse } from '../models/CustomerAccountNotificationResponse';
import type { UpdateCustomerAccountNotificationCommand } from '../models/UpdateCustomerAccountNotificationCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerAccountNotificationService {

    /**
     * Get customer account notifications
     * @returns CustomerAccountNotificationListResponse 
     * @throws ApiError
     */
    public static customerAccountNotificationControllerGetCustomerAccountNotifications({
customerAccountId,
}: {
customerAccountId: string,
}): CancelablePromise<CustomerAccountNotificationListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customer-account-notifications',
            query: {
                'customerAccountId': customerAccountId,
            },
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Update customer account notifications
     * @returns CustomerAccountNotificationResponse 
     * @throws ApiError
     */
    public static customerAccountNotificationControllerUpdateCustomerAccountNotification({
id,
requestBody,
}: {
id: string,
/**
 * UpdateCustomerAccountNotificationCommand
 */
requestBody?: UpdateCustomerAccountNotificationCommand,
}): CancelablePromise<CustomerAccountNotificationResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/eshop/customer-account-notifications/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                500: `Something went wrong.`,
            },
        });
    }

}
