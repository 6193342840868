import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import UserAccountPage from '@/pages/UserAccountPage.vue';
import Paths, { LOGIN_REQUIRED_PATHS } from '@/constants/Paths';
import PageTitleI18nKeys from '@/constants/PageTitleI18nKeys';
import AppLayout from '@/layouts/AppLayout.vue';
import TicketFarePage from '@/pages/TicketFarePage.vue';
import ElectronicWalletPage from '@/pages/ElectronicWalletPage.vue';
import ShoppingCartPage from '@/pages/ShoppingCartPage.vue';
import LoginPage from '@/pages/LoginPage.vue';
import AuthModule from '@/store/modules/auth';
import CustomerModule from '@/store/modules/customer';
import RegistrationPage from '@/pages/RegistrationPage.vue';
import ForgottenPasswordPage from '@/pages/ForgottenPasswordPage.vue';
import VerificationCodePage from '@/pages/VerificationCodePage.vue';
import ChangePasswordPage from '@/pages/ChangePasswordPage.vue';
import VerifyPage from '@/pages/VerifyPage.vue';
import moment from 'moment';
import OrderListPage from '@/pages/OrderListPage.vue';
import AssignedCardListPage from '@/pages/AssignedCardListPage.vue';
import UserLinkedAccountPage from '@/pages/UserLinkedAccountPage.vue';
import ManagedCardListPage from '@/pages/ManagedCardListPage.vue';
import CardManagingAccountListPage from '@/pages/CardManagingAccountListPage.vue';
import ApproveManagedCardPage from '@/pages/ApproveManagedCardPage.vue';
import RejectCardManageRequestPage from '@/pages/RejectCardManageRequestPage.vue';
import NotificationSettingPage from '@/pages/NotificationSettingPage.vue';
import { MODULE } from '@/constants/Module';
import { loadLocaleMessages } from '@/utils/i18n';
import { showModule } from '@/utils/module';
import isDefined from '@common/utils/isDefined';

const routes = [
  {
    path: Paths.LOGIN,
    component: LoginPage,
    meta: { titleI18nKey: PageTitleI18nKeys.LOGIN },
  },
  {
    path: Paths.REGISTRATION,
    component: RegistrationPage,
    meta: { titleI18nKey: PageTitleI18nKeys.REGISTRATION },
  },
  {
    path: Paths.FORGOTTEN_PASSWORD,
    component: ForgottenPasswordPage,
    meta: { titleI18nKey: PageTitleI18nKeys.FORGOTTEN_PASSWORD },
  },
  {
    path: Paths.VERIFICATION,
    component: VerificationCodePage,
    meta: { titleI18nKey: PageTitleI18nKeys.VERIFICATION },
  },
  {
    path: Paths.CHANGE_PASSWORD,
    component: ChangePasswordPage,
    meta: { titleI18nKey: PageTitleI18nKeys.CHANGE_PASSWORD },
  },
  {
    path: Paths.VERIFY,
    component: VerifyPage,
    meta: { titleI18nKey: PageTitleI18nKeys.VERIFY },
  },
  {
    path: Paths.APPROVE_MANAGED_CARD,
    component: ApproveManagedCardPage,
    meta: { titleI18nKey: PageTitleI18nKeys.APPROVE_MANAGED_CARD },
  },
  {
    path: Paths.REJECT_CARD_MANAGE_REQUEST_PAGE,
    component: RejectCardManageRequestPage,
    meta: { titleI18nKey: PageTitleI18nKeys.REJECT_CARD_MANAGE_REQUEST },
  },
  {
    path: Paths.APP,
    component: AppLayout,
    children: [
      {
        path: Paths.USER_ACCOUNT,
        component: UserAccountPage,
        meta: { titleI18nKey: PageTitleI18nKeys.USER_ACCOUNT },
      },
      {
        path: Paths.USER_LINKED_ACCOUNT,
        component: UserLinkedAccountPage,
        // title is set dynamically in AppLayout.vue
      },
      {
        path: Paths.ASSIGNED_CARD_LIST,
        component: AssignedCardListPage,
        meta: {
          titleI18nKey: PageTitleI18nKeys.ASSIGNED_CARD_LIST,
          canLoad: showModule(MODULE.ASSIGNED_CARD_LIST),
        },
      },
      {
        path: Paths.MANAGED_CARD_LIST,
        component: ManagedCardListPage,
        meta: {
          titleI18nKey: PageTitleI18nKeys.MANAGED_CARD_LIST,
          canLoad: showModule(MODULE.MANAGED_CARD_LIST),
        },
      },
      {
        path: Paths.CARD_MANAGING_ACCOUNT_LIST,
        component: CardManagingAccountListPage,
        meta: {
          titleI18nKey: PageTitleI18nKeys.CARD_MANAGING_ACCOUNT_LIST,
          canLoad: showModule(MODULE.CARD_MANAGING_ACCOUNT_LIST),
        },
      },
      {
        path: Paths.NOTIFICATION_SETTING,
        component: NotificationSettingPage,
        meta: {
          titleI18nKey: PageTitleI18nKeys.NOTIFICATION_SETTING,
          canLoad: showModule(MODULE.NOTIFICATION_SETTING),
        },
      },
      {
        path: Paths.TICKET_FARE,
        component: TicketFarePage,
        meta: {
          titleI18nKey: PageTitleI18nKeys.TICKET_FARE,
          canLoad: showModule(MODULE.TICKET_FARE),
        },
      },
      {
        path: Paths.ELECTRONIC_WALLET,
        component: ElectronicWalletPage,
        meta: {
          titleI18nKey: PageTitleI18nKeys.ELECTRONIC_WALLET,
          canLoad: showModule(MODULE.ELECTRONIC_WALLET),
        },
      },
      {
        path: Paths.SHOPPING_CART,
        component: ShoppingCartPage,
        meta: { titleI18nKey: PageTitleI18nKeys.SHOPPING_CART },
      },
      {
        path: Paths.ORDER_LIST,
        component: OrderListPage,
        meta: { titleI18nKey: PageTitleI18nKeys.ORDER_LIST },
      },
      // FIXME when sub-account will be implemented
      // {
      //   path: Paths.ACCOUNT_SETTING,
      //   component: AccountSettingPage,
      //   meta: { titleI18nKey: PageTitleI18nKeys.ACCOUNT_SETTING },
      // },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes as RouteRecordRaw[],
});

let whenLocaleMessagesLoaded: Promise<void> | null = null;

router.beforeEach((_to, _from, next) => {
  if (!whenLocaleMessagesLoaded) {
    whenLocaleMessagesLoaded = loadLocaleMessages();
  }

  whenLocaleMessagesLoaded.then(
    () => {
      next();
    },
    error => {
      next(error);
    },
  );
});

router.beforeEach(async (to, _from, next) => {
  const userLogged = AuthModule.logged;

  if (AuthModule.tokenExpiresIn && moment(AuthModule.tokenExpiresIn).isBefore(moment())) {
    AuthModule.logout();
  } else if (userLogged && to.matched[0].path === '') {
    return next(Paths.USER_ACCOUNT);
  }

  if (LOGIN_REQUIRED_PATHS.includes(to.matched[0].path) && !userLogged) {
    return next({
      path: Paths.LOGIN,
      query: { redirectUrl: to.path, token: to.query?.token },
    });
  }

  if (to.matched[0].path === Paths.APP && userLogged && isDefined(to.meta?.canLoad) && !to.meta.canLoad) {
    return next({
      path: Paths.USER_ACCOUNT,
    });
  }

  if (to.path !== Paths.USER_LINKED_ACCOUNT) {
    CustomerModule.clearLinkedCustomer();
  }

  // if there are query parameters before '/#' move them to the end
  if (window.location.search) {
    window.location.replace(`/#${to.fullPath}${window.location.search}`);
  }

  next();
});

export default router;
