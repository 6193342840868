export const SERVER_URL: string | undefined = import.meta.env.VITE_APP_SERVER_URL;

export const PAYMENT_CSOB_GATEWAY_BASE_URL: string | undefined = import.meta.env.VITE_APP_PAYMENT_CSOB_GATEWAY_BASE_URL;

export const PAYMENT_GP_GATEWAY_BASE_URL: string =
  import.meta.env.VITE_APP_PAYMENT_GP_GATEWAY_BASE_URL || 'https://iapi.iplatebnibrana.csob.cz/api/v1.8';

// see https://vuetifyjs.com/en/features/breakpoints/ and vuetify.ts
export const DISPLAY_BREAKPOINT_XS: number | undefined = import.meta.env.VITE_APP_DISPLAY_BREAKPOINT_XS
  ? parseInt(import.meta.env.VITE_APP_DISPLAY_BREAKPOINT_XS, 10)
  : 1200;

export const SENTRY_URL: string | undefined = import.meta.env.VITE_APP_SENTRY_URL;
export const SENTRY_ENV: string | undefined = import.meta.env.VITE_APP_SENTRY_ENV;
export const MODE_PRODUCTION = import.meta.env.NODE_ENV === 'production';

export const TERMS_AND_CONDITIONS_URL = {
  REGISTER: import.meta.env.VITE_APP_REGISTER_TERMS_AND_CONDITIONS || 'about:blank',
  CARD_APPLICATION: import.meta.env.VITE_APP_CARD_APPLICATION_TERMS_AND_CONDITIONS || 'about:blank',
};

export const DECLARATION_OF_ACCESSIBILITY: string | undefined = import.meta.env.VITE_APP_DECLARATION_OF_ACCESSIBILITY;

export const CONTACTS_URL: string | undefined = import.meta.env.VITE_APP_CONTACTS;

export const IS_PRODUCTION: boolean = import.meta.env.VITE_APP_IS_PRODUCTION
  ? JSON.parse(import.meta.env.VITE_APP_IS_PRODUCTION)
  : false;

export const VIRTUAL_CARD_APPLICATION_ENABLED: boolean = import.meta.env.VITE_APP_VIRTUAL_CARD_APPLICATION_ENABLED
  ? JSON.parse(import.meta.env.VITE_APP_VIRTUAL_CARD_APPLICATION_ENABLED)
  : false;

export const VIRTUAL_CARD_APPLICATION_TEST_BTN_ENABLED: boolean = import.meta.env
  .VITE_APP_VIRTUAL_CARD_APPLICATION_TEST_BTN_ENABLED
  ? JSON.parse(import.meta.env.VITE_APP_VIRTUAL_CARD_APPLICATION_TEST_BTN_ENABLED)
  : false;

export const GDPR_URL = {
  REGISTER: import.meta.env.VITE_APP_REGISTER_GDPR || 'about:blank',
  CARD_APPLICATION: import.meta.env.VITE_APP_CARD_APPLICATION_GDPR || 'about:blank',
};

export const FAQ_URL: string | undefined = import.meta.env.VITE_APP_FAQ;

export const HAULIER_NAME = import.meta.env.VITE_APP_HAULIER_NAME || 'dpmp';

export const HIDDEN_MODULES = import.meta.env.VITE_APP_HIDDEN_MODULES
  ? import.meta.env.VITE_APP_HIDDEN_MODULES.split(',')
  : [];
export const MIN_CUSTOMER_AGE = import.meta.env.VITE_APP_MIN_CUSTOMER_AGE
  ? parseInt(import.meta.env.VITE_APP_MIN_CUSTOMER_AGE, 10)
  : 15;

export const PAK_ALLOWED: boolean = import.meta.env.VITE_APP_PAK_ALLOWED
  ? JSON.parse(import.meta.env.VITE_APP_PAK_ALLOWED)
  : false;

export const DEFAULT_LOCALE = import.meta.env.VITE_APP_I18N_LOCALE || 'cs';
export const FALLBACK_LOCALE = import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'cs';

export const TICKET_FARE_MIN_VALID_FROM = import.meta.env.VITE_APP_TICKET_FARE_MIN_VALID_FROM;

export const CARD_NUMBER_MASK = import.meta.env.VITE_APP_CARD_NUMBER_MASK;

export const COMPANY_TZ = import.meta.env.VITE_APP_COMPANY_TZ || 'Europe/Prague';
