<template>
  <v-card width="100%" class="fill-height pa-4">
    <v-alert border="top" type="info" elevation="2" class="mb-4" data-cy="notification-setting-alert" variant="text">
      {{
        $t('notificationSetting.infoCard', {
          cardMinDaysInAdvance,
          cardMaxDaysInAdvance,
        })
      }}
    </v-alert>
    <v-alert border="top" type="info" elevation="2" class="mb-4" data-cy="notification-setting-alert" variant="text">
      {{
        $t('notificationSetting.infoTicket', {
          ticketMinDaysInAdvance,
          ticketMaxDaysInAdvance,
        })
      }}
    </v-alert>
    <v-card class="pb-2">
      <v-card-title class="break-word d-flex dialog-title">
        <div class="align-self-center">{{ $t('notificationSetting.emailNotificationsTitle') }}</div>
      </v-card-title>

      <v-form
        v-for="(notificationSetting, i) of notificationSettings"
        :key="i"
        :ref="`form${notificationSetting.type.name}`"
        class="px-1"
      >
        <div class="d-flex align-center">
          <v-checkbox
            v-model="notificationSetting.email"
            hide-details
            class="flex-grow-0 mr-2"
            :data-cy="`notification-setting-active-${notificationSetting.id}`"
            @update:model-value="saveForm(notificationSetting)"
          />
          <div class="d-flex align-center">
            <div>
              {{ $t(notificationTypeI18nKeys[notificationSetting.type.name]) }}
            </div>

            <v-text-field
              v-model="notificationSetting.daysInAdvance"
              class="days-in-advance"
              density="compact"
              variant="underlined"
              hide-details
              :rules="validations[notificationSetting.type.name]"
              type="number"
              :min="0"
              :data-cy="`notification-setting-input-${notificationSetting.id}`"
              :disabled="!notificationSetting.email"
              @keypress="allowOnlyNumeric"
              @update:model-value="validateDaysInAdvance(notificationSetting)"
              @change="saveForm(notificationSetting)"
            />
          </div>
        </div>
      </v-form>
    </v-card>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { CustomerAccountNotificationDto } from '@/api';
import notificationService from '@/services/api/notificationService';
import AuthModule from '@/store/modules/auth';
import KeyboardEventHelper from '@common/utils/keyboardEventHelper';
import { NotificationTypeI18nKeys, NotificationTypes } from '@/constants/NotificationTypes';
import { maxValue, minValue, requiredValidator } from '@/utils/validators';
import ToastModule from '@/store/modules/toast';

type VForm = [typeof Vue & { validate: () => boolean }];

const GLOBAL_SETTING_MIN_TICKET_DAYS_IN_ADVANCE_VALUE = 1;
const GLOBAL_SETTING_MAX_TICKET_DAYS_IN_ADVANCE_VALUE = 10;
const GLOBAL_SETTING_MIN_CARD_DAYS_IN_ADVANCE_VALUE = 30;
const GLOBAL_SETTING_MAX_CARD_DAYS_IN_ADVANCE_VALUE = 90;
@Component
export default class NotificationSettingPage extends Vue {
  authModule = AuthModule;
  notificationTypeI18nKeys = NotificationTypeI18nKeys;
  notificationSettings: CustomerAccountNotificationDto[] = [];
  allowOnlyNumeric = KeyboardEventHelper.allowOnlyNumeric;
  ticketMinDaysInAdvance = GLOBAL_SETTING_MIN_TICKET_DAYS_IN_ADVANCE_VALUE;
  ticketMaxDaysInAdvance = GLOBAL_SETTING_MAX_TICKET_DAYS_IN_ADVANCE_VALUE;
  cardMinDaysInAdvance = GLOBAL_SETTING_MIN_CARD_DAYS_IN_ADVANCE_VALUE;
  cardMaxDaysInAdvance = GLOBAL_SETTING_MAX_CARD_DAYS_IN_ADVANCE_VALUE;
  validations = {
    [NotificationTypes.CARD_EXPIRATION]: [
      (v: string) => requiredValidator(v),
      (v: string) => minValue(v, GLOBAL_SETTING_MIN_CARD_DAYS_IN_ADVANCE_VALUE),
      (v: string) => maxValue(v, GLOBAL_SETTING_MAX_CARD_DAYS_IN_ADVANCE_VALUE),
    ],
    [NotificationTypes.TICKET_EXPIRATION]: [
      (v: string) => requiredValidator(v),
      (v: string) => minValue(v, GLOBAL_SETTING_MIN_TICKET_DAYS_IN_ADVANCE_VALUE),
      (v: string) => maxValue(v, GLOBAL_SETTING_MAX_TICKET_DAYS_IN_ADVANCE_VALUE),
    ],
  };

  async mounted() {
    try {
      this.notificationSettings = await notificationService.getNotificationSetting({
        customerAccountId: this.authModule.customerAccountId,
      });
    } catch (e) {
      ToastModule.error({
        message: this.$t('notificationSetting.loadingError'),
      });
    }
  }

  validateForm(notificationSetting: CustomerAccountNotificationDto) {
    return (this.$refs[`form${notificationSetting.type.name}`] as VForm)[0].validate();
  }

  async saveForm(notificationSetting: CustomerAccountNotificationDto) {
    if (this.validateForm(notificationSetting)) {
      const { id, email } = notificationSetting;
      let { daysInAdvance } = notificationSetting;
      daysInAdvance = daysInAdvance ? parseInt(daysInAdvance.toString()) : 0;
      try {
        await notificationService.updateNotification(id, {
          // daysInAdvance is number type but value from input is string
          daysInAdvance: parseInt(daysInAdvance.toString()),
          email,
          sms: false,
        });
        ToastModule.success({
          message: this.$t('notificationSetting.saveSuccess'),
        });
      } catch (e) {
        ToastModule.error({
          message: this.$t('notificationSetting.savingError'),
        });
      }
    }
  }

  validateDaysInAdvance(notificationSetting: CustomerAccountNotificationDto) {
    if (!this.validateForm(notificationSetting)) {
      ToastModule.error({
        message: this.$t('notificationSetting.invalidDaysInAdvance'),
      });
    }
  }
}
</script>
<style scoped lang="scss">
.days-in-advance :deep(input) {
  max-width: 40px;
  min-width: 40px;
  display: flex;
  margin: -10px 15px 0px 15px;
}

.break-word {
  white-space: unset;
  hyphens: none;
}
</style>
