/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TicketFareMetadataVm = {
    /**
     * Ticket fare identifier. Type: 'UUID'
     */
    id: string;
    /**
     * Ticket number.
     */
    ticketNumber: number;
    cardNumber: string;
    /**
     * DateTime when ticket issued. Type: 'DATETIME'
     */
    issued: string;
    /**
     * Profile valid from date. Type: 'DATE'
     */
    validFrom: string;
    /**
     * Profile valid to date. Type: 'DATE'
     */
    validTo: string;
    /**
     * Ticket price. Value is in cents.
     */
    price: number;
    zone: TicketFareMetadataVm.zone;
    customerProfileNumber: number;
    tariffProfileNumber: number;
    cancellation: boolean;
};

export namespace TicketFareMetadataVm {

    export enum zone {
        ZONE_I = 'ZONE-I',
        ZONE_I_II = 'ZONE-I+II',
        ZONE_II = 'ZONE-II',
    }


}
