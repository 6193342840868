import moment from 'moment';
import { DATE_FORMAT, getDateAfter, isDateOneAfterDateTwo } from '@/utils/dateTime';
import { CustomerProfileDto, EnumsDto } from '@/api';
import isDefined from '@common/utils/isDefined';

const getNextDate = (day: number, month: number, year: number) => {
  const today = moment().set({ year: year });
  const givenDate = moment().set({ month: month - 1, date: day });
  if (today.isAfter(givenDate)) {
    givenDate.set('year', today.year() + 1);
  }
  return givenDate.format(DATE_FORMAT);
};

export const computeValidTo = (
  customerProfile: CustomerProfileDto | undefined,
  birthday: string,
  currentYear: number,
  validFrom?: string,
): string => {
  let validTo = '';
  switch (customerProfile?.validToDefault?.name) {
    case EnumsDto.customerProfileValidToDefaults.END_OF_YEAR:
      validTo = moment().set({ year: currentYear }).endOf('year').format(DATE_FORMAT);
      break;
    case EnumsDto.customerProfileValidToDefaults.END_OF_JANUARY:
      validTo = getNextDate(31, 1, currentYear);
      break;
    case EnumsDto.customerProfileValidToDefaults.END_OF_AUGUST:
      validTo = getNextDate(31, 8, currentYear);
      break;
    case EnumsDto.customerProfileValidToDefaults.END_OF_SEPTEMBER:
      validTo = getNextDate(30, 9, currentYear);
      break;
    case EnumsDto.customerProfileValidToDefaults.TO_AGE: {
      validTo = moment(birthday)
        .add(customerProfile?.ageTo, 'years')
        // Add extra year to ensure setting last day of the given age.
        .add(1, 'years')
        .subtract(1, 'day')
        .format(DATE_FORMAT);
      break;
    }
    case EnumsDto.customerProfileValidToDefaults._365_DAYS_AFTER_CONFIRMATION_DELIVERY: {
      // add only 364 days, today is the last day
      validTo = moment(validFrom || undefined)
        .add(364, 'days')
        .format(DATE_FORMAT);
      break;
    }
    default:
      validTo = '';
      break;
  }

  if (
    isDefined(validTo) &&
    isDefined(customerProfile?.validToDefault?.name) &&
    isDefined(customerProfile?.minProfileDaysValidity)
  ) {
    const minProfileDaysValidity = customerProfile?.minProfileDaysValidity || 0;
    const minDateValidTo = getDateAfter(new Date(), minProfileDaysValidity, 'days');
    if (isDateOneAfterDateTwo(minDateValidTo, validTo)) {
      const newYear = currentYear + 1;
      return computeValidTo(customerProfile, birthday, newYear, validFrom);
    }
  }

  return validTo;
};

export const isProfileReduced = (profile?: CustomerProfileDto): boolean => {
  return !!(profile && profile.reduced);
};

export const shouldSetValidTo = (profile?: CustomerProfileDto): boolean => {
  // Show date picker when default is not defined or is not CARD_VALIDITY.
  return (
    (profile && !isDefined(profile?.validToDefault?.name)) ||
    profile?.validToDefault?.name !== EnumsDto.customerProfileValidToDefaults.CARD_VALIDITY
  );
};

export const requireConfirmationCheck = (profile?: CustomerProfileDto): boolean => {
  return !!profile?.requireConfirmation;
};
