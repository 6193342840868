/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateLocalizationCommand } from './CreateLocalizationCommand';

export type CreateTariffProfileCommand = {
    name: string;
    abbreviation: string;
    profileNumber: number;
    /**
     * Profile valid from date. Type: 'DATE'
     */
    validFrom?: string;
    /**
     * Profile valid to date. Type: 'DATE'
     */
    validTo?: string;
    orderNumber: number;
    /**
     * Tariff profile type. SLIDING, CALENDAR, FIXED
     */
    type: CreateTariffProfileCommand.type;
    /**
     * Quantity of period. Used when type: SLIDING, CALENDAR
     */
    periodQuantity?: number;
    /**
     * Tariff profile period. Day, month, year, ... Used when type: SLIDING, CALENDAR
     */
    period?: CreateTariffProfileCommand.period;
    /**
     * Day start of tariff profile when FIXED type.
     */
    fixedValidFromDay?: number;
    /**
     * Month start of tariff profile when FIXED type.
     */
    fixedValidFromMonth?: number;
    /**
     * Day end of tariff profile when FIXED type.
     */
    fixedValidToDay?: number;
    /**
     * Month end of tariff profile when FIXED type.
     */
    fixedValidToMonth?: number;
    /**
     * Tariff profile identifier. Used for copying. Type: 'UUID'
     */
    oldId?: string;
    /**
     * Night transport when SINGLE type.
     */
    nightTransport?: boolean;
    /**
     * Up to three stops when SINGLE type.
     */
    upToThreeStops?: boolean;
    /**
     * Transfer ride when SINGLE type.
     */
    transfer?: boolean;
    /**
     * Coefficient for refund.
     */
    refundCoefficient?: number;
    localizations: Array<CreateLocalizationCommand>;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
};

export namespace CreateTariffProfileCommand {

    /**
     * Tariff profile type. SLIDING, CALENDAR, FIXED
     */
    export enum type {
        SLIDING = 'SLIDING',
        CALENDAR = 'CALENDAR',
        FIXED = 'FIXED',
        SINGLE = 'SINGLE',
    }

    /**
     * Tariff profile period. Day, month, year, ... Used when type: SLIDING, CALENDAR
     */
    export enum period {
        DAY = 'DAY',
        MONTH = 'MONTH',
        YEAR = 'YEAR',
        HALF_YEAR = 'HALF_YEAR',
        MINUTE = 'MINUTE',
    }


}
