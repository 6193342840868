import { Action, Mutation, VuexModule, Module, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { getTimeoutForToast } from '@/utils/toast';
import { PERMANENT_TOAST_INTERVAL } from '@/constants/Toast.ts';

export interface ToastOptions {
  x?: string;
  y?: string;
  color?: string;
  icon?: string;
  iconColor?: string;
  classes?: string | object | Array<object | string>;
  timeout?: number;
  dismissable?: boolean;
  multiLine?: boolean;
  vertical?: boolean;
  showClose?: boolean;
  closeText?: string;
  closeIcon?: string;
  closeColor?: string;
  queueable?: boolean;
}

export interface Toast {
  message: string;
  options?: ToastOptions;
}

@Module({ dynamic: true, store, namespaced: true, name: 'toast' })
class ToastModule extends VuexModule {
  public toasts: Toast[] = [];

  @Mutation
  public create(toast: Toast): void {
    if (!toast.options?.timeout) {
      toast.options = {
        ...toast.options,
        timeout: getTimeoutForToast(toast.message),
      };
    }

    if (toast.options?.timeout === PERMANENT_TOAST_INTERVAL) {
      toast.options = {
        ...toast.options,
        showClose: true,
      };
    }

    if (toast.options?.queueable) {
      this.toasts.push(toast);
    } else {
      this.toasts = [toast];
    }
  }

  @Action
  public success(params: { message: string; options?: ToastOptions }): void {
    const opt = params.options ? { ...params.options, color: 'success' } : { color: 'success' };

    this.context.commit('create', {
      message: params.message,
      options: opt,
    });
  }

  @Action
  public error(params: { message: string; options?: ToastOptions }): void {
    const opt = params.options ? { ...params.options, color: 'error' } : { color: 'error' };

    this.context.commit('create', {
      message: params.message,
      options: opt,
    });
  }

  @Action
  public info(params: { message: string; options?: ToastOptions }): void {
    const opt = params.options ? { ...params.options, color: 'info' } : { color: 'info' };

    this.context.commit('create', {
      message: params.message,
      options: opt,
    });
  }

  @Mutation
  public clearQueue(): void {
    this.toasts = [];
  }

  @Mutation
  public hide(): void {
    this.toasts.shift();
  }

  get toast(): Toast | null {
    return this.toasts.length ? this.toasts[0] : null;
  }
}

export default getModule(ToastModule);
