import { h } from 'vue';
import { createVuetify, IconProps, IconSet } from 'vuetify';
import * as components from 'vuetify/components';
import * as VDatePicker from 'vuetify/labs/VDatePicker';
import * as VDataTable from 'vuetify/labs/VDataTable';
import * as VStepper from 'vuetify/labs/VStepper';
import { Ripple } from 'vuetify/directives';
import { DISPLAY_BREAKPOINT_XS } from '@/config/config';
import { mdi } from 'vuetify/iconsets/mdi';
import { getIcons, getIconsAliases, getThemeColors } from '@/themes/theme';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import i18n from '../i18n';
import { I18n, useI18n } from 'vue-i18n';
import 'vuetify/styles';

const icons = getIcons();
const aliases = getIconsAliases();

const customSVGs: IconSet = {
  component: (props: IconProps) => h(icons[props.icon as string]),
};

const vuetify = createVuetify({
  directives: {
    Ripple, // to show action from a user - click on a button etc.
  },
  components: {
    ...components,
    ...VDatePicker,
    ...VDataTable,
    ...VStepper,
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
      custom: customSVGs,
    },
  },
  display: {
    mobileBreakpoint: DISPLAY_BREAKPOINT_XS,
  },
  theme: {
    variations: {
      colors: Object.keys(getThemeColors()),
      lighten: 5,
      darken: 4,
    },
    themes: {
      light: {
        dark: false,
        colors: getThemeColors(),
      },
    },
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n: i18n as I18n, useI18n }),
  },
});

export default vuetify;
