<template>
  <v-alert
    v-if="validToIsApproaching && userAccountModule.isCardActive"
    border="top"
    type="warning"
    variant="text"
    elevation="2"
    class="pt-6 mb-6"
  >
    {{
      t('validToApproachingNotification', {
        validTo: getFormattedValidTo,
      })
    }}
  </v-alert>
  <v-alert v-if="unverifiedDataWarning" border="top" type="warning" variant="text" elevation="2" class="pt-6">
    {{ t('personalDataNotVerified') }}
  </v-alert>
  <v-row class="mb-6 mt-0">
    <v-col cols="12" md="8">
      <RDPCustomerDetailXsOnly v-if="$vuetify.display.mobile && customerModule.currentlySelectedCustomerId" />
      <RDPCustomerDetail v-if="!$vuetify.display.mobile && customerModule.currentlySelectedCustomerId" />
    </v-col>
    <v-col cols="12" md="4" class="text-right">
      <div v-if="showConnectAccountBtn" :title="t('connectAccountButton.title')">
        <v-btn
          class="mb-4 rdp-button"
          color="buttonPrimary"
          :data-cy="cy('connect-card-application-btn')"
          theme="dark"
          @click="openConnectAccountDialog"
        >
          <template #prepend>
            <v-icon size="x-large" icon="$newCardApplicationIcon" />
          </template>
          <span class="rdp-button-text">
            {{ t('connectAccountButton.title') }}
          </span>
        </v-btn>
      </div>
      <div v-if="showAssignCustodianBtn" :title="$t('assignCustodian.title')">
        <v-btn
          class="mb-4 rdp-button"
          color="buttonPrimary"
          :data-cy="cy('assign-custodian-btn')"
          theme="dark"
          @click="openAssignCustodianDialog"
        >
          <template #prepend>
            <v-icon size="x-large" icon="$assignCustodianIcon" />
          </template>
          <span class="rdp-button-text">
            {{ $t('assignCustodian.title') }}
          </span>
        </v-btn>
      </div>
      <div v-if="showPakTransferButton" :title="t('pakTransferButton.title')">
        <v-btn
          class="mb-4 rdp-button pak-transfer-btn"
          color="buttonPrimary"
          :data-cy="cy('transfer-to-map-btn')"
          theme="dark"
          @click="openPakTransferDialog"
        >
          <template #prepend>
            <v-icon size="x-large" icon="$pakTransferIcon" />
          </template>
          <span class="rdp-button-text">
            {{ t('pakTransferButton.title') }}
          </span>
        </v-btn>
      </div>
      <CreateCardApplicationButton
        :show="showCardApplicationButton"
        :tooltip="createCardApplicationButtonTooltip(cardTypeTooltip.personal)"
        :data-cy="cy('create-card-application-btn')"
        :disabled="createCardApplicationDisabled"
        :confirm="openCardApplicationDialog"
        :title="t('newCardApplicationButton.title')"
      />
      <CreateCardApplicationButton
        :show="showVirtualCardApplicationButton"
        :tooltip="createCardApplicationButtonTooltip(cardTypeTooltip.virtual)"
        :data-cy="cy('create-virtual-card-application-btn')"
        :disabled="createVirtualCardApplicationDisabled"
        :confirm="openVirtualCardApplicationDialog"
        :title="t('newVirtualCardApplicationButton.title')"
      />
      <CreateCardApplicationButton
        :show="showBankCardApplicationButton"
        :tooltip="createCardApplicationButtonTooltip(cardTypeTooltip.bank)"
        :data-cy="cy('create-bank-card-application-btn')"
        :disabled="createBankCardApplicationDisabled"
        :confirm="openBankCardApplicationDialog"
        :title="t('newBankCardApplicationButton.title')"
      />
      <div
        v-if="hasActiveCard && showTicketFareButton"
        :title="ticketFareButtonTooltip"
        class="d-flex align-end flex-wrap flex-column"
      >
        <v-alert
          v-if="ticketFareButtonDisabled && $vuetify.display.mobile"
          border="top"
          type="warning"
          variant="text"
          elevation="2"
          width="292px"
          class="pt-3 pb-1 mb-2 text-left"
        >
          {{ ticketFareButtonTooltip }}
        </v-alert>
        <v-btn
          :disabled="ticketFareButtonDisabled"
          :theme="!ticketFareButtonDisabled ? 'dark' : ''"
          color="buttonPrimary"
          class="mb-4 rdp-button"
          :data-cy="cy('newTicketFare-btn')"
          @click="openTicketFareDialog"
        >
          <template #prepend>
            <v-icon size="x-large" icon="$ticketFareIcon" />
          </template>
          <span class="rdp-button-text">
            {{ t('newTicketFareButton.title') }}
          </span>
        </v-btn>
      </div>
      <div v-if="showEWalletButton" :title="eWalletButtonTooltip" class="d-flex align-end flex-wrap flex-column">
        <v-alert
          v-if="eWalletButtonDisabled && $vuetify.display.mobile"
          border="top"
          type="warning"
          variant="text"
          elevation="2"
          width="292px"
          class="pt-3 pb-1 mb-2 text-left"
        >
          {{ eWalletButtonTooltip }}
        </v-alert>
        <v-btn
          class="mb-4 rdp-button"
          color="buttonPrimary"
          :data-cy="cy('show-eWallet-btn')"
          :theme="!eWalletButtonDisabled ? 'dark' : ''"
          :disabled="eWalletButtonDisabled"
          @click="showEWalletDialog"
        >
          <template #prepend>
            <v-icon size="x-large" icon="$electronicWalletIcon" />
          </template>
          <span class="rdp-button-text">
            {{ t('chargeEWalletButton.title') }}
          </span>
        </v-btn>
      </div>
    </v-col>
  </v-row>
  <CardApplicationStepperDialog ref="cardStepperDialog" :confirm-callback="cardApplicationCreated" />
  <ConnectAccountDialog ref="connectAccountDialog" v-bind="$attrs" />
  <PakTransferDialog ref="pakTransferDialog" :map-card-number="userAccountModule.cardNumber" v-bind="$attrs" />
  <EWalletDialog ref="eWalletDialog" :loaded-card-detail-data="userAccountModule.cardDetailData" />
  <TicketFareDialog ref="ticketFareDialog" />
  <AssignCustodianDialog ref="assignCustodianDialog" @custodian-assigned="$emit('refresh-account-page')" />
</template>

<script lang="ts">
import { Component, Prop, Ref } from 'vue-facing-decorator';
import moment from 'moment';
import { formatDate } from '@/utils/dateTime';
import { RDPDialogInterface } from '@/components/common/RDPDialogInterface';
import RDPCustomerDetail from '@/components/common/RDPCustomerDetail/RDPCustomerDetail.vue';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import UserAccountModule from '@/store/modules/userAccount';
import CustomerModule from '@/store/modules/customer';
import ShoppingCartModule from '@/store/modules/shoppingCart.ts';
import CardApplicationStepperDialog, {
  CardApplicationStepperDialogInterface,
} from '@/components/cardApplication/CardApplicationStepperDialog.vue';
import customerService from '@/services/api/customerService';
import eshopOrderService from '@/services/api/eshopOrderService';
import EWalletDialog from '@/components/userAccount/dialogs/EWalletDialog.vue';
import ComponentBase from '@/components/common/ComponentBase';
import TicketFareDialog from '@/components/userAccount/dialogs/TicketFareDialog.vue';
import ConnectAccountDialog from '@/components/userAccount/dialogs/ConnectAccountDialog.vue';
import PakTransferDialog from '@/components/userAccount/dialogs/PakTransferDialog.vue';
import RDPCustomerDetailXsOnly from '@/components/common/RDPCustomerDetail/RDPCustomerDetailXsOnly.vue';
import { ConnectAccountDialogInterface, ConnectAccountTypeDialog } from '@/models/ConectAccountTypeDialog';
import { PAK_ALLOWED } from '@/config/config';
import { MODULE } from '@/constants/Module';
import { showModule } from '@/utils/module';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import CreateCardApplicationButton from '@/components/userAccount/CreateCardApplicationButton.vue';
import AssignCustodianDialog from '@/components/userAccount/dialogs/AssignCustodianDialog.vue';
import { MIGRATION_FLAGS } from '@T-system/common/src/api/constants';
import ToastModule from '@/store/modules/toast.ts';

const CARD_TYPE_TOOLTIP = {
  virtual: 'VirtualCard',
  personal: 'PersonalCard',
  bank: 'BankCard',
};

@Component({
  components: {
    CardApplicationStepperDialog,
    RDPCustomerDetail,
    ConfirmButton,
    EWalletDialog,
    TicketFareDialog,
    RDPCustomerDetailXsOnly,
    ConnectAccountDialog,
    PakTransferDialog,
    CreateCardApplicationButton,
    AssignCustodianDialog,
  },
})
export default class CustomerDetail extends ComponentBase {
  @Ref()
  readonly cardStepperDialog!: CardApplicationStepperDialogInterface;
  @Ref()
  readonly connectAccountDialog!: ConnectAccountDialogInterface;
  @Ref()
  readonly pakTransferDialog!: RDPDialogInterface;
  @Ref()
  readonly ticketFareDialog!: RDPDialogInterface;
  @Ref()
  readonly eWalletDialog!: RDPDialogInterface;
  @Ref()
  readonly assignCustodianDialog!: RDPDialogInterface;

  @Prop({ required: false, type: Function, default: () => undefined })
  readonly cardApplicationCreatedCallback!: () => void;

  @Prop({ required: false, type: Boolean, default: true })
  readonly customerIsManageable!: boolean;

  NOTIFICATION_BEFORE_EXPIRED_IN_DAYS = 30;
  userAccountModule = UserAccountModule;
  customerModule = CustomerModule;
  shoppingCartModule = ShoppingCartModule;
  customEffect = '';
  canCreateCardApplication = false;
  systemConfigurationModule = SystemConfigurationModule;
  cardTypeTooltip = CARD_TYPE_TOOLTIP;

  dataCy = 'customer-detail';
  i18nGroupKey = 'userAccountPage.customerDetail';

  async created() {
    await this.checkHasActiveCardApplication();
    if (this.customerModule.currentlySelectedCustomerId) {
      this.canCreateCardApplication = await eshopOrderService.verifyIfCardApplicationCanBeCreated({
        originId: this.customerModule.currentlySelectedCustomerId,
      });
    } else {
      this.canCreateCardApplication = true;
    }

    // hide alert after 20s
    setTimeout(() => {
      this.customEffect = 'hide-alert';
    }, 20000);
  }

  async checkHasActiveCardApplication() {
    if (this.customerModule.currentlySelectedCustomerId) {
      const active = await customerService.hasActiveCardApplication(this.customerModule.currentlySelectedCustomerId);
      this.userAccountModule.setCardApplicationActive(active);

      this.canCreateCardApplication = await eshopOrderService.verifyIfCardApplicationCanBeCreated({
        originId: this.customerModule.currentlySelectedCustomerId,
      });

      const canBeCreated = await customerService.canVirtualCardApplicationBeCreated(
        this.customerModule.currentlySelectedCustomerId,
      );
      this.userAccountModule.setVirtualCardApplicationCanBeCreated(canBeCreated);
    } else {
      this.userAccountModule.setCardApplicationActive(false);
      this.userAccountModule.setVirtualCardApplicationCanBeCreated(true);
    }
  }

  openCardApplicationDialog() {
    if (this.customerModule.isAssignedCustomerSelected) {
      this.cardStepperDialog.openClearedDialog(this.userAccountModule.selectedCustomerData.id);
    } else {
      this.cardStepperDialog.openDialogForPersonalCard();
    }
  }

  openVirtualCardApplicationDialog() {
    this.cardStepperDialog.openDialogForVirtualCard();
  }

  openBankCardApplicationDialog() {
    if (this.customerModule.isAssignedCustomerSelected) {
      this.cardStepperDialog.openClearedBankCardDialog(this.userAccountModule.selectedCustomerData.id);
    } else {
      this.cardStepperDialog.openDialogForBankCard();
    }
  }

  openConnectAccountDialog() {
    this.connectAccountDialog.openDialog(ConnectAccountTypeDialog.REGISTER);
  }

  public openAssignCustodianDialog() {
    this.assignCustodianDialog.openDialog(this.userAccountModule.selectedCustomerData.id);
  }

  openPakTransferDialog() {
    this.pakTransferDialog.openDialog();
  }

  openTicketFareDialog() {
    this.ticketFareDialog.openDialog();
  }

  get showEWalletButton() {
    return (
      this.hasActiveCard &&
      this.isNotVirtualCardLoadedOrIsVirtualCardEWalletAllowed &&
      !this.isBankCard &&
      showModule(MODULE.ELECTRONIC_WALLET) &&
      this.customerIsManageable
    );
  }

  get isBankCard() {
    return this.userAccountModule.isCardTypeBank;
  }

  get isNotVirtualCardLoadedOrIsVirtualCardEWalletAllowed() {
    return !this.userAccountModule.isCardTypeVirtual || this.systemConfigurationModule.isVirtualCardEWalletAllowed;
  }

  get showConnectAccountBtn() {
    return (
      !this.customerModule.customerConnected &&
      this.systemConfigurationModule.isHaulierCardAsCarrierAllowed &&
      this.customerIsManageable
    );
  }

  get telshopMigrationEnabled() {
    return this.systemConfigurationModule.configuration.migrationFlags.includes(MIGRATION_FLAGS.TELSHOP);
  }

  get showAssignCustodianBtn(): boolean {
    return Boolean(
      this.telshopMigrationEnabled &&
        this.customerModule.isManagedCustomerSelected &&
        this.userAccountModule.customerIsChild &&
        !this.userAccountModule.selectedCustomerData.parentId,
    );
  }

  get showPakTransferButton() {
    return (
      PAK_ALLOWED &&
      this.hasActiveCard &&
      !this.customerModule.isManagedCustomerSelected &&
      !this.userAccountModule.isCardTypeVirtual &&
      this.customerIsManageable
    );
  }

  get showCardApplicationButton() {
    return Boolean(
      (!this.customerModule.isManagedCustomerSelected || this.systemConfigurationModule.isTelshopMigrationAllowed) &&
        this.systemConfigurationModule.isHaulierCardAsCarrierAllowed &&
        this.customerIsManageable,
    );
  }

  get showVirtualCardApplicationButton() {
    return Boolean(
      !this.customerModule.isLinkedCustomerSelected &&
        this.systemConfigurationModule.isVirtualCardAsCarrierAllowed &&
        this.customerIsManageable,
    );
  }

  get showBankCardApplicationButton() {
    return Boolean(
      this.systemConfigurationModule.configuration.bankCardAsCarrier &&
        (!this.customerModule.isManagedCustomerSelected || this.systemConfigurationModule.isTelshopMigrationAllowed) &&
        this.customerIsManageable,
    );
  }

  get showTicketFareButton() {
    return showModule(MODULE.TICKET_FARE) && this.customerIsManageable;
  }

  cardApplicationCreated() {
    this.cardApplicationCreatedCallback();
    this.checkHasActiveCardApplication();
    ToastModule.success({ message: this.t('cardApplication.createSuccess') });
  }

  get createCardApplicationDisabled() {
    return this.shoppingCartModule.isCardApplicationInShoppingCartForSelectedUser || !this.canCreateCardApplication;
  }

  get createVirtualCardApplicationDisabled() {
    return this.createCardApplicationDisabled || !this.userAccountModule.canVirtualCardApplicationBeCreated;
  }

  get createBankCardApplicationDisabled() {
    return this.createCardApplicationDisabled;
  }

  get eWalletButtonDisabled() {
    return this.shoppingCartModule.isReChargeWalletRequestInShoppingCart;
  }

  get ticketFareButtonDisabled() {
    return this.shoppingCartModule.isTicketFareInShoppingCart;
  }

  createCardApplicationButtonTooltip(cardType: string): string {
    const i18nKey = this.shoppingCartModule.isCardApplicationInShoppingCartForSelectedUser
      ? 'alreadyInShoppingCart'
      : this.canCreateCardApplication
      ? `enabled${cardType}`
      : 'customerHasActiveCardApplication';

    return this.t(`newCardApplicationButton.tooltip.${i18nKey}`);
  }

  get eWalletButtonTooltip() {
    const i18nKey = this.shoppingCartModule.isReChargeWalletRequestInShoppingCart
      ? 'alreadyInShoppingCart'
      : !this.hasActiveCard
      ? 'noActiveCard'
      : 'enabled';
    return this.t(`chargeEWalletButton.tooltip.${i18nKey}`);
  }

  get ticketFareButtonTooltip() {
    const i18nKey = this.shoppingCartModule.isTicketFareInShoppingCart
      ? 'alreadyInShoppingCart'
      : !this.hasActiveCard
      ? 'noActiveCard'
      : 'enabled';
    return this.t(`newTicketFareButton.tooltip.${i18nKey}`);
  }

  get hasActiveCard() {
    return this.userAccountModule.isCardActive;
  }

  showEWalletDialog() {
    this.eWalletDialog.openDialog();
  }

  get validToIsApproaching() {
    const date = moment().add(this.NOTIFICATION_BEFORE_EXPIRED_IN_DAYS, 'days');

    return moment(this.userAccountModule.cardDetailData.validTo).isBefore(moment(date));
  }

  get getFormattedValidTo() {
    return formatDate(this.userAccountModule.cardDetailData.validTo);
  }

  get unverifiedDataWarning() {
    return (
      /* Warning is temporarily disabled by RDP-1238. */
      false &&
      this.customerModule.currentlySelectedCustomerId &&
      this.userAccountModule.hasCardDetailData &&
      !this.userAccountModule.personalDataVerified
    );
  }
}
</script>
<style lang="scss" scoped>
.transfer-text {
  width: 220px;
}

.hide-alert {
  display: none;
}

.pak-transfer-btn {
  .v-btn__content {
    margin-left: -2px;
  }

  svg {
    margin-left: 2px;
  }
}
</style>
