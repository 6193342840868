<template>
  <div class="customer-personal-info">
    <CustomerMainPersonalInfo v-show="showMainPersonalInfo" class="pl-3" />
    <v-row v-if="userAccountModule.hasCardDetailData">
      <v-col class="text-right label">
        {{ ts('state') }}
      </v-col>
      <v-col class="text-left" :data-cy="cy('state')" :class="verificationClass">
        {{ getCustomerCardState(customerCardData.reclamation, customerCardData.state) }}
      </v-col>
    </v-row>
    <v-row v-if="userAccountModule.hasCardDetailData">
      <v-col class="text-right label">
        {{ ts('cardValidity') }}
      </v-col>
      <v-col class="text-left" :data-cy="cy('cardValidity')" :class="verificationClass">
        {{ toStandardDate(customerCardData.validFrom) }}
        {{ ` - ` }}
        {{ toStandardDate(customerCardData.validTo) }}
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-facing-decorator';
import RDPCustomerDetailAbstract from '@/components/common/RDPCustomerDetail/RDPCustomerDetailAbstract.vue';
import CustomerMainPersonalInfo from '@/components/common/RDPCustomerDetail/CustomerMainPersonalInfo.vue';
import { toStandardDate } from '@/filters';

@Component({
  name: 'CustomerPersonalInfo',
  components: {
    CustomerMainPersonalInfo,
  },
})
export default class CustomerPersonalInfo extends RDPCustomerDetailAbstract {
  @Prop({ required: false, type: Boolean, default: true })
  readonly showMainPersonalInfo!: boolean;

  toStandardDate(value: string) {
    return toStandardDate(value);
  }
}
</script>
