<template>
  <VueDatePicker
    ref="picker"
    :model-value="internalDate"
    :data-cy="`date-picker-${dataCy}`"
    :min-date="min"
    :max-date="max"
    :disabled-dates="disabledDates"
    :disabled="disabled"
    :week-start="firstDayOfWeek"
    :month-picker="menuType === 'MONTH'"
    :year-picker="menuType === 'YEAR'"
    header-color="systemPrimary"
    :locale="locale"
    :enable-time-picker="false"
    auto-apply
    auto-position
    hide-offset-dates
    :teleport="true"
    @update:model-value="dateSelected"
  >
    <template #trigger>
      <v-text-field
        ref="textField"
        :model-value="dateFormatted"
        :data-cy="`date-picker-input-${dataCy}`"
        :label="label"
        :prepend-icon="prependIconVisible ? 'mdi-calendar' : undefined"
        :append-icon="clearable ? undefined : appendIcon"
        :rules="[requiredValidation]"
        :error-messages="customErrorMessage"
        :class="{ 'disable-events': disabled }"
        :hide-details="hideDetails"
        :clearable="clearable"
        readonly
        variant="underlined"
        @click:clear="dateCleared"
      />
    </template>
  </VueDatePicker>
</template>

<script lang="ts">
import { Component, Prop, Vue, Ref, Watch } from 'vue-facing-decorator';
import moment from 'moment';
import { requiredValidator } from '@/utils/validators';
import { DATE_FORMAT, DATE_PICKER_FORMAT, MONTH_FORMAT, YEAR_FORMAT } from '@/utils/dateTime';
import { MenuType } from '@/constants/MenuType';
import LocaleModule from '@/store/modules/locale';

const DAY_OF_WEEK_MONDAY = 1;

interface TextFieldElement extends HTMLElement {
  validate: () => void;
  resetValidation: () => void;
}

interface PickerInterface {
  activePicker: string;
}

interface MonthPickerValue {
  month: number;
  year: number;
}

@Component
export default class RDPDatePicker extends Vue {
  internalDate: Date | null;
  firstDayOfWeek = DAY_OF_WEEK_MONDAY;
  requiredValidation = (v: string | null | undefined) => {
    return this.required ? requiredValidator(v) : true;
  };
  localeModule = LocaleModule;

  /* Refs */
  @Ref()
  readonly textField!: TextFieldElement;

  @Ref()
  readonly picker!: PickerInterface;

  /* Props */
  @Prop({ required: false, type: String, default: '' })
  readonly dataCy!: string;

  @Prop({ required: false, type: String, default: null })
  readonly appendIcon!: string;

  @Prop({ required: false, type: Boolean, default: false })
  readonly hidePrependIcon!: boolean;

  @Prop({ required: false, type: String, default: DATE_PICKER_FORMAT })
  readonly dateFormat!: string;

  @Prop({ required: false, type: String, default: '' })
  readonly modelValue!: string;

  @Prop({ required: false, type: String, default: '' })
  readonly label!: string;

  @Prop({ required: false, type: Boolean, default: false })
  readonly required!: boolean;

  @Prop({ required: false, type: String, default: MenuType.DATE })
  readonly menuType!: string;

  @Prop({ required: false, type: String, default: '' })
  // always including min
  readonly min!: string;

  @Prop({ required: false, type: String, default: '' })
  // always including max
  readonly max!: string;

  @Prop({ required: false, type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly hideDetails!: boolean;

  @Prop({ required: false, type: String, default: '' })
  readonly customErrorMessage!: string;

  @Prop({ required: false, type: Boolean, default: false })
  readonly clearable!: boolean;

  @Prop({ required: false, type: Array, default: () => [] })
  readonly disabledDates!: string[];

  created() {
    if (this.modelValue) {
      this.internalDate = moment(this.modelValue).toDate();
    }
  }

  /* Computed */
  get dateFormatted(): string {
    return this.internalDate ? moment(this.internalDate).format(this.dateFormat) : '';
  }
  get prependIconVisible(): boolean {
    return !this.hidePrependIcon;
  }

  get locale(): string {
    return this.localeModule.locale;
  }

  dateCleared() {
    this.$emit('update:modelValue', null);
  }

  validate() {
    return this.required ? this.textField.validate() : true;
  }

  resetValidation() {
    return this.textField.resetValidation();
  }

  dateSelected(value: Date | MonthPickerValue | number | null) {
    let newValue;

    switch (this.menuType) {
      case MenuType.DATE:
        newValue = value ? moment(value as Date).format(DATE_FORMAT) : null;
        break;
      case MenuType.MONTH:
        newValue = value
          ? moment({ year: (value as MonthPickerValue).year, month: (value as MonthPickerValue).month }).format(
              MONTH_FORMAT,
            )
          : null;
        break;
      case MenuType.YEAR:
        newValue = value ? moment({ year: value as number }).format(YEAR_FORMAT) : null;
        break;
    }

    this.$emit('update:modelValue', newValue);
    this.$emit('change');
  }

  @Watch('modelValue')
  valueChanged(value: string) {
    if (value && value.length > 0) {
      this.internalDate = moment(value).toDate();
    } else {
      this.internalDate = null;
    }
  }
}
</script>

<style scoped>
:deep(input) {
  cursor: pointer;
}
</style>
