/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { CustomerAccountLinkListResponse } from '../models/CustomerAccountLinkListResponse';
import type { CustomerAccountLinkResponse } from '../models/CustomerAccountLinkResponse';
import type { ManageAccountLinkRequestResponse } from '../models/ManageAccountLinkRequestResponse';
import type { ManagedAccountLinkRequestCommand } from '../models/ManagedAccountLinkRequestCommand';
import type { ManagingAccountCardResponse } from '../models/ManagingAccountCardResponse';
import type { StringValueCommand } from '../models/StringValueCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EshopCustomerAccountLinkService {

    /**
     * Manage account link request.
     * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
 * + **INVALID_CUSTOMER_CARD** - Customer card does not exist or has invalid card state.<br>
 * + **INVALID_ACCOUNT** - Account does not exist or another customer is assigned to this account.<br>
 * + **CANNOT_MANAGE_OWN_CARD** - Can´t manage own card.<br>
 * + **CUSTOMER_CARD_ALREADY_MANAGED_BY_CURRENT_ACCOUNT** - Customer card already managed by current account.
     * @returns ManageAccountLinkRequestResponse 
     * @throws ApiError
     */
    public static eshopCustomerAccountLinkControllerManagingCardRequest({
requestBody,
}: {
/**
 * ManagedAccountLinkRequestCommand
 */
requestBody?: ManagedAccountLinkRequestCommand,
}): CancelablePromise<ManageAccountLinkRequestResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/eshop/customer-account-links-managed-account-link-request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get customer account links that are linked to the logged-in account.
     * @returns CustomerAccountLinkListResponse 
     * @throws ApiError
     */
    public static eshopCustomerAccountLinkControllerGetAll({
customerAccountLinkType,
timezoneOffset,
limit,
offset,
sortBy,
sortDesc,
sortLocale,
}: {
customerAccountLinkType?: 'REGISTERED' | 'ASSIGNED' | 'MANAGED',
timezoneOffset?: number,
limit?: number,
offset?: number,
sortBy?: string,
sortDesc?: boolean,
sortLocale?: string,
}): CancelablePromise<CustomerAccountLinkListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customer-account-links',
            query: {
                'customerAccountLinkType': customerAccountLinkType,
                'timezoneOffset': timezoneOffset,
                'limit': limit,
                'offset': offset,
                'sortBy': sortBy,
                'sortDesc': sortDesc,
                'sortLocale': sortLocale,
            },
            errors: {
                401: `User wasn't authenticated.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Confirm a customer account.
     * Confirm a request for managing a customer's card by another customer based on token.,Body contains a token.<br>
 * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
 * + **APPROVE_MANAGED_CARD_INVALID_TOKEN** - Token is invalid or belongs to a different account.<br>
 * + **APPROVE_MANAGED_CARD_EXPIRED_TOKEN** - Token has already expired.<br>
 * + **APPROVE_MANAGED_CARD_MAX_ACCOUNTS_REACHED** - Card cannot be managed by more than two accounts.
     * @returns ManagingAccountCardResponse 
     * @throws ApiError
     */
    public static eshopCustomerAccountLinkControllerApproveManagedCard({
requestBody,
}: {
/**
 * StringValueCommand
 */
requestBody?: StringValueCommand,
}): CancelablePromise<ManagingAccountCardResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/eshop/customer-account-links/manage-approve',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Reject card manage request.
     * Reject card manage request based on token.,Body contains a token.<br>
 * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
 * + **REJECT_MANAGED_CARD_INVALID_TOKEN** - Token is invalid or belongs to a different account.
     * @returns ApiResponse 
     * @throws ApiError
     */
    public static eshopCustomerAccountLinkControllerRejectCardManageRequest({
token,
}: {
token: string,
}): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/eshop/customer-account-links/{token}/reject-card-manage-request',
            path: {
                'token': token,
            },
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Remove customer-account link with the type MANAGED.
     * @returns ApiResponse 
     * @throws ApiError
     */
    public static eshopCustomerAccountLinkControllerRemoveManagedCustomerLink({
id,
}: {
id: string,
}): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/eshop/customer-account-links/{id}/managed',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Remove account-customer link with the type MANAGED by managing account id.
     * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **INVALID_ACCOUNT_CUSTOMER** - Link between account and customer does not exists.
     * @returns ApiResponse 
     * @throws ApiError
     */
    public static eshopCustomerAccountLinkControllerCancelManagingAccountCard({
id,
}: {
id: string,
}): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/eshop/customer-account-links/managing-account/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Set account-customer link with the type MANAGED to type REGISTERED.
     * @returns CustomerAccountLinkResponse 
     * @throws ApiError
     */
    public static eshopCustomerAccountLinkControllerSetRegisteredType({
id,
}: {
id: string,
}): CancelablePromise<CustomerAccountLinkResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/eshop/customer-account-links/{id}/registered',
            path: {
                'id': id,
            },
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Set account-customer link with the type MANAGED to type ASSIGNED.
     * @returns CustomerAccountLinkResponse 
     * @throws ApiError
     */
    public static eshopCustomerAccountLinkControllerSetAssignedType({
id,
}: {
id: string,
}): CancelablePromise<CustomerAccountLinkResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/eshop/customer-account-links/{id}/assigned',
            path: {
                'id': id,
            },
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

}
