/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { ChangeCustomerAccountOwnerCommand } from '../models/ChangeCustomerAccountOwnerCommand';
import type { CustomerAccountDetailResponse } from '../models/CustomerAccountDetailResponse';
import type { CustomerAccountListResponse } from '../models/CustomerAccountListResponse';
import type { CustomerAccountSettingResponse } from '../models/CustomerAccountSettingResponse';
import type { CustomerHeaderResponse } from '../models/CustomerHeaderResponse';
import type { CustomerListResponse } from '../models/CustomerListResponse';
import type { UpdateCustomerAccountCommand } from '../models/UpdateCustomerAccountCommand';
import type { UpdateCustomerAccountSettingCommand } from '../models/UpdateCustomerAccountSettingCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerAccountService {

    /**
     * Get card application.
     * @returns CustomerListResponse 
     * @throws ApiError
     */
    public static customerAccountControllerGetAccountRelatedCustomers({
adultOnly,
}: {
adultOnly?: boolean,
}): CancelablePromise<CustomerListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/customer-accounts-related-customers',
            query: {
                'adultOnly': adultOnly,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Update account setting.
     * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.
     * @returns CustomerAccountSettingResponse 
     * @throws ApiError
     */
    public static customerAccountControllerUpdateSettings({
requestBody,
}: {
/**
 * UpdateCustomerAccountSettingCommand
 */
requestBody?: UpdateCustomerAccountSettingCommand,
}): CancelablePromise<CustomerAccountSettingResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/eshop/customer-accounts/settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get customer accounts...
     * Require: `PERMISSION_CUSTOMER-CARDS_EDIT`
     * @returns CustomerAccountListResponse 
     * @throws ApiError
     */
    public static customerAccountControllerGetAll({
userName,
verified,
timezoneOffset,
limit,
offset,
sortBy,
sortDesc,
sortLocale,
}: {
userName?: string,
verified?: boolean,
timezoneOffset?: number,
limit?: number,
offset?: number,
sortBy?: string,
sortDesc?: boolean,
sortLocale?: string,
}): CancelablePromise<CustomerAccountListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/system/customer-accounts',
            query: {
                'userName': userName,
                'verified': verified,
                'timezoneOffset': timezoneOffset,
                'limit': limit,
                'offset': offset,
                'sortBy': sortBy,
                'sortDesc': sortDesc,
                'sortLocale': sortLocale,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Change customer account owner
     * Require: `PERMISSION_CUSTOMER-CARDS_EDIT`
     * @returns CustomerHeaderResponse 
     * @throws ApiError
     */
    public static customerAccountControllerChangeCustomerAccountOwner({
requestBody,
}: {
/**
 * ChangeCustomerAccountOwnerCommand
 */
requestBody?: ChangeCustomerAccountOwnerCommand,
}): CancelablePromise<CustomerHeaderResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/system/customer-accounts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get customer account.
     * Require: `PERMISSION_ADMINISTRATION_VIEW`
     * @returns CustomerAccountDetailResponse 
     * @throws ApiError
     */
    public static customerAccountControllerGetOne({
id,
}: {
id: string,
}): CancelablePromise<CustomerAccountDetailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/system/customer-accounts/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Update customer account.
     * Require: `PERMISSION_ADMINISTRATION_VIEW`<br>
 * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
 * + **INVALID_USERNAME** - Invalid username.<br>
 * + **USERNAME_NOT_UNIQUE** - Username already exists.
     * @returns CustomerAccountDetailResponse 
     * @throws ApiError
     */
    public static customerAccountControllerUpdate({
id,
requestBody,
}: {
id: string,
/**
 * UpdateCustomerAccountCommand
 */
requestBody?: UpdateCustomerAccountCommand,
}): CancelablePromise<CustomerAccountDetailResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/system/customer-accounts/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Delete customer account.
     * If an entity has any link, 409 Conflict will be returned.<br>
 * Require: `PERMISSION_ADMINISTRATION_VIEW`
     * @returns ApiResponse 
     * @throws ApiError
     */
    public static customerAccountControllerDelete({
id,
}: {
id: string,
}): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/system/customer-accounts/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                409: `Request couldn't be completed due to a conflict.`,
                500: `Something went wrong.`,
            },
        });
    }

}
