import i18n from '@/i18n';
import { EnumDto, EnumsDto, OrderItemDto } from '@/api';
import {
  getCustomerProfileDisplayName,
  getFeeDisplayName,
  getTariffProfileDisplayName,
  getTicketCombinationDisplayName,
} from '@/utils/i18n';

export interface Formattable {
  type: OrderItemDto.type;
  cancellation: boolean;
  feeName?: string;
  feeId?: string;
  productName?: string;
  zone?: EnumDto;
  customerProfileId?: string;
  tariffProfileId?: string;
  ticketCombinationId?: string;
  validFrom?: string;
  validTo?: string;
  reclamationNumber?: number;
  eWalletOperationType?: EnumsDto.electronicWalletOperations;
  price: number;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const formatFeeName = ({ feeId }: Formattable) => getFeeDisplayName(feeId);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const formatProductName = ({ productName }: Formattable) => productName!;

const formatTicketFareName = ({ ticketCombinationId, zone }: Formattable) =>
  i18n.global.t('orderItem.ticketFare', {
    ticketCombination: getTicketCombinationDisplayName(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ticketCombinationId!,
    ),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    zone: zone!.displayName,
  });

const formatPakTicketFareName = ({ customerProfileId, tariffProfileId, zone }: Formattable) =>
  i18n.global.t('orderItem.pakTicketFare', {
    customerProfile: getCustomerProfileDisplayName(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      customerProfileId!,
    ),
    tariffProfile: getTariffProfileDisplayName(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      tariffProfileId!,
    ),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    zone: zone!.displayName,
  });

const formatElectronicWalletOperationName = ({ eWalletOperationType, price }: Formattable) => {
  if (eWalletOperationType === EnumsDto.electronicWalletOperations.PAK_TRANSFER) {
    return price > 0 ? i18n.global.t('orderItem.pakWalletCharge') : i18n.global.t('orderItem.pakWalletWithdraw');
  }
  return i18n.global.t(`operationType.${eWalletOperationType}`);
};

const formatReclamationName = ({ reclamationNumber }: Formattable) =>
  i18n.global.t('orderItem.reclamation', { reclamationNumber });

const formatRoundingName = () => i18n.global.t('orderItem.rounding');

const formatNonCancelledItemName = (item: Formattable): string => {
  switch (item.type) {
    case OrderItemDto.type.FEE:
      return formatFeeName(item);
    case OrderItemDto.type.PRODUCT:
      return formatProductName(item);
    case OrderItemDto.type.TICKET_FARE:
      return formatTicketFareName(item);
    case OrderItemDto.type.PAK_TICKET_FARE:
      return formatPakTicketFareName(item);
    case OrderItemDto.type.ELECTRONIC_WALLET:
      return formatElectronicWalletOperationName(item);
    case OrderItemDto.type.RECLAMATION:
      return formatReclamationName(item);
    case OrderItemDto.type.ROUNDING:
      return formatRoundingName();
    default:
      return '';
  }
};

const formatOrderItemName = (item: Formattable): string => {
  const name = formatNonCancelledItemName(item);
  return item.cancellation ? i18n.global.t('orderItem.cancellation', { name }) : name;
};

export { formatOrderItemName };
