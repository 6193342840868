<template>
  <v-card width="100%" class="fill-height pa-4">
    <RDPDataTable
      ref="dataTable"
      :headers="headers"
      :filter="filter"
      :search-items-callback="searchCards"
      :row-click-callback="selectCard"
      :i18n-group-key="i18nGroupKey"
      disable-sort
      :data-cy="cy('table')"
    >
      <template #head-panel.end>
        <v-row>
          <v-col :cols="$vuetify.display.mobile ? 12 : 9">
            <v-alert border="top" type="info" elevation="2" class="mb-4" variant="text">
              {{ t('infoBoxText') }}
            </v-alert>
          </v-col>
          <v-col v-if="isAssignedChildNotAllowed" :cols="$vuetify.display.mobile ? 12 : 3" class="text-end">
            <v-btn
              v-if="showHaulierCardApplicationButton"
              :title="t('assignCard', { minCustomerAge: MIN_CUSTOMER_AGE })"
              data-cy="assign-card-button"
              class="mb-4 rdp-button"
              theme="dark"
              color="buttonPrimary"
              @click.stop="assignCard()"
            >
              <template #prepend>
                <v-icon size="large" icon="$assignCardIcon" />
              </template>
              <span class="rdp-button-text">
                {{ t('assignCard', { minCustomerAge: MIN_CUSTOMER_AGE }) }}
              </span>
            </v-btn>
            <div
              :title="
                t('createCardApplicationButton.title', {
                  minCustomerAge: MIN_CUSTOMER_AGE,
                })
              "
            >
              <v-btn
                v-if="showHaulierCardApplicationButton"
                color="buttonPrimary"
                class="mb-3 rdp-button"
                :data-cy="cy('create-card-application-button-btn')"
                theme="dark"
                @click="openCardApplicationDialog"
              >
                <template #prepend>
                  <v-icon size="large" icon="$newCardApplicationIcon" />
                </template>
                <span class="rdp-button-text">
                  {{
                    t('createCardApplicationButton.title', {
                      minCustomerAge: MIN_CUSTOMER_AGE,
                    })
                  }}
                </span>
              </v-btn>
            </div>
            <CreateCardApplicationButton
              :show="showBankCardApplicationButton"
              :data-cy="cy('create-bank-card-application-btn')"
              :confirm="openBankCardApplicationDialog"
              :title="
                t('createBankCardApplicationButton.title', {
                  minCustomerAge: MIN_CUSTOMER_AGE,
                })
              "
            />
          </v-col>
        </v-row>
      </template>
      <template #actions.start="{ item }">
        <v-btn
          :title="t('selectCard')"
          :data-cy="`select-card-button-${item.id}`"
          class="mx-1 elevation-4"
          dy
          icon
          size="x-small"
          @click.stop="selectCard(item)"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <template v-if="showHaulierCardApplicationButton" #no-data>
        {{ t('noDataTextPart1') }}
        <a @click="assignCard">{{ t('noDataTextPart2') }}</a>
        {{ t('noDataTextPart3') }}
        <a @click="openCardApplicationDialog">{{ t('noDataTextPart4') }}</a>
      </template>
    </RDPDataTable>
    <ConnectAccountDialog ref="connectAccountDialog" @refresh-assigned-card-list-page="refreshPage" />
    <CardApplicationStepperDialog ref="cardStepperDialog" :confirm-callback="refreshTable" />
  </v-card>
</template>

<script lang="ts">
import { Component, Ref } from 'vue-facing-decorator';
import { DATA_TABLE_HEADER_TYPES } from '@/components/common/RDPDataTable/DataTableHelper';
import ComponentBase from '@/components/common/ComponentBase';
import RDPDataTable from '@/components/common/RDPDataTable/RDPDataTable.vue';
import CustomerModule from '@/store/modules/customer';
import Paths from '@/constants/Paths';
import { CustomerAccountLinkHeaderVm, CustomerAccountLinkQuery } from '@/api';
import customerAccountLinkService from '@/services/api/customerAccountLinkService';
import { formatNameReverse } from '@/utils/name';
import ConnectAccountDialog from '@/components/userAccount/dialogs/ConnectAccountDialog.vue';
import { ConnectAccountDialogInterface, ConnectAccountTypeDialog } from '@/models/ConectAccountTypeDialog';
import { RDPDataTableInterface } from '@/components/common/RDPDataTable/RDPDataTableInterfaces';
import CardApplicationStepperDialog, {
  CardApplicationStepperDialogInterface,
} from '@/components/cardApplication/CardApplicationStepperDialog.vue';
import { MIN_CUSTOMER_AGE } from '@/config/config';
import CreateCardApplicationButton from '@/components/userAccount/CreateCardApplicationButton.vue';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import customerService from '@/services/api/customerService';

const DEFAULT_SORT_BY_COLUMN = 'name';
const DEFAULT_SORT_DESC = true;

@Component({
  components: {
    RDPDataTable,
    ConnectAccountDialog,
    CardApplicationStepperDialog,
    CreateCardApplicationButton,
  },
})
export default class AssignedCardListPage extends ComponentBase {
  @Ref()
  readonly connectAccountDialog!: ConnectAccountDialogInterface;

  @Ref()
  readonly dataTable!: RDPDataTableInterface;

  @Ref()
  readonly cardStepperDialog!: CardApplicationStepperDialogInterface;

  filter: CustomerAccountLinkQuery = {
    sortBy: DEFAULT_SORT_BY_COLUMN,
    sortDesc: !DEFAULT_SORT_DESC,
    customerAccountLinkType: CustomerAccountLinkQuery.customerAccountLinkType.ASSIGNED,
  };

  systemConfigurationModule = SystemConfigurationModule;

  headers = [
    {
      text: 'name',
      transform: (link: CustomerAccountLinkHeaderVm) => formatNameReverse(link.customer),
    },
    { text: 'customer.birthDay', type: DATA_TABLE_HEADER_TYPES.DATE },
    {
      text: 'activeCardNumber',
      type: DATA_TABLE_HEADER_TYPES.CARD_NUMBER,
    },
  ];

  MIN_CUSTOMER_AGE = MIN_CUSTOMER_AGE;

  onlyManagedRelations = false;

  dataCy = 'assigned-card-list';
  i18nGroupKey = 'assignedCardList';

  async created() {
    if (this.systemConfigurationModule.isTelshopMigrationAllowed) {
      this.onlyManagedRelations = Boolean(await customerService.hasOnlyManagedRelations());
    }
  }

  get isAssignedChildNotAllowed() {
    return !(this.systemConfigurationModule.isAllowedFreeCardApplication && this.onlyManagedRelations);
  }

  searchCards(query: CustomerAccountLinkQuery) {
    return customerAccountLinkService.getLinkList(query);
  }

  assignCard() {
    this.connectAccountDialog.openDialog(ConnectAccountTypeDialog.ASSIGN);
  }

  refreshPage() {
    this.dataTable.refresh();
  }

  openCardApplicationDialog() {
    this.cardStepperDialog.openClearedDialog();
  }

  async selectCard(link: CustomerAccountLinkHeaderVm) {
    CustomerModule.setAssignedAccountCustomer(link);
    await this.$router.push(Paths.USER_LINKED_ACCOUNT);
  }

  refreshTable() {
    this.dataTable.refresh();
  }

  openBankCardApplicationDialog() {
    this.cardStepperDialog.openClearedBankCardDialog();
  }

  get showBankCardApplicationButton() {
    return this.systemConfigurationModule.configuration.bankCardAsCarrier;
  }

  get showHaulierCardApplicationButton() {
    return this.systemConfigurationModule.isHaulierCardAsCarrierAllowed;
  }
}
</script>
