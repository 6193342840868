<template>
  <v-dialog
    v-model="show"
    max-width="800"
    scrollable
    persistent
    :fullscreen="$vuetify.display.mobile"
    data-cy="assign-custodian-dialog"
    @keydown.esc="cancelDialog"
  >
    <v-card>
      <v-card-title class="text-h5 pl-3 d-flex bg-systemPrimary dialog-title">
        <v-icon size="large" start color="white">mdi-account-child</v-icon>
        <div class="align-self-center">{{ $t('assignCustodian.title') }}</div>
      </v-card-title>
      <v-card-text class="card-text">
        <v-form ref="form" validate-on="lazy" data-cy="assignCustodian-dialog" @submit.prevent>
          <v-row v-if="userItems.length > 0" class="mt-2">
            <v-col cols="12">
              <v-select
                v-model="searchedUserId"
                :label="$t(`${i18KeyGroup}.dataSearch`)"
                :items="userItems"
                :no-data-text="$t('common.noData')"
                clearable
                :data-cy="`${dataCy}-selectUser`"
                variant="underlined"
                @update:model-value="searchedUserChanged"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-text-field
                v-model="custodianData.firstName"
                :rules="validations.firstName"
                :label="$t(`${i18KeyGroup}.firstName`)"
                :data-cy="`${dataCy}-firstName`"
                :class="{ 'disable-events': isFormDisabled }"
                variant="underlined"
              />
              <v-text-field
                v-model="custodianData.lastName"
                :rules="validations.lastName"
                :label="$t(`${i18KeyGroup}.lastName`)"
                :data-cy="`${dataCy}-lastName`"
                :class="{ 'disable-events': isFormDisabled }"
                variant="underlined"
              />
              <v-text-field
                v-model="custodianData.birthDay"
                :placeholder="dateFormat"
                :label="$t(`${i18KeyGroup}.birthDay`)"
                :rules="validations.birthDay"
                clearable
                :class="{ 'disable-events': isFormDisabled }"
                :data-cy="`${dataCy}-birthDay`"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-1">
              <v-text-field
                v-model="custodianData.address.street"
                :rules="validations.addressStreet"
                :label="$t(`${i18KeyGroup}.addressStreet`)"
                :class="{ 'disable-events': isFormDisabled }"
                :data-cy="`${dataCy}-addressStreet`"
                variant="underlined"
              />
            </v-col>
            <v-col cols="4" class="py-1">
              <v-text-field
                v-model="custodianData.address.streetNumber"
                :rules="validations.addressStreetNumber"
                :label="$t(`${i18KeyGroup}.addressStreetNumber`)"
                :class="{ 'disable-events': isFormDisabled }"
                :data-cy="`${dataCy}-addressStreetNumber`"
                variant="underlined"
              />
            </v-col>
            <v-col cols="4" class="py-1">
              <v-text-field
                v-model="custodianData.address.city"
                :rules="validations.addressCity"
                :label="$t(`${i18KeyGroup}.addressCity`)"
                :class="{ 'disable-events': isFormDisabled }"
                :data-cy="`${dataCy}-addressCity`"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-1">
              <v-text-field
                v-model="custodianData.address.zip"
                :rules="validations.addressZipCode"
                :label="$t(`${i18KeyGroup}.addressZipCode`)"
                :class="{ 'disable-events': isFormDisabled }"
                :data-cy="`${dataCy}-addressZipCode`"
                variant="underlined"
              />
            </v-col>
            <v-col cols="4" class="py-1">
              <v-text-field
                v-model="custodianData.email"
                :rules="validations.email"
                :label="$t(`${i18KeyGroup}.email`)"
                :class="{ 'disable-events': isFormDisabled }"
                :data-cy="`${dataCy}-email`"
                variant="underlined"
              />
            </v-col>
            <v-col cols="4" class="py-1">
              <v-text-field
                v-model="custodianData.phoneNumber"
                :rules="validations.phone"
                :label="$t(`${i18KeyGroup}.phone`)"
                :class="{ 'disable-events': isFormDisabled }"
                :data-cy="`${dataCy}-phone`"
                variant="underlined"
                @keypress="allowPhoneNumber"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn color="buttonWarning" :data-cy="`${dataCy}-cancel-button`" variant="elevated" @click="cancelDialog">
          <template #prepend>
            <v-icon>mdi-cancel</v-icon>
          </template>
          {{ $t('buttons.close') }}
        </v-btn>
        <ConfirmButton
          dark
          :confirm="confirmDialog"
          :text="$t('buttons.ok')"
          icon="mdi-check"
          :data-cy="`${dataCy}-confirm-button`"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-facing-decorator';
import RDPAutoComplete from '@/components/common/RDPAutoComplete/RDPAutoComplete.vue';
import { FormInterface } from '@/components/common/FormInterface';
import customerService from '@/services/api/customerService';
import accountService from '@/services/api/accountService';
import { DATE_BIRTHDAY_FORMAT, formatDate, MIN_DAY_OF_BIRTHDAY } from '@/utils/dateTime';

import {
  dateValidator,
  emailValidator,
  maxLengthValidator,
  requiredValidator,
  requiredValidatorIf,
} from '@/utils/validators';
import moment from 'moment';
import KeyboardEventHelper from '@common/utils/keyboardEventHelper';
import RDPConfirmDialog from '@/components/common/RDPConfirmDialog.vue';
import cloneDeep from 'lodash/cloneDeep';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import CustomerModule, { _custodianData } from '@/store/modules/customer';
import { CustomerDto, CustomerHeaderVm } from '@/api';
import assignCustodianMapper from '@/components/userAccount/mappers/AssignCustodianMapper';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import ToastModule from '@/store/modules/toast.ts';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';

@Component({
  components: { ConfirmButton, RDPConfirmDialog, RDPAutoComplete },
})
export default class AssignCustodianDialog extends Vue {
  @Ref()
  readonly form!: FormInterface;

  show = false;
  customerId: string;
  i18KeyGroup = 'assignCustodian';
  dataCy = 'assignCustodian';
  searchedUserId = null;
  custodianData: CustomerDto = cloneDeep(_custodianData);
  userItems: { value: string; title: string }[] = [];
  dateFormat = DATE_BIRTHDAY_FORMAT;
  allowPhoneNumber = KeyboardEventHelper.allowPhoneNumber;
  customerModule = CustomerModule;
  systemConfigurationModule = SystemConfigurationModule;

  get validations() {
    return {
      firstName: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 50)],
      lastName: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 50)],
      birthDay: [
        (v: string) => requiredValidator(v),
        (v: string) =>
          dateValidator(
            v,
            this.dateFormat,
            MIN_DAY_OF_BIRTHDAY,
            this.getMaxDayOfBirthDay().toISOString().substring(0, 10),
          ),
      ],
      addressStreet: [
        (v: string) => requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressStreetRequired),
        (v: string) => maxLengthValidator(v, 50),
      ],
      addressStreetNumber: [
        (v: string) =>
          requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressStreetNumberRequired),
        (v: string) => maxLengthValidator(v, 15),
      ],
      addressCity: [
        (v: string) => requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressCityRequired),
        (v: string) => maxLengthValidator(v, 50),
      ],
      addressZipCode: [
        (v: string) =>
          requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressZipCodeRequired),
        (v: string) => maxLengthValidator(v, 6),
      ],
      email: [(v: string) => emailValidator(v), (v: string) => maxLengthValidator(v, 50)],
      phone: [(v: string) => maxLengthValidator(v, 20)],
    };
  }

  async openDialog(customerId: string) {
    const accountRelatedCustomers = await accountService.getAccountRelatedCustomerList({ adultOnly: true });
    this.transformUserAutocompleteItems(accountRelatedCustomers);
    this.customerId = customerId;
    this.show = true;
  }

  transformUserAutocompleteItems(responseData: CustomerHeaderVm[]) {
    this.userItems = responseData.map((customer: CustomerHeaderVm) => ({
      value: customer.id,
      title: `${customer.lastName} ${customer.firstName} (${formatDate(customer.birthDay)})`,
    }));
  }

  cancelDialog() {
    this.closeDialog();
  }

  closeDialog() {
    this.custodianData = cloneDeep(_custodianData);
    this.searchedUserId = null;
    this.show = false;
  }

  async confirmDialog() {
    if ((await this.form.validate()).valid) {
      try {
        const payload = assignCustodianMapper.toAssignCustodianCommand(this.custodianData);
        await customerService.assignCustomerCustodian(this.customerId, payload);
        ToastModule.success({ message: this.$t(`${this.i18KeyGroup}.saveSuccess`) });
        const customer = await customerService.getLoggedCustomer();
        this.customerModule.setCustomer(customer!);
        this.$emit('custodianAssigned');
        this.closeDialog();
      } catch (e) {
        ToastModule.error({ message: this.$t(`${this.i18KeyGroup}.saveFailed`) });
      }
    }
  }

  getMaxDayOfBirthDay() {
    return moment().subtract(18, 'years');
  }

  get isFormDisabled() {
    return this.custodianData.id !== '';
  }

  async searchedUserChanged() {
    if (this.searchedUserId) {
      try {
        this.custodianData = await customerService.getCustomer(this.searchedUserId);
        this.custodianData.birthDay = formatDate(this.custodianData.birthDay);
      } catch (e) {
        const errorName = `cardApplication.errors.${(e as ApiErrorInterface)?.data?.name}`;
        if (this.$te(errorName)) {
          ToastModule.error({ message: this.$t(errorName) });
        }
      }
    } else {
      this.clearSearchedUser();
      this.custodianData = cloneDeep(_custodianData);
      this.form.resetValidation();
    }
  }

  clearSearchedUser() {
    this.searchedUserId = null;
  }
}
</script>

<style scoped lang="scss">
.headline {
  color: white;
}

.card-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
