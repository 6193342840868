/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TicketCombinationQuery = {
    /**
     * Customer profile identifier. Type: 'UUID'
     */
    customerProfileId?: string;
    /**
     * Customer profile type. Values: PERSONAL, IMPERSONAL
     */
    customerProfileType?: TicketCombinationQuery.customerProfileType;
    /**
     * Validity of tariff profiles. Past, Present, Future.
     */
    validity?: TicketCombinationQuery.validity;
    reduced?: boolean;
    typeName?: TicketCombinationQuery.typeName;
    /**
     * The flag determining the ticket with this combination can be sold at the counter.
     */
    counterSale?: boolean;
    /**
     * The flag determining the ticket with this combination can be sold in the e-shop.
     */
    eshopSale?: boolean;
    /**
     * A flag that determines whether a short-term ticket with this combination can be sold in a mobile application on a virtual card. A short-term ticket with this combination is tied to the customer profile on the virtual card.
     */
    mobileVirtualCardSale?: boolean;
    /**
     * The flag determining whether a short-term ticket with this combination can be sold in a mobile application cannot be combined with a sale at the counter or a sale in an e-shop. A short-term ticket with this combination can also be purchased in the mobile application without a transport / virtual card.
     */
    mobileEshopAccountSale?: boolean;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
    /**
     * Page Size. Default: 25
     */
    limit?: number;
    /**
     * Offsetting x items. Default: 0
     */
    offset?: number;
    /**
     * Sort by. Default: id
     */
    sortBy?: string;
    /**
     * Sort order descendent. Default: true
     */
    sortDesc?: boolean;
    /**
     * Sort with locale. Default: sk
     */
    sortLocale?: string;
    /**
     * Filter by date within date range. Type: 'DATE'
     */
    date?: string;
};

export namespace TicketCombinationQuery {

    /**
     * Customer profile type. Values: PERSONAL, IMPERSONAL
     */
    export enum customerProfileType {
        IMPERSONAL = 'IMPERSONAL',
        PERSONAL = 'PERSONAL',
    }

    /**
     * Validity of tariff profiles. Past, Present, Future.
     */
    export enum validity {
        PAST = 'PAST',
        PRESENT = 'PRESENT',
        FUTURE = 'FUTURE',
    }

    export enum typeName {
        SINGLE = 'SINGLE',
        TIME = 'TIME',
    }


}
