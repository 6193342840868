/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CardProfileVm } from './CardProfileVm';
import type { ElectronicWalletDto } from './ElectronicWalletDto';
import type { EnumDto } from './EnumDto';
import type { TicketFareDto } from './TicketFareDto';

export type PortableCardDetailVm = {
    electronicWallet: ElectronicWalletDto;
    ticketFares: Array<TicketFareDto>;
    /**
     * Card profile. Contains date range, profile itself and optional confirmation check date.
     */
    profileOne?: CardProfileVm;
    /**
     * Card profile. Contains date range, profile itself and optional confirmation check date.
     */
    profileTwo?: CardProfileVm;
    customerCardType: PortableCardDetailVm.customerCardType;
    /**
     * Portable card identifier. Type: 'UUID'
     */
    id: string;
    /**
     * Unique identifier of card.
     */
    cardNumber: string;
    /**
     * Chip number identifier. Is unique.
     */
    chipNumber: string;
    /**
     * Card valid from. Type: 'DATE'
     */
    validFrom: string;
    /**
     * Card valid to. Type: 'DATE'
     */
    validTo: string;
    note?: string;
    /**
     * Card state. Active, blocked, ...
     */
    state: EnumDto;
};

export namespace PortableCardDetailVm {

    export enum customerCardType {
        PERSONAL = 'PERSONAL',
        PORTABLE = 'PORTABLE',
        RECLAMATION = 'RECLAMATION',
        VIRTUAL_CARD = 'VIRTUAL_CARD',
        BANK_CARD = 'BANK_CARD',
    }


}
