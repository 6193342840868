/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { PriceListCollectionResponse } from '../models/PriceListCollectionResponse';
import type { PriceListDetailResponse } from '../models/PriceListDetailResponse';
import type { PriceListPeriodResponse } from '../models/PriceListPeriodResponse';
import type { PriceListResponse } from '../models/PriceListResponse';
import type { PriceListZoneListResponse } from '../models/PriceListZoneListResponse';
import type { SavePriceListNameCommand } from '../models/SavePriceListNameCommand';
import type { SavePriceListPeriodCommand } from '../models/SavePriceListPeriodCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PriceListService {

    /**
     * Get price list collection.
     * @returns PriceListCollectionResponse 
     * @throws ApiError
     */
    public static priceListControllerGetAll({
limit,
offset,
sortBy,
sortDesc,
sortLocale,
timezoneOffset,
}: {
limit?: number,
offset?: number,
sortBy?: string,
sortDesc?: boolean,
sortLocale?: string,
timezoneOffset?: number,
}): CancelablePromise<PriceListCollectionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/price-lists',
            query: {
                'limit': limit,
                'offset': offset,
                'sortBy': sortBy,
                'sortDesc': sortDesc,
                'sortLocale': sortLocale,
                'timezoneOffset': timezoneOffset,
            },
            errors: {
                401: `User wasn't authenticated.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Create price list name.
     * Require: `PERMISSION_ADMINISTRATION_VIEW`<br>
 * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.
     * @returns PriceListResponse 
     * @throws ApiError
     */
    public static priceListControllerCreate({
requestBody,
}: {
/**
 * SavePriceListNameCommand
 */
requestBody?: SavePriceListNameCommand,
}): CancelablePromise<PriceListResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/price-lists',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get price list by id.
     * @returns PriceListDetailResponse 
     * @throws ApiError
     */
    public static priceListControllerGetOne({
id,
}: {
id: string,
}): CancelablePromise<PriceListDetailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/price-lists/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Update price list name.
     * Require: `PERMISSION_ADMINISTRATION_VIEW`<br>
 * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.
     * @returns PriceListResponse 
     * @throws ApiError
     */
    public static priceListControllerUpdate({
id,
requestBody,
}: {
id: string,
/**
 * SavePriceListNameCommand
 */
requestBody?: SavePriceListNameCommand,
}): CancelablePromise<PriceListResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/public/price-lists/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Delete price list.
     * If an entity is being used, 409 Conflict will be returned.<br>
 * Require: `PERMISSION_ADMINISTRATION_VIEW`
     * @returns ApiResponse 
     * @throws ApiError
     */
    public static priceListControllerDelete({
id,
}: {
id: string,
}): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/public/price-lists/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                404: `Resource wasn't found.`,
                409: `Request couldn't be completed due to a conflict.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Get prices for the given price list.
     * @returns PriceListZoneListResponse 
     * @throws ApiError
     */
    public static priceListControllerGetPriceListZones({
id,
date,
timezoneOffset,
}: {
id: string,
date: string,
timezoneOffset?: number,
}): CancelablePromise<PriceListZoneListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/price-lists/{id}/zones',
            path: {
                'id': id,
            },
            query: {
                'date': date,
                'timezoneOffset': timezoneOffset,
            },
            errors: {
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Create price list period.
     * Zones need to be unique for the given period.,Date range cannot overlap within one price list.<br>
 * Require: `PERMISSION_ADMINISTRATION_VIEW`<br>
 * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
 * + **DATE_RANGE_IN_COLLISION** - Date range is in collision with another date range.<br>
 * + **INVALID_VALID_TO** - ValidFrom is after ValidTo OR New ValidFrom is null or new validFrom is before old ValidFrom.
     * @returns PriceListPeriodResponse 
     * @throws ApiError
     */
    public static priceListControllerCreatePeriod({
id,
requestBody,
}: {
id: string,
/**
 * SavePriceListPeriodCommand
 */
requestBody?: SavePriceListPeriodCommand,
}): CancelablePromise<PriceListPeriodResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/price-lists/{id}/periods',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                403: `User didn't have permissions to perform this action.`,
                500: `Something went wrong.`,
            },
        });
    }

}
