/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { PayShortTermTicketsByWalletCommand } from '../models/PayShortTermTicketsByWalletCommand';
import type { VehicleTicketListResponse } from '../models/VehicleTicketListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EshopElectronicWalletTransactionService {

    /**
     * Get tickets from a transaction made in a vehicle.
     * Returns an empty array if the transaction was not made in a vehicle.
     * @returns VehicleTicketListResponse 
     * @throws ApiError
     */
    public static eshopElectronicWalletTransactionControllerGetVehicleTickets({
id,
}: {
id: string,
}): CancelablePromise<VehicleTicketListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/eshop/electronic-wallet-transactions/{id}/vehicle-tickets',
            path: {
                'id': id,
            },
            errors: {
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Short term tickets pay by wallet
     * ### Error names for HTTP Status 400 BadRequest:<br>
 * + **INVALID_ELECTRONIC_WALLET** - Electronic wallet does not exist.<br>
 * + **INVALID_ELECTRONIC_WALLET_OPERATION** - Electronic wallet operation does not exist or is not allowed.<br>
 * + **NOT_ENOUGH_MONEY** - Not enough money to process the operation.<br>
 * + **INVALID_CUSTOMER_CARD** - Customer card does not exist or has invalid card state.<br>
 * + **INVALID_CUSTOMER** - Customer does not exist, belongs to another account, is a draft or origin.<br>
 * + **INVALID_CUSTOMER_VIRTUAL_CARD** - Customer card must be virtual for pay short term tickets by e-wallet.<br>
 * + **EWALLET_TRANSACTION_PAY_BY_WALLET_NOT_ALLOWED** - Short term tickets setting is not allowed in system configuration.
     * @returns ApiResponse 
     * @throws ApiError
     */
    public static eshopElectronicWalletTransactionControllerPayShortTermTicketsByWallet({
requestBody,
}: {
/**
 * PayShortTermTicketsByWalletCommand
 */
requestBody?: PayShortTermTicketsByWalletCommand,
}): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/eshop/electronic-wallet-transactions-pay-by-wallet',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation failed.`,
                401: `User wasn't authenticated.`,
                404: `Resource wasn't found.`,
                500: `Something went wrong.`,
            },
        });
    }

}
