/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdatePortableCardCommand = {
    /**
     * Card state enum. Represents active, blocked, expired.
     */
    state: UpdatePortableCardCommand.state;
    note?: string;
    /**
     * Client current timezone offset in minutes. Is required.
     */
    timezoneOffset?: number;
};

export namespace UpdatePortableCardCommand {

    /**
     * Card state enum. Represents active, blocked, expired.
     */
    export enum state {
        ACTIVE = 'ACTIVE',
        BLOCKED = 'BLOCKED',
        EXPIRED = 'EXPIRED',
    }


}
