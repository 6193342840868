import isDefined from '@common/utils/isDefined';
import i18n from '@/i18n';
import validator from 'validator';
import moment from 'moment';
import { DATE_FORMAT, DATE_PICKER_FORMAT } from '@/utils/dateTime';

export const requiredValidator = (v: string | null | undefined) => isDefined(v) || i18n.global.t('validators.required');
export const requiredValidatorIf = (v: string | null | undefined, cond: () => boolean) =>
  cond() ? isDefined(v) || i18n.global.t('validators.required') : true;
export const emailValidator = (v: string) => !isDefined(v) || validator.isEmail(v) || i18n.global.t('validators.email');
export const maxLengthValidator = (v: string, maxLength: number) =>
  !isDefined(v) || v.length <= maxLength || i18n.global.t('validators.maxLength', { maxLength });
export const minLengthValidator = (v: string, minLength: number) =>
  !isDefined(v) || v.length >= minLength || i18n.global.t('validators.minLength', { minLength });
/**
 * Returns an error if the trimmed string has a different length.
 * @param v
 * @param definedLength
 */
export const definedLengthValidator = (v: string, definedLength: number) =>
  (isDefined(v) && v.replace(/\s/g, '').length === definedLength) ||
  i18n.global.t('validators.definedLength', { definedLength });
export const strongPasswordValidator = (v: string) =>
  /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/.test(v) || i18n.global.t('validators.strongPassword');
export const confirmPasswordValidator = (v: string, password: string) =>
  v === password || i18n.global.t('validators.confirmPassword');
export const positiveNumber = (v: string) =>
  (isDefined(v) && parseInt(v) > 0) || i18n.global.t('validators.positiveNumber');
export const minValue = (v: string, min: number) =>
  (isDefined(v) && parseInt(v) >= min) || i18n.global.t('validators.minValue', { min });
export const maxValue = (v: string, max: number) =>
  (isDefined(v) && parseInt(v) <= max) || i18n.global.t('validators.maxValue', { max });

export const dateValidator = (v: string, format: string = DATE_PICKER_FORMAT, min?: string, max?: string) => {
  if (!isDefined(v)) {
    return true;
  }

  const date = moment(v, format, true).format(DATE_FORMAT);

  if (!validator.isISO8601(date, { strict: true })) {
    return i18n.global.t('validators.dateFormat', { format });
  }

  if (min && moment(date).isBefore(min)) {
    return i18n.global.t('validators.dateMin', { min: moment(min).format(format) });
  }

  if (max && moment(date).isAfter(max)) {
    return i18n.global.t('validators.dateMax', { max: moment(max).format(format) });
  }

  return true;
};

export const checkedValidator = (v: boolean) => v || i18n.global.t('validators.checked');

export const numericStringValidator = (v: string) => /^[0-9]*$/.test(v) || i18n.global.t('validators.numericString');
